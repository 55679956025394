class MainCtrl extends Controller
  constructor: (@$rootScope, $scope, @$state, @$cookies, @$translate, @$window, @Person, @Constants) ->
    @languages = [
      {
        short: @Constants.languages.short.et,
        long: @Constants.languages.long.et
      },
      {
        short: @Constants.languages.short.en,
        long: @Constants.languages.long.en
      },
      {
        short: @Constants.languages.short.ru,
        long: @Constants.languages.long.ru
      },
      {
        short: @Constants.languages.short.fi,
        long: @Constants.languages.long.fi
      },
      {
        short: @Constants.languages.short.pt,
        long: @Constants.languages.long.pt
      },
      {
        short: @Constants.languages.short.lv,
        long: @Constants.languages.long.lv
      },
      {
        short: @Constants.languages.short.lt,
        long: @Constants.languages.long.lt
      }
    ]

  logOut: =>
    @Person.logout().then ->
      window.location.href = '/login'

  invalidField: (form, field) -> form.$submitted && form[field].$invalid
