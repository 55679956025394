class PersonalFeedbackRow extends Directive
  constructor: () ->
    return {
      scope: {
        feedback: '='
      }
      templateUrl: 'directives/personalFeedbackRow.html',
      controller: 'PersonalFeedbackRowCtrl',
      controllerAs: 'ctrl'
    }

class PersonalFeedbackRowCtrl extends Controller
  constructor: () ->
    @long = false

  showLongComment: () ->
    @long = true

  hideLongComment: () ->
    @long = false
