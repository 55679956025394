class CustomGroupRowCtrl extends Controller
  constructor: (@$stateParams, @$scope, @SurveyResponderRoleRepository, @SurveyResponderRoleGroupRepository, @LanguageService, @toastr, @$translate, @Constants, @$window, @$timeout, @$state) ->
    @group = @$scope.group
    @currentLanguage = @LanguageService.selectedLanguage.short
    @roles = []
    @rolesVisible = false
    @editNameMode = false
    @groupId = @$stateParams.group_id

  roleInCurrentLocale: (role) ->
    role.roleForLocale(role.languageOrGivenLocale(@currentLanguage))

  showRole: (role) ->
    @$state.go('survey_responder_role', {id: role.id})

  edit: () ->
    return if @editNameMode

    @originalName = @group.name
    @editNameMode = true
    # Need to wait 100ms, because input form is not visible initially and #nameForm is not appearing in the DOM instantly
    @$timeout ( => @_setNameFormFocus()), 100

  updateName: () ->
    options = {name: @group.name}
    @SurveyResponderRoleGroupRepository.update(@group.id, options).then (response) =>
      @toastr.success(@$translate.instant('survey_responder_roles.groups.updated'))
      @editNameMode = false

  updateOrRevertName: (event) ->
    if event.keyCode == @Constants.keycodes.ENTER
      @updateName()
    else if event.keyCode == @Constants.keycodes.ESCAPE
      @_revertName()

  _setNameFormFocus: () ->
    nameForm = @$window.document.getElementById("nameForm")
    if(nameForm)
      nameForm.focus()

  showRoles: () ->
    @rolesVisible = !@rolesVisible
    if @rolesVisible && @roles.length == 0
      @SurveyResponderRoleRepository.getRoles(@group.id).then (roles) =>
        @roles = roles

  showRolesOnInit: () ->
    if @groupId == @group.id
      @rolesVisible = !@rolesVisible
      if @rolesVisible && @roles.length == 0
        @SurveyResponderRoleRepository.getRoles(@group.id).then (roles) =>
          @roles = roles

  _revertName: () ->
    return unless @editNameMode

    @editNameMode = false
    @group.name = @originalName

  updateVisibility: (index) ->
    role = @roles[index]
    return unless role

    options = {visible: role.visible}
    @SurveyResponderRoleRepository.update(role.id, options).then (role) =>
      @toastr.success(@$translate.instant('survey_responder_roles.roles.updated'))
