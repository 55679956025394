class EmailRepository extends Service

  constructor: (@Restangular, @Email) ->

  getAll: (options = {}) ->
    @Restangular.all('email_statistics').getList(options)

  get: (emailId, options = {}) ->
    @Restangular.one('email_statistics', emailId).get(options).then (response) =>
      new @Email(response.data)
