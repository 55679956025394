class VacancyTransferConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'vacancy.transfer',
        url: '/transfer'
        views:
          '@':
            templateUrl: 'vacancy/transfer/vacancyTransfer.html'
            controller: 'VacancyTransferRouteCtrl'
            controllerAs: 'routeCtrl'
            resolve:
              structures: (currentPerson, Structure) ->
                Structure.getCorporationTree(currentPerson.currentCompany.id)

class VacancyTransferRouteCtrl extends Controller
  constructor: (@$state, @toastr, @$translate, @vacancy, @structures, @Vacancy, @currentPerson, $rootScope) ->
    @selectedStructure = _.find @structures, (structure) => structure.id == @vacancy.entity_id
    @params = $rootScope.fromParams

  backToStructure: =>
    if _.isEmpty @params
      @params = {structure_type: 'companies', structure_id: @currentPerson.currentCompany.id}
    @$state.go('structures.people', {
      structure_id: @params.structure_id, structure_type: @params.structure_type
    })

  transferTo: =>
    @Vacancy.transfer(@vacancy, @selectedStructure).then =>
      @backToStructure()
      @toastr.success "", @$translate.instant('vacancy.transferred')
