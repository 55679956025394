angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'activity',
        parent: 'protected'
        url: '/planning/activity'
        template: "<ui-view />"

        abstract: true
        params: {
          activityId: undefined,
          planId: undefined,
        }

        resolve:
          currentActivity: () -> {}
          properties: (ActivityService) -> ActivityService.getProperties()
          people: (Employment, currentPerson) ->
            Employment.shortEmploymentsForStructure(currentPerson.currentCompany.id).then (employments) ->
              _.uniq(_.map(_.sortBy(employments, 'name'), (person) -> {id: person.id, name: person.name}))
          categories: (StructureRepository, currentPerson) ->
            StructureRepository.allPlanningCategoriesForStructure(currentPerson.currentCompany.id).then (response) ->
              response.data
          costRowTypes: (ActivityRepository) -> ActivityRepository.allCostRowTypes()
          parentItems: (StructureRepository, currentPerson) ->
            StructureRepository.allPlanningItemsForStructure(currentPerson.currentCompany.id).then (response) ->
              response.data

        views:
          '@':
            controller: "ActivityCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/activity/activity.html"

      .state 'activity.new',
        parent: 'activity'
        url: '/new'

      .state 'activity.edit',
        parent: 'activity'
        url: '/edit/{activityId: int}'
        resolve:
          currentActivity: ($stateParams, ActivityRepository) ->
            if $stateParams.activityId?
              ActivityRepository.getActivityById($stateParams.activityId)
        views:
          '@':
            controller: "ActivityCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/activity/activity.html"

class ActivityCtrl extends Controller
  constructor: (
    @$scope,
    @$state,
    @$modal,
    @$stateParams,
    @ActivityRepository,
    @$rootScope,
    properties,
    currentActivity,
    people,
    categories,
    costRowTypes,
    parentItems
  ) ->

    @activityTypesList = properties.typesList
    @activityStatusList = properties.activityStatusList
    @coResponsibleList = people
    @responsibleList = people
    @planningCategoryList = categories
    @possibleParentList = parentItems

    # use currentActivity if edit, new main object otherwise
    @activity = if currentActivity.id? then currentActivity else { cost_rows: [] }
    @activity.parent_id = _.filter @possibleParentList, (parent) => parent.id is parseInt(@activity.parent_id)

    # it would be better to move type and status to DB level instead of hard-coding them
    @activity.item_type = _.filter properties.typesList, (type) => type.id is parseInt(@activity.item_type)
    @activity.status = _.filter properties.activityStatusList, (status) => status.id is parseInt(@activity.status)

    # TODO: Need to talk through the logic for self-financed sums
    @costRowTypes = costRowTypes
    @costRowsVisible = false
    @currentCostRow = {}

    @totalExpense = 0
    @selfFinanced = 0

    @ActivityTabs = new Enum(
      [
        'CONVERSATIONS',
        'RELATIONSHIPS',
        'PARTNERS',
        'DOCUMENTS',
        'LOG',
        'CHILD_ACTIVITY'
      ]
    )

    @selectedTab = @ActivityTabs.CONVERSATIONS

    @updateExpenseSum()

  toggleCostRow: () ->
    @costRowsVisible = !@costRowsVisible

  # TODO: update self financed sum
  updateExpenseSum: () ->
    @totalExpense = 0
    @selfFinanced = 0
    _.each @activity.cost_rows, (row) =>
      @totalExpense += parseFloat(row.planned_amount)
      @selfFinanced += parseFloat(row.actual_amount)

  isActiveTab: (tabName) ->
    @selectedTab == tabName

  setTab: (tabName) ->
    @selectedTab = tabName

  openChildActivityModal: () ->
    return if @isActivityInRepository == 0

    @$modal.open
      windowClass: "medium"
      templateUrl: 'planning/activity/child/childActivity.html'
      controller: "ChildActivityCtrl"
      controllerAs: 'ctrl'
      scope: @$scope

  isErroneous: (field) -> (field.$dirty || field.$touched) && field.$invalid

  restangularize: () =>
    @activity.status = _.pluck(@activity.status, 'id')[0]
    @activity.item_type = _.pluck(@activity.item_type, 'id')[0]
    @activity.parent_id = _.pluck(@activity.parent_id, 'id')[0]
    @activity.planning_categories = _.pluck @activity.planning_categories, 'id'
    @activity.responsible_people = _.pluck @activity.responsible_people, 'id'
    @activity.co_responsible_people = _.pluck @activity.co_responsible_people, 'id'
    @activity.followers = _.pluck @activity.followers, 'id'

  save: () =>
    @restangularize()
    if @$state.current.name == "activity.edit"
      @ActivityRepository.updateActivity(@activity)
    else
      @ActivityRepository.createActivity(@activity)
    @goBackToOldState()

  goBackToOldState: () =>
    if @$rootScope.previousState.length
      @$state.go(@$rootScope.previousState)
      return
    @$state.go("planning")

  saveCostRow: () ->
    @activity.cost_rows.push @currentCostRow
    @updateExpenseSum()
    @currentCostRow = {}
    @toggleCostRow()

  deleteCostRow: (index) ->
    @activity.cost_rows.splice(index, 1)
    @updateExpenseSum()

#@$state.go('planning', { saved: true, title: @title, sum: @plannedSum })
