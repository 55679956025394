class UpdateIndividualResponderRole extends Directive
  constructor: () ->
    return {
      restrict: 'EA',
      templateUrl: 'directives/survey_responders/updateIndividualResponderRole.html',
      controller: 'IndividualResponderRoleUpdateCtrl',
      controllerAs: 'ctrl',
      scope: {
        responder: "=",
        role: "=",
        survey: "=",
        reassignableResponderRoles: "="
      }
    }

class IndividualResponderRoleUpdateCtrl extends Controller
  constructor: (@$scope, @$translate, @$state, @toastr, @IndividualResponderRepository) ->
    @responder = @$scope.responder
    @role = @$scope.role
    @survey = @$scope.survey

    @reassignableResponderRoles = _.filter @$scope.reassignableResponderRoles, (r) => r.role_name != @role
    @roleSelection = [{ role_name: @role, translatedName: @$translate.instant('survey.responder.change_group') }].concat(@reassignableResponderRoles)

  isRoleChangeable: () ->
    @role != 'employee' && !_.isEmpty(@reassignableResponderRoles) && (@survey.isDraft() || @survey.isOngoing() || @survey.isClosed())

  update: () ->
    # The ID of a responder from API is actually person ID:
    @IndividualResponderRepository.updateRole(@survey.id, @responder.id, { role: @role }).then (response) =>
      @$state.go('survey', { id: @survey.id, showResponders: true }, { reload: true }).then () =>
        @toastr.success(@$translate.instant('general.changed'))
