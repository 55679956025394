angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'my_goals',
        parent: 'protected'
        url: "/my_goals"
        template: "<ui-view/>"
        resolve:
          response: (Goal, currentPerson) ->
            Goal.allForEmployment({owner_employment_id: currentPerson.currentEmployment.id})
        views:
          '@':
            controller: "MyGoalsListCtrl"
            controllerAs: "ctrl"
            templateUrl: "goals/list/myGoalsList.html"

class MyGoalsListCtrl extends Controller
  constructor: (@currentPerson, response, @Goal, TableOptions, @Paging, @$modal, @toastr, @$translate, @GoalRepository) ->
    @tableOptions = new TableOptions(translationScope: 'goals')
    @paging       = new @Paging(response)
    @goals        = new Array(@paging.getTotalPages())
    @options      = {owner_employment_id: @currentPerson.currentEmployment.id}
    @statuses     = @Goal.statuses()

    @goals[@paging.iterator()] = @Goal.mapGoals(response.data)

  isSorainen: =>
    @currentPerson.currentCompany.id == 1911        # DEV-718: Sorainen goal view changes

  getFilteredGoals: =>
    @options.page = 1
    @fetchGoals(@options)

  fetchGoals: (options) =>
    @Goal.allForEmployment(options).then (response) =>
      @paging = new @Paging(response)
      @goals[@paging.iterator()] = @Goal.mapGoals(response.data)

  changePage: (page) =>
    @paging.changePage(page)
    if _.isUndefined(@goals[@paging.iterator()])
      options =  _.merge({page: page}, @options)
      @fetchGoals(options)

  openGoalDeleteModal: (goal) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'goals/delete/deleteGoalModal.html'
      controller: "DeleteGoalModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        goal: => goal
    .result.then =>
      @GoalRepository.delete(goal).then =>
        @toastr.success @$translate.instant('goals.deleted')
        @fetchGoals(@options)

  setGoalDone: (goal) ->
    goal.setDone().then (updated_goal) ->
      goal = updated_goal
