class Survey extends Service
  constructor: (
    Base,
    Restangular,
    Permissions,
    Employment,
    Constants,
    moment,
    IndividualResponder
  ) ->
    return class Survey extends Base
      constructor: (json) ->
        angular.extend @, json

        @category = _.snakeCase @category.replace('Upsteem::Surveys::', '')
        @permissions = new Permissions(@permissions)
        @individual_responders = _.map(@individual_responders, (responder) ->
          new IndividualResponder(responder)
        )

        if @permissions.one('allowingToDownloadOwnPdf')
          @own_response = @individual_responders[0]

        @started = !(@sent_out_at == undefined || @sent_out_at == null)
        @updateError = null

      @surveyTypes: -> [
        {
          type: Constants.survey_types.APPRAISAL_INTERVIEW,
          translatedType: "surveys." + Constants.survey_types.APPRAISAL_INTERVIEW
        },
        {
          type: Constants.survey_types.EVALUATION,
          translatedType: "surveys." + Constants.survey_types.EVALUATION
        },
        {
          type: Constants.survey_types.STAFF_SATISFACTION_SURVEY,
          translatedType: "surveys." + Constants.survey_types.STAFF_SATISFACTION_SURVEY
        }
      ]

      @categories: -> _.map Constants.survey_types, (type) -> type

      @allStatuses: -> _.map Constants.survey_statuses, (status) -> status

      @statuses: (isManager) ->
        if isManager
          _.reject(Constants.survey_statuses, (status) ->
            status == 'finished'
          )
        else
          _.reject(Constants.survey_statuses, (status) ->
            status == 'finished' || status == 'closed' || status == 'draft'
          )

      isSubjectPerson:   -> @subject_type == 'Person'
      hasPersonAnswered: -> @individual_responder_for_current_person.answered_at != null

      overDue: -> @sent_out_at != null && (new moment(@deadline_at) < new moment())

      canOverDueSurveyBeAnswered: -> @overDue() && !@isPublicSurvey()

      reopenableForAnswering: -> @reopenable_for_answering
      reopenableForEditing: -> @reopenable_for_editing

      isAnswerable: ->
        if @isAppraisalInterview()
          @isOngoing() || @isClosed()
        else
          @isOngoing()

      isStaffSatisfactionSurvey: -> @category == "staff_satisfaction_survey"
      isAppraisalInterview:      -> @category == "appraisal_interview"
      isPublicSurvey:            -> @category == "public_survey"
      isEvaluation:              -> @category == "evaluation"

      isDraft: -> @state == 'draft'
      isClosed: -> @state == 'closed'
      isFinished: -> @state == 'finished'
      isOngoing: -> @started && !@isFinished() && !@isClosed()
      ended: -> @started && (@isFinished() || @isClosed())

      answeringLink: (path) -> "#{path}/#{@individual_responder_for_current_person.token}"

      canShowEditDraftButton: -> @isDraft()

      canShowStartAnsweringButton: ->
        if @overDue()
          @canOverDueSurveyBeAnswered() && !@hasPersonAnswered()
        else !@hasPersonAnswered()

      canShowEditAnswersButton: ->
        if @overDue()
          @canOverDueSurveyBeAnswered() && @hasPersonAnswered()
        else
          @hasPersonAnswered()

      canShowViewResultsButton: -> @overDue() && @isPublicSurvey() && !@isDraft()
      canShowAddParticipantsButton: -> @permissions.one('allowingToEditSurvey') && !@isDraft()

      translatedStatus: -> "general.status." + @state

      stateAssignmentTime: ->
        time = switch @state
          when "finished"
            @finished_at
          when "closed"
            @closed_at
          when "ongoing"
            @sent_out_at
        time || null

      allowedToViewFeedback: ->
        @permissions.one("allowingToViewFeedback")

      allowedAllPdfsOrOwnPdf: ->
        @permissions.one('allowingToDownloadAllPdfs') ||
          @permissions.one('allowingToDownloadOwnPdf')

      copyWithUnchangedEntity: (updatedJson) ->
        survey = new Survey(updatedJson)
        survey.entity = @entity
        survey

      decorateEntityWithPage: (Structure, person) ->
        if @entity
          @entity = new Structure(@entity)
          Structure.getPage(person.currentCompany.id, @entity.id).then (page) =>
            @entity.page = page
        @

      canRenderSurveyWithinCurrentApp: () -> @isEvaluation() || @isAppraisalInterview()

      categoryTextKey: (currentCompanyId) ->
        'surveys.' + @category + @_categoryTextKeySuffix(currentCompanyId)

      _categoryTextKeySuffix: (currentCompanyId) ->
        if currentCompanyId == 1911
          '.sorainen'
        else
          '.default'
