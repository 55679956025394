angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $stateProvider
      .state 'trainings.new',
        url: "/new"
        resolve:
          trainingCategories: (TrainingCategory) ->
            TrainingCategory.all()
          jobFamilies: (ProfessionalGroup, currentPerson) ->
            ProfessionalGroup.allForCompany(currentPerson.currentCompany.id)
        views:
          '@':
            templateUrl: "trainings/new/newTraining.html"
            controller: "NewTrainingCtrl"
            controllerAs: "ctrl"

class NewTrainingCtrl extends Controller
  constructor: (@$state, @currentPerson, @Training, @trainingCategories, @TrainingCategory, @jobFamilies, toastr) ->
    @trainingTypes = _.filter @Training.trainingTypes(), (type) =>
      @currentPerson.permissions.one(
        'allowingToCreate' + inflection.camelize(type) + 'Trainings'
      )
    @training = { type: @trainingTypes[0] }

  createCategory: (name) =>
    categoryToAdd = {name: name, id: null}
    @trainingCategories.push(categoryToAdd)

    @TrainingCategory.create(name).then (response) =>
      _.remove(@trainingCategories,  (category) -> category == categoryToAdd)
      _.remove(@training.categories, (category) -> category == categoryToAdd)

      categoryToAdd = new @TrainingCategory(response.data)

      @trainingCategories.push(categoryToAdd)
      @training.categories.push(categoryToAdd)

    categoryToAdd

  backTo: =>
    @$state.go('trainings', {}, { reload: true })

  submit: =>
    @Training.create(@training).then (training) =>
      @$state.go('training.show', {training_id: training.id}, { reload: true })
