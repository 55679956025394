angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'objectives',
        parent: 'protected'
        url: '/objectives'
        template: "<ui-view />"
        resolve:
          response: (Objective, currentPerson) ->
            Objective.allForEmployment({owner_employment_id: currentPerson.currentEmployment.id, page: 1})
        views:
          '@':
            controller: "ObjectivesListCtrl"
            controllerAs: "ctrl"
            templateUrl: "objectives/list/objectivesList.html"

class ObjectivesListCtrl extends Controller
  constructor: (@currentPerson, response, @Objective, TableOptions, @Paging, @$modal, @$translate) ->
    @tableOptions = new TableOptions(translationScope: 'objectives')
    @paging       = new @Paging(response)
    @objectives   = new Array(@paging.getTotalPages())
    @options      = {owner_employment_id: @currentPerson.currentEmployment.id}
    @addObjectives(response)

  addObjectives: (response) =>
    @objectives[@paging.iterator()] = @Objective.mapObjectives(response.data)

  fetchObjectives: () =>
    @Objective.allForEmployment(@options).then (response) =>
      @addObjectives(response)

  changePage: (page) =>
    @paging.changePage(page)
    if _.isUndefined(@objectives[@paging.iterator()])
      @options.page = page
      @fetchObjectives()
