angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'given_feedback',
        parent: 'protected'
        url: '/given_feedback'
        resolve:
          response: (SurveyRepository, currentPerson) ->
            SurveyRepository.getGivenFeedbacks({person_id: currentPerson.id})
        views:
          '@':
            controller: "GivenFeedbackCtrl",
            controllerAs: "ctrl",
            templateUrl: "feedback/given_feedback/givenFeedback.html"

class GivenFeedbackCtrl extends Controller
  constructor: (response, @Survey, @Paging, TableOptions, @$rootScope) ->
    @tableOptions = new TableOptions(translationScope: 'surveys')
    @paging       = new @Paging(response)
    @all          = new Array(@paging.getTotalPages())

    @addSurveysToList(response.data)

  answeringLink: (survey) =>
    survey.answeringLink(@$rootScope.config.answering_app_host)

  addSurveysToList: (surveys) =>
    @all[@paging.iterator()] = _.map surveys, (survey) => new @Survey(survey)

  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@all[@paging.iterator()])
      @fetchSurveys(page)

  fetchSurveys: (page) =>
    @SurveyRepository.getGivenFeedbacks({person_id: @$rootScope.currentPerson.id, page: page}).then (response) =>
      @addSurveysToList(response.data)

  # DEV-895 specific hack
  isSorainen: () ->
    @$rootScope.currentPerson.currentCompany.id == 1911
