angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'external_responders',
        parent: 'token'
        url: "/ext/survey/:token"
        template: "<ui-view/>"
        resolve:
          survey: ($stateParams, RespondersRepository) ->
            RespondersRepository.getSurveyWithToken($stateParams.token).then (response) ->
              response.data

        views:
          'unprotected@':
            templateUrl: 'survey/responders/light/lightViewAddResponder.html'
            controller: 'LightViewAddResponderCtrl'
            controllerAs: 'ctrl'

class LightViewAddResponderCtrl extends Controller
  constructor: (@$rootScope, @$state, @$scope, @$modal, @$stateParams, @survey, @RespondersRepository, @$translate, @toastr) ->
    @groupedResponders = _.sortBy(@survey.responders, "role_index")
    @survey_url = @survey.survey_url
    @sectionState = {}
    @formData = {}
    @token = @$stateParams.token
    @$rootScope.$on "external_added", () => @fetchResponders()
    @$rootScope.$on "external_deleted", () => @fetchResponders()
    @setLanguage(@survey.language)

  getCategory: ( ) ->
    @survey.category.split("::").pop().toLowerCase() # Extract category name from survey

  hasSurveyUrl: () ->
    return @survey_url != "no_survey_url"

  setLanguage: (lang) ->
    @$translate.use(lang)

  getTranslatedName: (role) ->
    locale = _.find(role.locales, (roleLocale) => roleLocale.locale == @$translate.use())
    if (locale?)
        locale.name
    else
      _.find(role.locales, (roleLocale) => roleLocale.locale == @$translate.preferredLanguage()).name

  sectionOpened: (role) ->
    @sectionState == role

  openOrCloseSection: (role) ->
    if (@sectionState == role)
      @sectionState = {}
    else
      @sectionState = role

  fetchResponders: () =>
    @RespondersRepository.getSurveyWithToken(@token).then (response) =>
      @survey = response.data
      @survey_url = response.data.survey_url
      @groupedResponders = _.sortBy(@survey.responders, "role_index")

  openModal: (role) =>
    @$scope.group = role
    @$scope.token = @$stateParams.token
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: "small"
      templateUrl: "survey/responders/addExternalResponderModal.html"
      controller: "AddExternalResponderModalCtrl"
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() ->)
    )['finally'] () ->
      @$scope.modalInstance = undefined

  deleteResponder: (responder, role) ->
    responderGroup = @groupedResponders.filter((p) -> p.role_name is role)
    for responderItem, index in responderGroup[0].responders
      if responderItem.id == responder.id
        responderGroup[0].responders.splice(index, 1)
        break
    responderToDelete = {
      responder_id: responder.id
      token: @token
    }

    @RespondersRepository.deleteExternalResponder(responderToDelete).then((response) =>
      @$rootScope.$broadcast("external_deleted")
      @toastr.success @$translate.instant("survey.responder.removed")
    )
