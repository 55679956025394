class SurveyResultGroup extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '='
        translations: '='
      },
      controller: 'SurveyResultGroupCtrl',
      controllerAs: 'ctrl'
      templateUrl: 'directives/survey_result/survey_result_group.html'
    }

class SurveyResultGroupCtrl extends Controller
  constructor: (@$scope, @SurveyTranslatorService) ->
    @data = @$scope.data
    @translations = @$scope.translations
    @translator = @SurveyTranslatorService
    @role_ratings = @_roleRatings(@data.role_ratings, @translations)

  _roleRatings: (ratings, translations) ->
    _.reduce(ratings, (result, rating, role) =>
      result[@translator.translatedRole(translations, role)] = rating
      result
    , {}, @)
