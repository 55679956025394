angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $stateProvider
      .state 'structures',
        parent: 'protected'
        template: "<ui-view/>"
        url: '/{structure_type:(?:companies|departments)}/{structure_id:[0-9]*}?:page'
        params: {
          vacancy_id: null
        }
