class RelationshipsTabCtrl extends Controller
  constructor: (@$scope, @$modal) ->

  addRelationshipModal: () ->
    @$scope.modalInstance = @$modal.open
      windowClass: "medium"
      templateUrl: 'planning/activity/tabs/relationships/relationshipsModal.html'
      controller: "RelationshipsModalCtrl"
      controllerAs: 'ctrl'
      scope: @$scope
