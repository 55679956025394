class Enrollment extends Service
  constructor: (Base, Person, Permissions, Restangular, toastr, $translate) -> return class Enrollment extends Base
    constructor: (json) ->
      angular.extend @, json

      @participant = new Person(@participant_employment.person)
      @additional_info_requests = _.sortBy @additional_info_requests, 'created_at'
      @permissions = new Permissions(@permissions)

    @one: (enrollment_id) ->
      Restangular.one('enrollments', enrollment_id).get().then (response) ->
        new Enrollment(response.data)

    @all: (training_id) ->
      Restangular.one('trainings', training_id).all('enrollments').getList().then (enrollments) ->
        enrollments.map (enrollment) -> new Enrollment(enrollment.data)

    @create: (training_id, employment_ids) ->
      Restangular.one('trainings', training_id).all('enrollments')
        .post({employment_ids: employment_ids, training_id: training_id})
          .then (response) ->
            toastr.success $translate.instant('trainings.participation_added')
            response

    @setParticipations: (data) ->
      Restangular.all('enrollments').all('set_participation').post({"enrollments[]": data})

    @remove: (enrollment) ->
      Restangular.one('enrollments', enrollment.id).remove()

    accept: (comment) ->
      Restangular.one('enrollments', @id).one('accept').customPUT(@).then (response) =>
        @updateFieldsAfterDecision(response.data)

    reject: (comment) ->
      Restangular.one('enrollments', @id).one('reject').customPUT(@).then (response) =>
        @updateFieldsAfterDecision(response.data)

    updateFieldsAfterDecision: (enrollment) ->
      @decision = enrollment.decision
      @comment  = enrollment.comment
      @decider  = enrollment.decider

    translatedParticipation: -> if @participated then 'yes' else 'no'

    requestAdditionalInfo: (request_data) ->
      Restangular.one('enrollments', @id).post('additional_info_requests', {request: request_data}).then (new_additional_info) ->
        new_additional_info.data

    addResponseToRequest: (request) ->
      Restangular.one('enrollments', @id).one('additional_info_requests', request.id).put({response: request.response}).then (new_additional_info) ->
        new_additional_info.data

    doCommentsMatch: (comment) -> @comment == comment

    isRejected:    -> @decision == 'rejected'
    isAccepted:    -> @decision == 'accepted'
    hasDecision:   -> @decision != null
