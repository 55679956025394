angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $stateProvider
      .state 'training.edit',
        url: "/edit"
        resolve:
          trainingCategories: (TrainingCategory) ->
            TrainingCategory.all()
          jobFamilies: (ProfessionalGroup, currentPerson) ->
            ProfessionalGroup.allForCompany(currentPerson.currentCompany.id)
        views:
          '@':
            templateUrl: "trainings/edit/editTraining.html"
            controller: "EditTrainingCtrl"
            controllerAs: "ctrl"

class EditTrainingCtrl extends Controller
  constructor: (@$state, @training, @currentPerson, @trainingCategories, @jobFamilies, @TrainingCategory, @Training) ->
    @trainingTypes = _.filter @Training.trainingTypes(), (type) =>
      @currentPerson.permissions.one(
        'allowingToCreate' + inflection.camelize(type) + 'Trainings'
      )

  backTo: =>
    @$state.go('training.show', {training_id: @training.id})

  createCategory: (name) =>
    categoryToAdd = {name: name, id: null}
    @trainingCategories.push(categoryToAdd)

    @TrainingCategory.create(name).then (response) =>
      _.remove(@trainingCategories,  (category) -> category == categoryToAdd)
      _.remove(@training.categories, (category) -> category == categoryToAdd)

      categoryToAdd = new @TrainingCategory(response.data)

      @trainingCategories.push(categoryToAdd)
      @training.categories.push(categoryToAdd)

    categoryToAdd

  submit: =>
    @training.update().then (training) =>
      @$state.go('training.show', {training_id: training.id}, { reload: true })
