class PersonalFeedbackCategoryRepository extends Service

  constructor: (@Restangular) ->
    @defaultCategories = []
    @customCategories = []

  getAll: (options = {}) ->
    @Restangular.all('personal_feedback_categories').getList(options)

  create: (category) ->
    @Restangular.all('personal_feedback_categories').post(category)

  update: (category) ->
    @Restangular.all('personal_feedback_categories').customPUT(category)

  delete: (category) ->
    @Restangular.one('personal_feedback_categories', category.id).remove()
