class JobFamilyJoinConfig extends Config
  constructor: (RestangularProvider) ->
    RestangularProvider.extendCollection 'job_families', (collection) ->
      return angular.extend collection,
        forJobFamily: (jobFamily) ->
          _.find collection, (element) =>
            element.professional_group_id is jobFamily.id

        addJobFamily: (jobFamily) ->
          collection.post(professional_group_id: jobFamily.id).then (response) =>
            collection.push response

        removeJobFamily: (jobFamily) ->
          @forJobFamily(jobFamily).remove().then (response) =>
            _.remove collection, (item) =>
              item.id is response.id

class VacancyJobFamily extends Service
  constructor: -> return class VacancyJobFamily
    constructor: (json) ->
      angular.extend @, json
