class ImageRepository extends Service
  constructor: (Base, Restangular, Permissions, Constants, $state, toastr, $translate, $http, $rootScope) -> return class Document extends Base
    constructor: (json) ->
      angular.extend @,json

    @upload: (file, current_company) ->
      @allowedFiles = ["jpg", "jpeg", "png", "gif"]
      @maxSize = 3000000
      errors = @validate(file)

      if(file != null && errors.length == 0)
        $rootScope.isStateLoading = true

        formData = new FormData()
        formData.append('file', file)
        formData.append('company_id', current_company)

        # Bug, restangular with Content-Type undefined returns 400
        $http.post("/api/v2/company/configuration/answering_design.json", formData, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        })
      else
        throw(errors)

    @validate: (file) ->
      return @_validExtension(file).concat(@_imgSizeLimitOk(file).concat(@_validContentType(file)))

    @_validContentType: (file) ->
      valid = false

      for type in @allowedFiles
        valid = (file.type == ("image/"+type))
        break if valid
      if valid then [] else [['uploads.file_type', {types: "jpeg", "jpg", "png", "gif"}]]

    @_validExtension: (file) ->
      extension = file.name.substr(file.name.lastIndexOf(".")+1).toLowerCase()
      if (@allowedFiles.indexOf(extension) > -1) then [] else [['uploads.file_type', {types: "jpeg", "jpg", "png", "gif"}]]

    @_imgSizeLimitOk: (file) ->
      if (@maxSize > file.size) then [] else [['uploads.file_size', {size: "3MB"}]]
