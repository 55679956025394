class RemarkCtrl extends Controller
  constructor: (@$rootScope, $scope, Constants, @Paging, @RemarkRepository, @Remark) ->
    @periods = _.values(Constants.remark_periods)
    @selectedPeriod = Constants.remark_periods.ALL
    @opened = false
    @personController = $scope.$parent.ctrl
    @person = @personController.person
    @$rootScope.$on "refresh", () =>
      @fetchRemarks()

  periodChanged: () ->
    @fetchRemarks()

  toggleSection: ->
    @opened = !@opened

  load: =>
    if _.isUndefined(@all)
      @all = new Array(0)
      @fetchRemarks().then =>
        @toggleSection()
    else if @paging
      @toggleSection()

  addRemarksToList: (remarks) ->
    @all[@paging.iterator()] = @Remark.mapRemarks(remarks)

  changePage: (page) ->
    @paging.changePage(page)
    if _.isUndefined(@all[@paging.iterator()])
      @fetchRemarks({ page: page })

  fetchRemarks: (options = {}) ->
    if @personController.isRemarksFetchingAllowed()
      @$rootScope.isStateLoading = true
      @RemarkRepository.getAllForPerson(@person.id, { period: @selectedPeriod, page: options.page }).then (response) =>
        @_refreshData(response)
        @$rootScope.isStateLoading = false

  _refreshData: (response) ->
    newPaging = new @Paging(response)
    if !@paging || newPaging.getTotal() != @paging.getTotal()
      @all = new Array(newPaging.getTotalPages())
    @paging = newPaging
    @addRemarksToList(response.data)

  getForPage: () ->
    if(@paging != undefined)
      @all[@paging.iterator()]
