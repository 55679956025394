class EmailTemplateRepository extends Service

  constructor: (@Restangular, @EmailTemplate) ->

  getTemplatesForGroup: (templateGroupId, options = {}) ->
    @Restangular.one('email_template_group', templateGroupId).all("email_templates").getList(options).then (response) =>
      _.map(response.data, (template) => new @EmailTemplate(template))

  get: (templateId, options = {}) ->
    @Restangular.one('email_template', templateId).get().then (response) =>
      new @EmailTemplate(response.data)

  getDefault: (templateId) ->
    @Restangular.one('email_template', templateId).one('default').get().then (response) =>
      new @EmailTemplate(response.data)

  update: (templateId, options = {}) ->
    @Restangular.one('email_template', templateId).patch(options)
