class OverviewConfirmationCtrl extends Controller
  constructor: (@$scope, @SurveyRepository, @toastr, @$translate, @action, @targetIds, @callback) ->
    @survey = {}
    @targetCount = _.size(@targetIds)
    @showNotificationInput = _.includes(['send_out'], @action)
    @showCommentInput = _.includes(['send_out'], @action)
    @showDeadlineInput = _.includes(['send_out'], @action)
    @sendNotification = true
    @comment = ''
    @deadlineAt = moment().add(14, 'days')

  title: () ->
    {
      send_out: 'surveys.send_out_selected_surveys_confirmation',
      close: 'surveys.close_selected_surveys_confirmation',
      open: 'surveys.open_selected_surveys_confirmation',
      unfinish: 'surveys.unfinish_selected_surveys_confirmation',
      delete: 'surveys.delete_selected_surveys_confirmation',
    }[@action]

  notificationTitle: () ->
    {
      send_out: 'surveys.send_invitation'
    }[@action]

  actionTitle: () ->
    {
      send_out: 'survey.send_out',
      close: 'surveys.close_surveys',
      open: 'surveys.open_surveys',
      unfinish: 'surveys.unfinish_surveys',
      delete: 'general.delete',
    }[@action]


  closeModal: () ->
    @$scope.modalInstance.close()

  handleResponse: (response) ->
    data = response.data
    @callback(data).then (callbackResult) =>
      if _.size(data.success) > 0
        @toastr.success @$translate.instant(
          "surveys.#{@action}_success", { count: _.size(data.success) }
        )
      if _.size(data.errors) > 0
        @toastr.error  @$translate.instant(
          "surveys.#{@action}_error", { count: _.size(data.errors) }
        )

  postRequest: () ->
    switch @action
      when 'send_out'
        @SurveyRepository.massSendOut(@targetIds, @comment, @deadlineAt, @sendNotification)
          .then((response) => @handleResponse(response))
      when 'close'
        @SurveyRepository.massClose(@targetIds).then((response) => @handleResponse(response))
      when 'open'
        @SurveyRepository.massOpen(@targetIds).then((response) => @handleResponse(response))
      when 'delete'
        @SurveyRepository.massDelete(@targetIds).then((response) => @handleResponse(response))
    @closeModal()
