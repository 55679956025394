class SurveyResponderGroups extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      templateUrl: 'directives/survey_responders/surveyResponderGroups.html'
      controller: 'SurveyResponderGroupsCtrl',
      controllerAs: 'ctrl',
      scope: {
        survey: "=",
      }
    }

class SurveyResponderGroupsCtrl extends Controller

  constructor: ($scope, @$rootScope) ->
    @survey = $scope.survey
    @responders = @survey.responders
    @sectionState = {}
    @currentLanguage = @$rootScope.currentPerson.profile_language
    @defaultLanguage = 'en'

    @groupedResponders = _.sortBy(@survey.responders, "role_index")
    _.each @groupedResponders, (role) =>
      locale = _.find(role.locales, (roleLocale) => roleLocale.locale == @currentLanguage)
      if (locale?)
        role.translatedName = locale.name
      else
        role.translatedName = _.find(role.locales, (roleLocale) => roleLocale.locale == @defaultLanguage).name

    @reassignableResponderRoles = _.filter @groupedResponders, (role) -> role.role_name != 'employee'

  sectionOpened: (role) ->
    @sectionState == role

  openOrCloseSection: (role) ->
    if (@sectionState == role)
      @sectionState = {}
    else
      @sectionState = role

  countRespondersForRole: (group) ->
    answeredResponders = @countAnsweredPeople(group.responders)
    group.responders.length + " / " + answeredResponders

  countAnsweredPeople: (responders) ->
    answeredCount = responders.length
    for responder in responders
      if !responder.answered_at?
        answeredCount--
    answeredCount

  deleteResponder: (responder, roleName) ->
    responderGroup = @groupedResponders.filter((p) -> p.role_name is roleName)
    for responderItem, index in responderGroup[0].responders
      if responderItem.id == responder.id
        responderGroup[0].responders.splice(index, 1)
        break

  respondersForRole: (role) ->
    responderGroup = @groupedResponders[role]
    if (responderGroup == undefined)
      @groupedResponders[role] = []
      responderGroup = @groupedResponders[role]
    responderGroup

