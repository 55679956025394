class AnsweringStyleRepository extends Service

  constructor: (@Restangular) ->
    @options = {}

  getCompanyStyle: () ->
    @Restangular.one('company').one('configuration').one('answering_design').get()

  createNewStyle: (styleOptions) ->
    @Restangular.one('company').one('configuration').all('answering_design').post(styleOptions).then (response) ->
      response

  getCompanyImage: () ->
    @Restangular.one('company').one('configuration').all('answering_design').get('image')
