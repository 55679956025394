class DatepickerLocaldate extends Directive
  constructor: ($parse) ->
    return {
      restrict: 'A'
      require: ['ngModel'],
      link: (scope, element, attr, ctrls) -> 
        ngModelController = ctrls[0]
        ngModelController.$parsers.push((viewValue) ->
          viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
          return viewValue.toISOString().substring(0, 10);
        )
    }