class FeedbackModalCtrl extends Controller
  constructor: (@$scope, @$modalInstance, @$timeout, @$rootScope, @toastr, @availablePeople, @categories, @PersonalFeedbackRepository, @$translate, @PersonalFeedbackCategoryRepository) ->
    @scale = ['very_poor', 'poor', 'regular', 'good', 'excellent']

    @slider = {
      floor: 1,
      ceil: @scale.length,
      minValue: 1,
      maxValue: @scale.length,
      showTicksValues: true,
      translate: (value) => @$translate.instant('feedbacks.' + @scale[value - 1])
    }

    @feedback = {
      rating: @slider.maxValue,
      visibility: "everyone",
      subject: @_preSelectPerson(),
      anonymous: false,
      category: @defaultCategory()
    }

  # Bug
  # https://github.com/angular-slider/angularjs-slider/issues/78
  setSlider: () ->
    @$timeout ( => @$scope.$broadcast('rzSliderForceRender')), 200

  submit: (form) ->
    if(form.$valid)
      @_formatFeedback()
      if(@feedback.to.indexOf(@$rootScope.currentPerson.id) != -1)
        @toastr.error @$translate.instant('feedbacks.self_error')
      else
        @PersonalFeedbackRepository.create(@feedback).then((response) =>
          if(response.status > 199 && response.status < 300)
            @toastr.success @$translate.instant('feedbacks.sent')
            @$modalInstance.close()
            @$rootScope.$broadcast("personal_feedback_created");
          else
            @toastr.error @$translate.instant('feedbacks.error')
        ).then((response) =>

        ).then null, (err) =>
          @toastr.error @$translate.instant('feedbacks.error')

  _formatFeedback: () ->
    @feedback.to = _.map @feedback.subject, (subject) -> { id: subject.id, entity_id: subject.entity_id }
    @feedback.personal_feedback_category_id = @feedback.category.id
    @feedback.category = undefined
    @feedback.subject = undefined
    @feedback.category = undefined

  close: () ->
    @$modalInstance.close()

  _preSelectPerson: () ->
    if(@$scope.person != undefined)
      selectedPerson =  _.find(@availablePeople, (person) =>
        person.id == @$scope.$parent.person
      )
      return if (selectedPerson == undefined) then undefined else [selectedPerson]

  defaultCategory: () ->
    index = _.findIndex(@categories, {is_default: true})
    return @categories[index]
