class EmailDeliveryConfigurationRepository extends Service

  constructor: (@Restangular, @EmailDeliveryConfiguration) ->

  get: (options = {}) ->
    @Restangular.one('email_delivery_configuration').get(options).then (response) =>
      new @EmailDeliveryConfiguration(response.data)

  load: (deliveryConfigurationId, options = {}) ->
    @Restangular.one('email_delivery_configuration', deliveryConfigurationId).get().then (response) =>
      new @EmailDeliveryConfiguration(response.data)

  update: (deliveryConfiguration, options = {}) ->
    angular.extend(deliveryConfiguration, options)
    @Restangular.one('email_delivery_configuration', deliveryConfiguration.id).patch(deliveryConfiguration)

  create: (deliveryConfiguration, options = {}) ->
    @Restangular.all('email_delivery_configuration').post(deliveryConfiguration)
