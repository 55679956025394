class PersonalFeedback extends Service
  constructor: ($rootScope, Base, $translate) -> return class PersonalFeedback extends Base
    constructor: (json) ->
      angular.extend @, json

    commentShortened: () ->
      @long_comment.length > @short_comment.length

    shortComment: () ->
      short_comment = @short_comment
      if(@commentShortened())
        short_comment += "..."
      short_comment

    @mapPersonalFeedbacks: (feedbacks) ->
      _.map(feedbacks, (feedback) ->
        new PersonalFeedback(feedback)
      )

    _anonymous: () ->
      @from == null

    _isGivenByCurrentPerson: () ->
      !@_anonymous() && @from.id == $rootScope.currentPerson.id

    isGivenToMe: () ->
      @to.id == $rootScope.currentPerson.id

    getName: () ->
      if(@isGivenToMe())
        $translate.instant("feedbacks.to_you")
      else
        $translate.instant("feedbacks.to_user") + " " + @to.name

    type: () ->
      if @_isGivenByCurrentPerson()
        "ME"
      else if @_anonymous()
        "ANONYMOUS"
      else
        "STANDARD"