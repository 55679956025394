angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'satisfaction_survey.show',
        parent: 'protected'
        url: '/satisfaction-surveys/{survey_id:int}'
        params:
          survey: null
          selectionRepository: null
          answerMassUpload: null
          answerMassUploadDeletion: null
          responderMassUpload: null
          responderMassUploadDeletion: null
        template: '<ui-view/>'
        resolve:
          survey: ($stateParams, SatisfactionSurvey) ->
            $stateParams.survey || SatisfactionSurvey.one($stateParams.survey_id)
          selectionRepository: ($stateParams, survey, SatisfactionSurvey) ->
            $stateParams.selectionRepository || SatisfactionSurvey.getSelectionRepository(survey.id)
          answerMassUpload: ($stateParams, survey, SurveyRepository) ->
            $stateParams.answerMassUpload || SurveyRepository.getLastAnswerMassUpload(survey.id)
          answerMassUploadDeletion: ($stateParams, answerMassUpload, survey, SurveyRepository) ->
            if $stateParams.answerMassUploadDeletion
              $stateParams.answerMassUploadDeletion
            else if answerMassUpload && (answerMassUpload.state == 'succeeded' || answerMassUpload.state == 'failed')
              SurveyRepository.getLastAnswerMassUploadDeletion(survey.id)
            else
              null
          responderMassUpload: ($stateParams, survey, SurveyRepository) ->
            $stateParams.responderMassUpload || SurveyRepository.getLastResponderMassUpload(survey.id)
          responderMassUploadDeletion: ($stateParams, responderMassUpload, survey, SurveyRepository) ->
            if $stateParams.responderMassUploadDeletion
              $stateParams.responderMassUploadDeletion
            else if responderMassUpload && (responderMassUpload.state == 'succeeded' || responderMassUpload.state == 'failed')
              SurveyRepository.getLastResponderMassUploadDeletion(survey.id)
            else
              null
        views:
          '@':
            controller: "ShowSatisfactionSurveyCtrl"
            controllerAs: "ctrl"
            templateUrl: "satisfaction_surveys/showSatisfactionSurvey.html"

class ShowSatisfactionSurveyCtrl extends Controller
  constructor: (
    @$rootScope, @$scope, @$modal, @$state, @$stateParams, @$translate, @toastr,
    @survey, @currentPerson, @selectionRepository, @SurveyRepository, @Structure,
    @answerMassUpload, @answerMassUploadDeletion, @responderMassUpload, @responderMassUploadDeletion
  ) ->
    @structure = _.map @selectionRepository.entities, (entity) => new @Structure(entity)
    @company = _.find @structure, (entity) => entity.id == @survey.company_id
    @unitAnsweringAvailable = @_checkUnitAnsweringAvailability()
    @structureMetadata = @_calculateStructureMetadata()
    @professionalGroups = @selectionRepository.professional_groups
    @ageGroups = @selectionRepository.age_groups
    @tenureGroups = @selectionRepository.tenure_groups
    @answerMassUploadSettings = @_composeAnswerMassUploadSettings()
    @answerMassUploadState = @answerMassUploadSettings.state
    @responderMassUploadSettings = @_composeResponderMassUploadSettings()
    @responderMassUploadState = @responderMassUploadSettings.state

  downloadResponderStatistics: () ->
    lang = @currentPerson.language
    token = @currentPerson.token
    @_openReportGeneratorUrl("responder-statistics/#{@survey.id}?lang=#{lang}&token=#{token}")

  fetchReport: (group, format) ->
    surveyId = @survey.id
    person = @currentPerson
    @_openReportGeneratorUrl("statistics/#{surveyId}?lang=#{person.language}&token=#{person.token}&format=#{format}&group=#{group}")

  isChild: (structureId) ->
    @survey.company_id != structureId

  isReportable: (selectionItem) ->
    statistics = selectionItem.statistics
    completed = statistics && statistics.completed
    completed && completed > 4

  getResponderStatisticsText: (group) ->
    statistics = group.statistics
    statistics && statistics.completed + " / " + statistics.all

  getLongText: (prefix, group) ->
    key = @_getLongKey(prefix, group)
    key && @$translate.instant(key)

  uploadAnswers: (file) ->
    # This method sometimes gets called with null argument by the ng-file-upload module for some reason.
    if !file
      return

    @toastr.success(@$translate.instant('general.starts'))
    @SurveyRepository.massUploadAnswers(
      @survey.id, file
    ).then (response) =>
      @$state.go(
        'satisfaction_survey.show',
        # Optimize state reload:
        { survey: @survey, selectionRepository: @selectionRepository, answerMassUpload: response.data },
        { reload: true }
      )

  downloadAnswerMassUploadExample: () ->
    @_downloadMassUploadExample('answers')

  checkAnswerMassUploadProgress: () ->
    stateBefore = @answerMassUpload.state
    @SurveyRepository.getLastAnswerMassUpload(@survey.id).then (task) =>
      stateAfter = task && task.state
      if stateAfter != stateBefore
        # Reload selection repository:
        @$state.go(
          'satisfaction_survey.show',
          { survey: @survey, selectionRepository: null, answerMassUpload: task, answerMassUploadDeletion: null },
          { reload: true }
        )

  checkAnswerMassUploadDeletionProgress: () ->
    stateBefore = @answerMassUploadDeletion.state
    @SurveyRepository.getLastAnswerMassUploadDeletion(@survey.id).then (task) =>
      stateAfter = task && task.state
      if stateAfter != stateBefore
        # Reload selection repository:
        @$state.go(
          'satisfaction_survey.show',
          { survey: @survey, selectionRepository: null, answerMassUpload: null, answerMassUploadDeletion: task },
          { reload: true }
        )

  openAnswerMassUploadDeletionDialog: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'satisfaction_surveys/answerMassUploadDeletionDialog.html'
      controller: 'AnswerMassUploadDeletionController'
      controllerAs: 'ctrl'
      resolve:
        survey: => @survey
        selectionRepository: => @selectionRepository
        answerMassUpload: => @answerMassUpload
        deleteAnswerMassUpload: => @deleteAnswerMassUpload
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  deleteAnswerMassUpload: () ->
    @toastr.success(@$translate.instant('general.starts'))
    @SurveyRepository.deleteAnswerMassUpload(@survey.id).then (response) =>
      @$state.go(
        'satisfaction_survey.show',
        # Optimize state reload:
        { survey: @survey, selectionRepository: @selectionRepository, answerMassUpload: @answerMassUpload, answerMassUploadDeletion: response.data },
        { reload: true }
      )

  uploadResponders: (file) ->
    # This method sometimes gets called with null argument by the ng-file-upload module for some reason.
    if !file
      return

    @toastr.success(@$translate.instant('general.starts'))
    @SurveyRepository.massUploadResponders(
      @survey.id, file
    ).then (response) =>
      @$state.go(
        'satisfaction_survey.show',
        # Optimize state reload:
        { survey: @survey, selectionRepository: @selectionRepository, responderMassUpload: response.data },
        { reload: true }
      )

  downloadResponderMassUploadExample: () ->
    @_downloadMassUploadExample('responders')

  checkResponderMassUploadProgress: () ->
    stateBefore = @responderMassUpload.state
    @SurveyRepository.getLastResponderMassUpload(@survey.id).then (task) =>
      stateAfter = task && task.state
      if stateAfter != stateBefore
        # Reload selection repository:
        @$state.go(
          'satisfaction_survey.show',
          { survey: @survey, selectionRepository: null, responderMassUpload: task, responderMassUploadDeletion: null },
          { reload: true }
        )

  checkResponderMassUploadDeletionProgress: () ->
    stateBefore = @responderMassUploadDeletion.state
    @SurveyRepository.getLastResponderMassUploadDeletion(@survey.id).then (task) =>
      stateAfter = task && task.state
      if stateAfter != stateBefore
        # Reload selection repository:
        @$state.go(
          'satisfaction_survey.show',
          { survey: @survey, selectionRepository: null, responderMassUpload: null, responderMassUploadDeletion: task },
          { reload: true }
        )

  openResponderMassUploadDeletionDialog: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'satisfaction_surveys/responderMassUploadDeletionDialog.html'
      controller: 'ResponderMassUploadDeletionController'
      controllerAs: 'ctrl'
      resolve:
        survey: => @survey
        selectionRepository: => @selectionRepository
        responderMassUpload: => @responderMassUpload
        deleteResponderMassUpload: => @deleteResponderMassUpload
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  deleteResponderMassUpload: () ->
    @toastr.success(@$translate.instant('general.starts'))
    @SurveyRepository.deleteResponderMassUpload(@survey.id).then (response) =>
      @$state.go(
        'satisfaction_survey.show',
        # Optimize state reload:
        { survey: @survey, selectionRepository: @selectionRepository, responderMassUpload: @responderMassUpload, responderMassUploadDeletion: response.data },
        { reload: true }
      )

  _getLongKey: (prefix, group) ->
    group.long_key && prefix + "." + group.long_key

  _checkUnitAnsweringAvailability: () ->
    _.some @structure, (entity) -> !_.isEmpty(entity.answering_url)

  _calculateStructureMetadata: () ->
    data = {}

    if @unitAnsweringAvailable
      data.unit_name = { width: "40%" }
    else
      data.unit_name = { width: "87%" }

    data

  _composeAnswerMassUploadSettings: () ->
    @_composeMassUploadSettings(@answerMassUploadDeletion, @answerMassUpload)

  _composeResponderMassUploadSettings: () ->
    @_composeMassUploadSettings(@responderMassUploadDeletion, @responderMassUpload)

  _composeMassUploadSettings: (deletion, upload) ->
    settings = {
      sectionVisible: @survey.answer_mass_upload_allowed || !!upload
      actionsAllowed: @survey.answer_mass_upload_allowed
      acceptedFormats: ['CSV']
      state: @_getMassUploadState(deletion, upload)
    }
    settings.actionPending = settings.state == 'started' || settings.state == 'deletion_pending'
    settings

  _getMassUploadState: (deletion, upload) ->
    if deletion && deletion.state == 'started'
      'deletion_pending'
    else if upload
      upload.state
    else
      'not_yet_started'

  _downloadMassUploadExample: (type) ->
    lang = @currentPerson.language
    token = @currentPerson.token
    @_openReportGeneratorUrl("mass-uploads/#{@survey.id}/#{type}/example?lang=#{lang}&token=#{token}")

  _openReportGeneratorUrl: (relativeUrl) ->
    baseUrl = @$rootScope.config.report_generator_url
    url = "#{baseUrl}/#{relativeUrl}"
    window.open(url)
