class TableOptions extends Service
  constructor: (Constants) -> return class TableOptions
    constructor: (options) ->
      @options          = options or {}
      @translationScope = @options.translationScope or 'general'
      @sortByField      = @options.defaultSortField
      @sortDirectionAsc = Constants.defaults.IS_SORT_ASC
      @sortAction = @options.sortAction

    isAscFor: (field) ->
      @sortByField is field and @sortDirectionAsc
    isDescFor: (field) ->
      @sortByField is field and not @sortDirectionAsc

    sortBy: (field) =>
      @sortByField = field
      @sortDirectionAsc = !@sortDirectionAsc

      @sortAction({sortField: @sortByField, sortIsAsc: @sortDirectionAsc})