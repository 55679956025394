class EmploymentTerminateModalCtrl extends Controller
  constructor: (@$modalInstance, @Structure, @AffiliatableRole, @EmploymentService, @employment, @person, roles) ->
    @_initializeRoles(roles)

  terminate: ->
    @EmploymentService.terminateWithRoles(@employment.id, @roles).then =>
      @$modalInstance.close("terminated")

  cancel: ->
    @$modalInstance.dismiss("dismissed")

  _initializeRoles: (roles) ->
    @roles = _.map roles, (role_data) =>
      role = new @AffiliatableRole(role_data)
      if role.entity
        role.entity = new @Structure(role_data.entity)
      role.deletable = true
      role
      
