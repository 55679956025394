class TrainingNeeds extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '='
      },
      controller: 'TrainingNeedsCtrl',
      controllerAs: 'ctrl'
      templateUrl: 'directives/survey_result/training_needs.html'
    }

class TrainingNeedsCtrl extends Controller
  constructor: (@$scope, @$rootScope) ->
    @data = @$scope.data
    @groupedTrainings = @_groupedTrainings(@data.answers)

  _groupedTrainings: (answers) ->
    flat = []
    _.each(answers, (a) ->
      _.each(a.content, (c) ->
        flat.push({
          id: a.id,
          answerer_name: a.answerer_name,
          content: c
        })
      )
    )
    _.groupBy(flat, (a) -> a.content.title + ' ' + '(' + a.content.start_time + ' - ' + a.content.end_time + ')')
