class SurveyRepository extends Service
  constructor: (@Restangular, @Survey, @Paging, @Constants, @Upload, @$rootScope) ->
    @surveys = []

  initialize: (paging, options = {}) ->
    @surveys = []
    @fetchSurveysForPage(paging, options)

  fetchSurveysForPage: (paging, options = {}) ->
    if @surveys[paging.iterator()] == undefined
      options.page = paging.page
      @fetchPersonalFeedbacks(options)

  fetchPersonalFeedbacks: (options = {}) =>
    @getActiveFeedbacks(options).then (response) =>
      paging = new @Paging(response)
      @addSurveysToList(response.data, paging)
      paging

  addSurveysToList: (surveys, paging) =>
    @surveys[paging.iterator(paging.page)] = _.map surveys, (survey) => new @Survey(survey)

  getForPage: (paging) ->
    @surveys[paging.iterator()]

  filterOverviewStatistics: (options = {}) ->
    @Restangular.one("surveys").one("overview/filter").patch(options)

  getOverviewStatistics: (options = {}) ->
    @Restangular.one('surveys').one('overview').get(options)

  getGivenFeedbacks: (options = {}) ->
    @Restangular.one('surveys').all('feedbacks/given').getList(options)

  getActiveFeedbacks: (options = {}) ->
    @Restangular.one('surveys').all('feedbacks/active').getList(options)

  getReceivedFeedbacks: (options = {}) ->
    @Restangular.all('surveys').customGET('feedbacks/received', options)

  # Converts a data file into a plain 2-dimensional array:
  extractFileData: (file) ->
    @Upload.upload(
      url: @$rootScope.config.report_generator_url + '/files/extract',
      data: { body: file }
    )

  # Converts a data file into an array of objects where column names are used as keys:
  extractFileDataWithColumnsAsKeys: (file) ->
    @Upload.upload(
      url: @$rootScope.config.report_generator_url + '/files/extract?columns=true',
      data: { body: file }
    )

  validateMassDrafts: (params) ->
    @Restangular.all('surveys').all('drafts/validate').post(params)

  createMassDrafts: (params) ->
    @Restangular.all('surveys').all('drafts/create').post(params)

  confirmMassDraftsCreation: (validationId) ->
    @Restangular.all('surveys/drafts').one('validations', validationId).one('confirm').post()

  cancelMassDraftsCreation: (validationId) ->
    @Restangular.all('surveys/drafts').one('validations', validationId).one('discard').remove()

  massSendOut: (survey_ids, comment, deadline, send_notification) ->
    params = { survey_ids, comment, deadline, send_notification }
    @Restangular.one('surveys').one('send_out').customPOST(params)

  massClose: (survey_ids) ->
    @Restangular.one('surveys').one('close').customPOST({ survey_ids })

  massOpen: (survey_ids) ->
    @Restangular.one('surveys').one('open').customPOST({ survey_ids })

  massGetNotifiableRolesForFinishing: (survey_ids) ->
    params = { survey_ids }
    @Restangular.one("surveys").one("finishing/notifiable_roles").customPOST(params).then (response) ->
      response.data

  massFinish: (survey_ids, notificationConfig) ->
    params = { survey_ids }
    _.each _.keys(notificationConfig), (role) ->
      params["notify_" + role] = notificationConfig[role]

    @Restangular.one('surveys').one('finish').customPOST(params)

  massDelete: (survey_ids) ->
    @Restangular.one('surveys').one('destroy').customPOST({ survey_ids })

  massUploadAnswers: (surveyId, file) ->
    @Upload.upload(
      url: 'api/v2/survey/' + surveyId  + '/answers/upload',
      data: { body: file }
    )

  massUploadResponders: (surveyId, file) ->
    @Upload.upload(
      url: 'api/v2/survey/' + surveyId  + '/individual_responders/upload',
      data: { body: file }
    )

  deleteAnswerMassUpload: (surveyId) ->
    @Restangular.one('survey', surveyId).one('answers/destroy_upload').remove()

  deleteResponderMassUpload: (surveyId) ->
    @Restangular.one('survey', surveyId).one('individual_responders/destroy_upload').remove()

  get: (surveyId, options = {}) ->
    @Restangular.one('survey', surveyId).get().then (response) =>
      new @Survey(response.data)

  getResult: (surveyId, options = {}) ->
    @Restangular.one('survey', surveyId).one('result').get()

  getEvaluationTemplates: () ->
    @Restangular.all('templates').getList({survey_type: @Constants.survey_types.EVALUATION}).then (response) -> response.data

  getAppraisalInterviewTemplates: () ->
    @Restangular.all('templates').getList({survey_type: @Constants.survey_types.APPRAISAL_INTERVIEW}).then (response) -> response.data

  getLastMassDraftsValidation: () ->
    @Restangular.all('surveys').one('drafts/last_validation').get().then (response) -> response.data

  getMassDraftsValidation: (validationId) ->
    @Restangular.all('surveys').one('drafts/validations', validationId).get().then (response) -> response.data

  getAssociatedMassDraftsConfirmation: (validationId) ->
    @Restangular.all('surveys').one('drafts/validations', validationId).one('confirmation').get().then (response) -> response.data

  getMassDraftsConfirmation: (confirmationId) ->
    @Restangular.all('surveys').one('drafts/confirmations', confirmationId).get().then (response) -> response.data

  getLastAnswerMassUpload: (surveyId) ->
    @Restangular.one('survey', surveyId).one('answers/last_upload').get().then (response) -> response.data

  getLastAnswerMassUploadDeletion: (surveyId) ->
    @Restangular.one('survey', surveyId).one('answers/last_upload_deletion').get().then (response) -> response.data

  getLastResponderMassUpload: (surveyId) ->
    @Restangular.one('survey', surveyId).one('individual_responders/last_upload').get().then (response) -> response.data

  getLastResponderMassUploadDeletion: (surveyId) ->
    @Restangular.one('survey', surveyId).one('individual_responders/last_upload_deletion').get().then (response) -> response.data

  getMassDraftsErrors: (taskApiPath, taskId, options = {}) ->
    @Restangular.all('surveys/drafts').one(taskApiPath, taskId).all('errors').getList(options)

  getMassUploadErrors: (surveyId, massUploadId, options = {}) ->
    @Restangular.one('survey', surveyId).one('mass_uploads', massUploadId).all('errors').getList(options)

  sendOut: (survey) ->
    request = {
      survey_id: survey.id,
      comment: survey.comment
    }
    @Restangular.one('survey', survey.id).customPOST(request, 'send_out', {}, {})

  setDeadline: (survey, deadline) ->
    @Restangular.one('survey', survey.id).patch({deadline_at: deadline})

  setTemplate: (survey, templateId) ->
    @Restangular.one('survey', survey.id).patch({template_id: templateId})

  getAvailablePeople: (survey, name) ->
    @Restangular.one('survey', survey.id).all('available_people').getList({name: name})

  update: (survey, update) ->
    @Restangular.one('survey', survey.id).patch(update)

  unifyResult: (survey) ->
    @Restangular.one('survey', survey.id).one('unify_result').patch({})

  createSurveyDraft: (employment_id, survey_type) ->
    @Restangular.all('surveys').post({employment_id: employment_id, survey_type: survey_type})
