angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'objectives.new',
        url: "/objectives/new"
        parent: 'protected'
        template: "<ui-view/>"
        resolve:
          entitiesWithPermission: (permissionService) ->
            _.map(permissionService.entitiesWithPermission("allowingToManageObjectives"), 'id')
          people: (Employment, entitiesWithPermission) ->
            Employment.shortEmploymentsForStructureMultiple(entitiesWithPermission)
        views:
          '@':
            controller: "NewObjectiveCtrl"
            controllerAs: "ctrl"
            templateUrl: "objectives/new/newObjective.html"


class NewObjectiveCtrl extends Controller
  constructor: (@$scope, @$state, @currentPerson, @people, @Objective, @Kpi, @toastr, @$translate) ->
    @objective = new @Objective({
      start_date: moment().startOf('year').format('YYYY-MM-DD'),
      end_date: moment().endOf('year').format('YYYY-MM-DD')
    })

    @objective.owner_employment_id = @currentPerson.currentEmployment.id

    @visibleElements = {}
    @people = _.uniq _.map _.sortBy(@people, 'name'), (person) -> {id: person.id, name: person.name}
    @selectedPeople = []
    @$scope.$watchCollection('ctrl.selectedPeople', @updatePeople)

    @kpis = []
    @selectedKpis = []
    @$scope.$watchCollection('ctrl.selectedKpis', @updateKpis)
    @getKpis()
    @selectedKpis = _.where(@kpis, {is_default: true})

    @ratingSlider = {
      floor: 0,
      ceil: 5
    }

  save: =>
    @Objective.create(@objective).then (objective) =>
      @toastr.success @$translate.instant('toastr.actions.created', { item: @$translate.instant('objectives.new') })
      @$state.go('objective', {objective_id: objective.id})

  back: =>
    @$state.go('objectives')

  getKpis: =>
    @Kpi.getCustom().then (response) =>
      @kpis = response.data
      @selectedKpis = _.where(@kpis, {is_default: true})

  addPerson: (person) =>
    @objective.addPerson(person)

  activeGoals: () =>
    []

  kpiList: () =>
    _.groupBy @objective.goals, (goal) -> goal.kpi

  updatePeople: (people) =>
    @objective.updatePeople(people)

  updateKpis: (kpis) =>
    @objective.updateKpis(kpis)

  setMonths: =>
    @objective.setMonths()

  isErroneous: (field) =>
    (field.$dirty || field.$touched) && field.$invalid

  toggle: (key, index) =>
    @visibleElements["#{key}_#{index}"] = !@visibleElements["#{key}_#{index}"]

  isVisible: (key, index) =>
    !!@visibleElements["#{key}_#{index}"]
