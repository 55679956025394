angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $stateProvider
      .state 'structure.employment',
        url: '/employments/:employment_id'
        abstract: true
        template: '<ui-view/>'
        resolve:
          employment: (Employment, $stateParams) ->
            Employment.get($stateParams.structure_id, $stateParams.employment_id)
