class GoalsRowCtrl extends Controller
  constructor: ($scope) ->
    @goals      = $scope.employmentWithGoal.goals
    @employment = $scope.employmentWithGoal

    @goalsVisible = false

  totalWeight: => _.sum @goals, (goal) -> goal.weight

  toggleGoalsVisibility: => @goalsVisible = !@goalsVisible
