angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'objective',
        parent: 'protected'
        url: "/objectives/{objective_id:int}"
        template: "<ui-view/>"
        resolve:
          objective: ($stateParams, Objective) ->
            Objective.one($stateParams.objective_id)
        views:
          '@':
            controller: "ObjectiveCtrl"
            controllerAs: "ctrl"
            templateUrl: "objectives/show/objective.html"

class ObjectiveCtrl extends Controller
  constructor: (@$state, @currentPerson, @objective) ->
    @backState =
      'objectives({employment_id: ' + @objective.owner_employment_id + '})'

    @people = _.pluck(@objective.people, 'name').join(', ')

    @chartData = {
      months: _.map @objective.months, (month) -> month
      kpis: @objective.kpis
      goals: @objective.goals
      title: @objective.title
    }
