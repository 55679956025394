class ActivityFeedCtrl extends Controller
  constructor: (@$rootScope, @$scope, @PersonalFeedbackRepository, Paging, response) ->
    @paging = new Paging(response)
    @PersonalFeedbackRepository.initialize(@paging)
    @commentState = {}
    @$rootScope.$on "personal_feedback_created", () =>
      @changePage(0, true)

  changePage: (page, forceDownload=false) =>
    @paging.changePage(page)

    feedbacks = @PersonalFeedbackRepository.fetchFeedbacksForPage(@paging, forceDownload)
    if(feedbacks != undefined)
      feedbacks.then (paging) =>
        @paging = paging
      
  getForPage: () ->
    @PersonalFeedbackRepository.getForPage(@paging)

  showLongComment: (feedItem) ->
    if(@commentState[feedItem.id] == undefined)
      @commentState[feedItem.id] = {}
    @commentState[feedItem.id].long=true

  hideShortComment: (feedItem) ->
    if(@commentState[feedItem.id] == undefined)
      @commentState[feedItem.id] = {}
    @commentState[feedItem.id].long=false

    
    