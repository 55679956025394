class AcceptRejectModalCtrl extends Controller
  constructor: (@$scope, @$modalInstance, @$translate, @toastr, @enrollment, @decisionType) ->
    @isAcceptModal = @decisionType == 'accept'
    @isRejectModal = @decisionType == 'reject'

  acceptEnrollment: =>
    @enrollment.accept().then (updated_enrollment) =>
      @toastr.success @$translate.instant('trainings.participation_accepted')
      @$modalInstance.close(updated_enrollment)

  rejectEnrollment: =>
    @enrollment.reject().then (updated_enrollment) =>
      @toastr.success @$translate.instant('trainings.participation_rejected')
      @$modalInstance.close(updated_enrollment)

  cancel: => @$modalInstance.dismiss('cancelled')
