class Base extends Service('Upsteem.Models')
  constructor: -> return class Base
    @include: (mixin) ->
      for name, method of mixin
        method['super'] = @prototype[name]
        @prototype[name] = method

    klass: -> @constructor.name
    tableize: -> @klass().tableize()
    toPath: -> "/#{@tableize()}/#{@id}"
