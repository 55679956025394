class CustomTemplateGroupRowCtrl extends Controller

  constructor: (@$scope, @toastr, @$state, @Constants, @$translate, @EmailTemplateGroupRepository, @EmailTemplateRepository, @EmailDeliveryConfigurationRepository, @EmailDeliveryConfiguration) ->
    @editNameMode = false
    @originalName = undefined
    @emailListVisible = false
    @emailDeliveryConfVisible = false
    @emailTemplates = []
    @emailDeliveryConfiguration = undefined

  editName: () ->
    if @editNameMode
      @editNameMode = false
    else
      @editNameMode = true
      @originalName = @$scope.group.name
      @_getEmailDeliveryConfiguration()

  updateName: () ->
    return unless @editNameMode

    @editNameMode = false

    updateParams = {name: @$scope.group.name}
    @EmailTemplateGroupRepository.update(@$scope.group.id, updateParams).then (templateGroup) =>
      @toastr.success(@$translate.instant("emails.template_groups.updated"))

  updateOrRevertName: (event) ->
    if event.keyCode == @Constants.keycodes.ENTER
      @updateName()
    else if event.keyCode == @Constants.keycodes.ESCAPE
      @_revertName()

  updateDeliveryConfiguration: () ->
    @editNameMode = false
    if @emailDeliveryConfiguration.id
      @EmailDeliveryConfigurationRepository.update(@emailDeliveryConfiguration).then (response) =>
        @toastr.success(@$translate.instant("emails.delivery_configurations.updated"))
    else
      console.debug(@emailDeliveryConfiguration)
      @EmailDeliveryConfigurationRepository.create(@emailDeliveryConfiguration).then (response) =>
        console.debug(response.data)
        @$scope.group.email_delivery_configurations.push(new @EmailDeliveryConfiguration(response.data))
        @toastr.success(@$translate.instant("emails.delivery_configurations.updated"))

  _getEmailDeliveryConfiguration: () ->
    group_based_conf = @$scope.group.email_delivery_configurations[0]
    if group_based_conf
      @EmailDeliveryConfigurationRepository.load(group_based_conf.id).then (delivery_conf) =>
        @emailDeliveryConfiguration = delivery_conf
        @_showEmailDeliveryConfiguration()
    else
      data = {
        reply_to: @$scope.ctrl.deliveryConfiguration.reply_to,
        sender_name: @$scope.ctrl.deliveryConfiguration.sender_name,
        sender_email: @$scope.ctrl.deliveryConfiguration.sender_email,
        email_template_group_id: @$scope.group.id
      }
      @emailDeliveryConfiguration = new @EmailDeliveryConfiguration(data)
      @_showEmailDeliveryConfiguration()

  getEmailTemplates: () ->
    @_togglEmailListVisibility()

    return unless @emailListVisible

    @EmailTemplateRepository.getTemplatesForGroup(@$scope.group.id).then (templates) =>
      @emailTemplates = templates

  editTemplate: (templateId) ->
    @$state.go('email_template', {id: templateId})

  updateUsability: (template) ->
    options = { active: template.active }
    @EmailTemplateRepository.update(template.id, options).then (updated_template) =>
      @toastr.success(@$translate.instant("emails.templates.updated"))

  _revertName: () ->
    return unless @editNameMode

    @$scope.group.name = @originalName
    @editNameMode = false

  _togglEmailListVisibility: () ->
    @emailListVisible = !@editNameMode && !@emailListVisible

  _showEmailDeliveryConfiguration: () ->
    @emailDeliveryConfVisible = true

