class PersonalFeedbackCtrl extends Controller
  constructor: (@$rootScope, $scope, Constants, @Paging, @PersonalFeedbackRepository, @PersonalFeedback) ->
    @periods = _.values(Constants.personal_feedback_periods)
    @selectedPeriod = Constants.personal_feedback_periods.ALL
    @opened = false
    @person = $scope.$parent.ctrl.person
    @init()
    @$rootScope.$on "personal_feedback_created", () =>
      @fetchAll()

  init: () ->
    @fetchStats()

  load: =>
    if _.isUndefined(@all)
      @all = new Array(0)
      @fetchAll().then =>
        @toggleSection()
    else if @paging
      @toggleSection()

  periodChanged: () ->
    @fetchAll()

  changePage: (page) ->
    @paging.changePage(page)
    if _.isUndefined(@all[@paging.iterator()])
      @fetchPersonalFeedbacks({ page: page })

  getForPage: () ->
    if(@paging != undefined)
      @all[@paging.iterator()]

  fetchAll: () ->
    @fetchStats()
    @fetchPersonalFeedbacks()

  fetchStats: () ->
    @PersonalFeedbackRepository.getStatsForPerson(@person.id, { period: @selectedPeriod }).then (response) =>
      @average = response.data.average
      @trend = response.data.trend
      @total = response.data.total

  fetchPersonalFeedbacks: (options = {}) ->
    @$rootScope.isStateLoading = true
    @PersonalFeedbackRepository.getAllForPerson(@person.id, { period: @selectedPeriod, page: options.page }).then (response) =>
      @_refreshData(response)
      @$rootScope.isStateLoading = false

  toggleSection: ->
    @opened = !@opened

  _refreshData: (response) ->
    newPaging = new @Paging(response)
    if !@paging || newPaging.getTotal() != @paging.getTotal()
      @all = new Array(newPaging.getTotalPages())
    @paging = newPaging
    @addPersonalFeedbacksToList(response.data)

  addPersonalFeedbacksToList: (feedbacks) ->
    @all[@paging.iterator()] = @PersonalFeedback.mapPersonalFeedbacks(feedbacks)
