angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey_responder_role_groups',
        parent: 'protected'
        url: '/survey_responder_roles/groups'
        params: {
          group_id: null
        }
        template: "<ui-view/>"
        resolve:
          defaultGroups: (SurveyResponderRoleGroupRepository) =>
            SurveyResponderRoleGroupRepository.getDefault()
          customGroups: (SurveyResponderRoleGroupRepository) =>
            SurveyResponderRoleGroupRepository.getCustom()
        views:
          '@':
            controller: "GroupListCtrl"
            controllerAs: "ctrl"
            templateUrl: "survey_responder_roles/groups/groups.html"

class GroupListCtrl extends Controller
  constructor: (@$stateParams, @defaultGroups, @customGroups, @SurveyResponderRoleGroupRepository, @toastr, @$translate) ->

  defaultName: (_group) ->
    @$translate.instant("general.system_wide")

  copyDefault: (group) ->
    @copy(group, @defaultName(group))

  copyCustom: (group) ->
    @copy(group, group.name)

  copy: (group, name = "") ->
    @SurveyResponderRoleGroupRepository.create(group.id, name: name + " (copy)").then (customGroup) =>
      @customGroups.push(customGroup)
      @toastr.success(@$translate.instant("survey_responder_roles.groups.created"))

  delete: (index) ->
    group = @customGroups[index]

    @SurveyResponderRoleGroupRepository.delete(group.id).then () =>
      @customGroups.splice(index,1)
      @toastr.success(@$translate.instant("survey_responder_roles.groups.deleted"))
