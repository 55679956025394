class SurveyResponderRole extends Service
  constructor: (Base, SurveyResponderRoleLocale) ->
    return class SurveyResponderRole extends Base
      constructor: (json) ->
        angular.extend(@, json)
        @locales = _.groupBy(_.map(@locales, (locale) => new SurveyResponderRoleLocale(locale)), (locale) -> locale.locale)

      findOrInitializeRoleLocale: (locale) =>
        roleLocale = @roleForLocale(locale)
        return roleLocale if roleLocale

        roleLocale = { locale: locale, name: "", abbreviation: "" }
        @locales[locale] = [roleLocale]
        roleLocale

      # Removes role locales which were obviously not intended to be edited.
      cleanAwayIncompleteRoleLocales: () =>
        _.each _.keys(@locales), (locale) =>
          roleLocale = @roleForLocale(locale)
          if _.isEmpty(roleLocale.id) && _.isEmpty(roleLocale.name) && _.isEmpty(roleLocale.abbreviation)
            delete @locales[locale]

      # TODO: Change method name - it's misleading.
      roleForLocale: (locale) =>
        translation = @locales[locale]
        return unless translation
        translation[0]

      languageOrGivenLocale: (locale) ->
        if @language
          @language
        else
          locale
