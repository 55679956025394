String::tableize   = -> inflection.tableize @
String::underscore = -> inflection.underscore @
String::_isEmpty = (options) -> _.isEmpty @, options
String::_isNotEmpty = (options) -> not _.isEmpty @, options
String::_includes = (collection, target, fromIndex) ->
  _.includes @, collection, target, fromIndex

Array::filter      = (predicate, thisArg) -> _.filter @, predicate, thisArg
Array::map         = (iteratee, thisArg) -> _.map @, iteratee, thisArg
Array::each        = (iteratee, thisArg) -> _.each @, iteratee, thisArg
Array::flatten     = (isDeep) -> _.flatten @, isDeep
Array::flatMap     = (cb) -> _.flatten _.map @, cb
Array::includes    = (cb) -> _.includes @, cb
Array::_includes   = (cb) -> _.includes @, cb
Array::_remove     = (cb) -> _.remove @, cb
Array::sortByOrder = (iteratees, orders, cb) -> _.sortByOrder @, iteratees, orders, cb
Array::_some        = (predicate, thisArg) -> _.some @, predicate, thisArg
Array::_compact = -> _.compact @
Array::_last = -> _.last @
Array::_first = -> _.first @
Array::_second = -> @[1]
