class VacancyAction extends Directive
  constructor: () ->
    return {
      scope: {
        vacancy: '=',
        employment: '=',
        structure: '=',
        vacancies: '='
      }
      restrict: 'E'
      templateUrl: 'directives/vacancyAction.html',
      controller: 'VacancyActionCtrl',
      controllerAs: 'ctrl'
    }

class VacancyActionCtrl extends Controller
  constructor: (@$scope, @$state, @Restangular, @$modal, @toastr, @$translate, @SurveyRepository) ->
    @vacancy = @$scope.vacancy
    @employment = @$scope.employment
    @structure = @$scope.structure
    @vacancies = @$scope.vacancies
    @_active = ''

  activatePane: (name) -> @_active = if @_active is name then '' else name
  paneClass:    (name) -> 'active' if name is @_active
  moreIcon:     (name) -> if @_active is name then 'fa-angle-down' else 'fa-angle-right'

  createSurveyDraftAndRedirect: (employment_id, survey_type) =>
    @toastr.success "", @$translate.instant('general.starts')
    @SurveyRepository.createSurveyDraft(employment_id, survey_type).then (response) =>
      @$state.go('survey', { id: response.data.id }, { reload: true }).then () =>
        @toastr.success "", @$translate.instant('general.started')

  openVacancyDeleteModal: (vacancy) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'vacancy/delete/vacancyDelete.html'
      controller: "VacancyDeleteModalCtrl"
      controllerAs: 'vacancyModalCtrl'
      resolve:
        vacancy: =>
          @Restangular.restangularizeElement(null, vacancy, 'vacancies')
    .result.then =>
      @toastr.success @$translate.instant('vacancy.deleted')
      _.remove(@vacancies, vacancy)

  sendAffiliationReminderFor: (employment) ->
    @Restangular.one('affiliations', employment.id).all('reminders').post().then (response) =>
      @toastr.success "", @$translate.instant('affiliation.reminder_sent')
