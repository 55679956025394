class SinglePlanService extends Service
  constructor: (@SinglePlanRepository, @SinglePlan) ->

  createSinglePlanInstance: () -> new @SinglePlan

  APICreatePlan: (planObject) ->
    @SinglePlanRepository.createPlan(planObject)

  getPlanData: (planId) ->
    @SinglePlanRepository.getPlanById(planId).then (response) =>
      @createSinglePlanInstance().responseToModel(response)

  getSelectedOwner: (people, planModel) ->
    people.filter( (p) => planModel.responsibleEmploymentId == p.id)[0]

  getCurrentLevel: (planModel) ->
    @getLevelList().filter( (p) => planModel.level == p.id)[0]

  getTypeList: () ->
    [
      {
        id: 1,
        translatedType: "Riskiplaan"
      },
      {
        id: 2,
        translatedType: "Eelarveplaan"
      },
      {
        id: 3,
        translatedType: "Strateegiaplaan"
      },
      {
        id: 4,
        translatedType: "Tööplaan"
      },
      {
        id: 5,
        translatedType: "Hankeplaan"
      }
    ]

  getLevelList: () ->
    [
      {
        id: 1,
        name: "Vabariigi valitsus"
      },
      {
        id: 2,
        name: "Riiklik"
      },
      {
        id: 3,
        name: "Ministeerium"
      },
      {
        id: 4,
        name: "Kohalik omavalitsus"
      }
    ]