angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'kpis',
        parent: 'protected'
        url: '/kpis'
        template: "<ui-view/>"
        views:
          '@':
            controller: "KpisListCtrl"
            controllerAs: "ctrl"
            templateUrl: "kpis/list/kpisList.html"

class KpisListCtrl extends Controller
  constructor: (@Kpi, @toastr, @$translate, TableOptions) ->
    @tableOptions = new TableOptions(translationScope: 'kpis')
    @defaultKpis = []
    @customKpis = []
    @allowedValues = {}

    @getDefaultKpis()
    @getCustomKpis()
    @getAllowedValues()

  getDefaultKpis: () ->
    @Kpi.getDefault().then (response) =>
      @defaultKpis = response.data

  getCustomKpis: () ->
    @Kpi.getCustom().then (response) =>
      @customKpis = response.data

  getAllowedValues: () ->
    @Kpi.getValues().then (response) =>
      @allowedValues = response.data

  copy: (kpi) ->
    newKpi = angular.copy kpi
    newKpi.id = undefined
    newKpi.name = undefined
    newKpi.default = false
    @customKpis.push newKpi

  save: (kpi) ->
    @Kpi.create(kpi).then (response) ->
      kpi.id = response.data.id
      kpi.entity_id = response.data.entity_id
    @toastr.success @$translate.instant('toastr.actions.updated', { item: "KPI " + kpi.name })

  delete: (index) ->
    kpi = @customKpis[index]

    if kpi.id == undefined
      @customKpis.splice(index, 1)
      @toastr.success @$translate.instant('toastr.actions.deleted', { item: "KPI "})
    else if kpi.deletable
      @Kpi.delete(kpi)
      @customKpis.splice(index, 1)
      @toastr.success @$translate.instant('toastr.actions.deleted', { item: "KPI " + kpi.name })
