class EditIndividualResponder extends Directive
  constructor: () ->
    return {
      restrict: 'EA',
      templateUrl: 'directives/survey_responders/editIndividualResponder.html',
      controller: 'IndividualResponderCtrl',
      controllerAs: 'ctrl',
      scope: {
        responder: "=",
        role: "=",
        survey: "=",
        reassignableResponderRoles: "="
      }
    }
