class Config extends Service
  constructor: (Base) -> return class Config extends Base
    constructor: (json) ->
      angular.extend @, json

    answeringPath: (token) ->
      @answering_app_host + "/#{token}"

    isGoalsAppAvailable: () ->
      !_.isEmpty(@goals_app_url)

    goalsAppUrl: () ->
      @goals_app_url
