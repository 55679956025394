class SurveySendOutCtrl extends Controller
  constructor: (@$scope, @SurveyRepository, @toastr, @$state, @$translate) ->
    @survey = @$scope.$parent.ctrl.survey

  closeModal: () ->
    @newResponders = []
    @newAnonymousResponder = {}
    @$scope.modalInstance.close()

  sendOut: () ->
    @closeModal()
    @SurveyRepository.sendOut(@survey).then (response) =>
      @$state.go('survey', { id: @survey.id }, { reload: true })
