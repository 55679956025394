angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'email_template',
        parent: 'protected'
        url: '/emails/templates/{id: int}'
        template: "<ui-view/>"
        resolve:
          template: ($stateParams, EmailTemplateRepository) ->
            EmailTemplateRepository.get($stateParams.id)
        views:
          '@':
            controller: "EmailTemplateCtrl"
            controllerAs: "ctrl"
            templateUrl: "emails/templates/template.html"

class EmailTemplateCtrl extends Controller

  constructor: (@template, @toastr, @$translate, @Constants, @EmailTemplateLocaleRepository, @$state, @EmailTemplateRepository) ->
    @previewActive = @template.system_wide
    @_getDefaultTemplateAndSetLocale()

  showRawHtml: () ->
    @_setPreviewStatus(false)

  showPreview: () ->
    @_setPreviewStatus(true)

  shouldShowSubject: () ->
    @template.template_type != "layout"


  changeLocale: (locale) ->
    @currentLocale = locale
    @currentTemplateLocale = @template.getTemplateLocaleByLocale(locale)

    unless @template.system_wide
      @currentDefaultTemplateLocale = @defaultTemplate.getTemplateLocaleByLocale(locale)

  isActiveLocale: (locale) ->
    @currentLocale == locale

  cancel: () ->
    @$state.go('email_template_groups')

  restoreDefault: () ->
    @currentTemplateLocale.title = @currentDefaultTemplateLocale.title
    @currentTemplateLocale.html_content = @currentDefaultTemplateLocale.html_content
    @EmailTemplateLocaleRepository.updateOne(@currentTemplateLocale.id, @currentTemplateLocale).then (response) =>
      @toastr.success(@$translate.instant("emails.templates.default_restored"))

  update: () ->
    updatedTemplateLocales = @_collectUpdatedTemplateLocales()
    @EmailTemplateLocaleRepository.updateAll(@template.id, updatedTemplateLocales).then (response) =>
      @toastr.success(@$translate.instant("emails.templates.updated"))

  _getDefaultTemplateAndSetLocale: () ->
    return if !@template

    if @template.system_wide
      @changeLocale(@Constants.languages.short.en)
    else
      @EmailTemplateRepository.getDefault(@template.id).then (defaultTemplate) =>
        @defaultTemplate = defaultTemplate
        @changeLocale(@Constants.languages.short.en)

  _collectUpdatedTemplateLocales: () ->
    updatedTemplateLocales = _.map(@template.emailTemplateLocales, (locale) -> locale)
    {email_template_locales: updatedTemplateLocales}

  _setPreviewStatus: (status) ->
    return if @template.system_wide

    @previewActive = status
