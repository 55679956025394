angular.module('Upsteem').service 'Storage', ($window, $filter, Constants) ->
  class Storage
    constructor: ->
      @__storage = $window.sessionStorage

    set:    (key, value) -> @__storage.setItem key, value
    get:    (key)        -> @__storage.getItem key || Constants.defaults[key]
    remove: (key)        -> @__storage.removeItem key

  new Storage()
