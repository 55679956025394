class SurveyProjectSurveysCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$translate, @ProjectRepository, @SurveyReportGeneratorService, @Paging, @SurveyResultUtils) ->
    @project = @$scope.$parent.ctrl.project
    @responseOptions = @$scope.$parent.ctrl.surveysResponseOptions
    @reportLanguage = @$scope.$parent.ctrl.reportLanguage
    response = @$scope.$parent.ctrl.surveysResponse

    @paging = new @Paging(response)
    @all = new Array(@paging.getTotalPages())
    @_addSurveysToList(response.data)

  fetchStatisticsExcel: () =>
    @SurveyReportGeneratorService.fetchProjectSurveysExcel(@project.id, @$rootScope.currentPerson, { language: @_getReportLanguage() })

  getForPage: () =>
    @all[@paging.iterator()]

  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@all[@paging.iterator()])
      @_fetchSurveys({ page: page })

  _fetchSurveys: (options = {}) =>
    angular.extend options, @responseOptions
    @ProjectRepository.getSurveyProjectSurveys(@project.id, options).then (response) =>
      @paging = new @Paging(response)
      @_addSurveysToList(response.data)

  _addSurveysToList: (surveys) =>
    @_extendSurveys(surveys)
    @all[@paging.iterator()] = surveys

  _extendSurveys: (surveys) =>
    _.each surveys, (survey) =>
      survey.responderStatistics = @SurveyResultUtils.countSurveyResponders(survey)

      lastReminder = null
      _.each survey.responders, (responder) =>
        if !lastReminder || responder.reminder_sent_at && responder.reminder_sent_at > lastReminder
          lastReminder = responder.reminder_sent_at
      survey.lastReminder = lastReminder

  categoryText: (survey) ->
    @$translate.instant(survey.categoryTextKey(@$rootScope.currentPerson.currentCompany.id))

  isReportable: (survey) ->
    if survey.permissions.one('allowingToViewSurveyResults') && !survey.isDraft()
      survey.isEvaluation() || survey.isAppraisalInterview()
    else
      false

  fetchPdfReport: (survey) ->
    @SurveyReportGeneratorService.fetchReportPdf(survey.id, @$rootScope.currentPerson, { language: @_getReportLanguage() })

  # Report language can change in parent controller, so we can't simply store it as @reportLanguage.
  _getReportLanguage: () ->
    @$scope.$parent.ctrl.reportLanguage
