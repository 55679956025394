angular.module 'Upsteem'
  .service 'OldApp', () ->
    root: -> 'o/'
    peoplePersonalMessages: (person_id) ->
      "#{@root()}people/#{person_id}/personal_messages"

    structureMessages: (structure_type, structure_id) ->
      type = _.snakeCase structure_type
      "#{@root()}#{type}/#{structure_id}/entity_messages"

    search:  -> "#{@root()}search"
    signOut: -> "#{@root()}users/sign_out"

    profileLink:           (person_id) -> "#{@root()}persons/#{person_id}/profile/setting/edit"
    personalFiles:         (person_id) -> "#{@root()}persons/#{person_id}/documents"
    fileDownload:          (person_id, file_id) -> "#{@root()}persons/#{person_id}/documents/#{file_id}/download"
    onGoigHrmLink:        (company_id) -> "#{@root()}hrm/companies/#{company_id}/ongoing_surveys"
    permissionsPage:       (person_id) -> "#{@root()}persons/#{person_id}/profile/permissions"
    accountSettingsEditPage: (person_id) -> "#{@root()}persons/#{person_id}/profile/account/edit"
    personalDataEditPage:  (person_id) -> "#{@root()}persons/#{person_id}/profile/setting/edit"
    receivedFeedbackPath:  (survey_type, survey_id) -> "#{@root()}#{survey_type}/#{survey_id}"
    receivedFeedbacksLink: (person_id) -> "#{@root()}persons/#{person_id}/received_feedbacks"
    statisticsPath:        (company_id) -> "#{@root()}hrm/companies/#{company_id}/statistics"
    questionnairesPath:    (company_id) -> "#{@root()}hrm/companies/#{company_id}/evaluation_templates"   

    appraisalInterviewPath: (person_id, survey_id) ->
      "#{@root()}persons/#{person_id}/received_feedbacks?auto_open=appraisal_interview_#{survey_id}"

    startEvaluation: (structure_id, employment_id) ->
      "#{@root()}hrm/companies/#{structure_id}/employments/#{employment_id}/evaluations"

    startAppraisalInterview: (structure_id, employment_id) ->
      "#{@root()}hrm/companies/#{structure_id}/employments/#{employment_id}/appraisal_interviews"

    addStructure: (type, parent) ->
      "#{@root()}#{parent.type.tableize()}/#{parent.id}/#{type.tableize()}/new"

    editStructure: (structure) ->
      "#{@root()}#{structure.type.tableize()}/#{structure.id}/profile/setting/edit"

    editStructurePermissions: (structure) ->
      "#{@root()}#{structure.type.tableize()}/#{structure.id}/affiliatable_roles"

    importLink: (company_id) ->
      "#{@root()}companies/#{company_id}/import/type"

    editDraftLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/drafts/#{survey_id}/responders"

    editSurveyAppDraftLink: (company_id, survey_id) ->
      "#{@root()}surveys/companies/#{company_id}/surveys/drafts/#{survey_id}/responders"

    ongoingSurveyRespondersLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/ongoing_surveys/#{survey_id}/responders"

    ongoingSurveyRespondersSurveyAppLink: (company_id, survey_id) ->
      "#{@root()}surveys/companies/#{company_id}/surveys/ongoing_surveys/#{survey_id}/responders"

    ongoingSurveyParticipantsLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/ongoing_surveys/#{survey_id}/responders"

    ongoingSurveyAppParticipantsLink: (company_id, survey_id) ->
      "#{@root()}surveys/companies/#{company_id}/surveys/ongoing_surveys/#{survey_id}/responders"

    ongoingSurveyLink: (company_id, survey) ->
      if survey.isPublicSurvey()
        @ongoingSurveyRespondersSurveyAppLink(company_id, survey.id)
      else
        @ongoingSurveyRespondersLink(company_id, survey.id)

    closedSurveyParticipantsLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/closed_surveys/#{survey_id}/responders"

    closedSurveyAppParticipantsLink: (company_id, survey_id) ->
      "#{@root()}surveys/companies/#{company_id}/surveys/closed_surveys/#{survey_id}/responders"

    finishedSurveyPdfLink: (company_id, survey_id, individual_responder_id) ->
      "#{@root()}hrm/companies/#{company_id}/finished_surveys/#{survey_id}/result.pdf?responder=#{individual_responder_id}"

    finishedSurveyOverallPdfLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/finished_surveys/#{survey_id}/result.pdf"

    surveyOverallPdfLink: (company_id, survey_id, survey_state) ->
      "#{@root()}hrm/companies/#{company_id}/#{survey_state}_surveys/#{survey_id}/result.pdf"

    surveyOverallExcelLink: (company_id, survey_id, survey_state) ->
      "#{@root()}hrm/companies/#{company_id}/#{survey_state}_surveys/#{survey_id}/answers.xlsx"

    surveyResultLink: (company_id, survey_id, survey_state) ->
      "#{@root()}hrm/companies/#{company_id}/#{survey_state}_surveys/#{survey_id}/result"

    ongoingSurveyExcelLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/ongoing_surveys/#{survey_id}/answers.xlsx"

    closedSurveyExcelLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/closed_surveys/#{survey_id}/answers.xlsx"

    finishedSurveyExcelLink: (company_id, survey_id) ->
      "#{@root()}hrm/companies/#{company_id}/finished_surveys/#{survey_id}/answers.xlsx"

    editDraftSurveyLink: (company_id, survey) ->
      if survey.isPublicSurvey()
        @editSurveyAppDraftLink(company_id, survey.id)
      else
        @editDraftLink(company_id, survey.id)

    addRespondersLink: (company_id, survey) ->
      if survey.isPublicSurvey()
        if survey.state == 'closed'
          @closedSurveyAppParticipantsLink(company_id, survey.id)
        else
          @ongoingSurveyAppParticipantsLink(company_id, survey.id)
      else
        if survey.state == 'closed'
          @closedSurveyParticipantsLink(company_id, survey.id)
        else
          @ongoingSurveyParticipantsLink(company_id, survey.id)

    # TODO
    # Refactor methods above to use below ones when composing survey links
    responderLink: (company_id, survey) ->
      if survey.isPublicSurvey()
        @addSurveyAppLink(company_id, survey)
      else
        @addHrmLink(company_id, survey)

    addSurveyAppLink: (company_id, survey) ->
      "#{@root()}surveys/companies/#{company_id}/surveys/#{@statePath(survey)}/#{survey.id}/responders"

    addHrmLink: (company_id, survey) ->
      "#{@root()}hrm/companies/#{company_id}/#{@statePath(survey)}/#{survey.id}/responders"

    projectLink: (project_id) ->
      "#{@root()}projects/#{project_id}/entity_messages"

    newProjectLink: () ->
      "#{@root()}projects/new"

    statePath: (survey) ->
      if survey.isDraft()
        "drafts"
      else
        "#{survey.state}_surveys"

    newChildOrganizationLink: (parent_id) ->
      "#{@root()}companies/new?parent_id=#{parent_id}"
