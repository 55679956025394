class DefaultTemplateGroupRowCtrl extends Controller

  constructor: (@$scope, @toastr, @$state, @Constants, @$translate, @EmailTemplateGroupRepository, @EmailTemplateRepository) ->
    @editNameMode = false
    @originalName = undefined
    @emailListVisible = false
    @emailTemplates = []

  getEmailTemplates: () ->
    @_togglEmailListVisibility()

    return unless @emailListVisible

    @EmailTemplateRepository.getTemplatesForGroup(@$scope.group.id).then (templates) =>
      @emailTemplates = templates

  _togglEmailListVisibility: () ->
    @emailListVisible = !@editNameMode && !@emailListVisible

  showTemplate: (templateId) ->
    @$state.go('email_template', {id: templateId})
