class ConfirmPopup extends Directive
  constructor: (@$modal) ->
    return {
      restrict: 'A',
      scope: {
        confirmAction: '&'
        confirmText: '@'
        cancelText: '@'
        questionText: '@'
        titleText: '@'
      },
      link: (scope, element, attr) =>
        element.bind('click', () =>
          @$modal.open
            windowClass: 'small'
            templateUrl: 'directives/confirm_popup/confirmPopupModal.html'
            controller: 'ConfirmPopupModalCtrl'
            controllerAs: 'ctrl'
            resolve:
              questionText: () -> scope.questionText
              titleText: () -> scope.titleText
              confirmText: () -> scope.confirmText
              cancelText: () -> scope.cancelText
          .result.then () ->
            scope.confirmAction()
        )
    }
