class AddExternalResponderModalCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$translate, @toastr, @RespondersRepository) ->
    @formData = {}

  addExternalResponder: () =>
    @formData.group_name = @$scope.$parent.group
    @formData.token = @$scope.$parent.token
    @RespondersRepository.addExternalResponder(@formData).then((response) =>
      @$rootScope.$broadcast("external_added")
      @toastr.success @$translate.instant("survey.responder.invited")
    ).then((response) ->

    ).then(null, (err) =>
      @toastr.error @$translate.instant("survey.responder.error.duplicate")
    )
    @$scope.$parent.modalInstance.close()
