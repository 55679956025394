class ReceivedFeedbackCtrl extends Controller
  constructor: (
    @Survey,
    @DocumentRepository,
    @SurveyRepository,
    @SurveyReportGeneratorService,
    @Document,
    @Paging,
    TableOptions,
    @$scope,
    @$rootScope,
    @$stateParams,
    @toastr,
    @$translate,
    Constants
  ) ->
    @tableOptions = new TableOptions(translationScope: 'surveys')
    @opened = false
    @periods = _.values(Constants.personal_feedback_periods)
    @selectedPeriod = Constants.personal_feedback_periods.ALL
    @surveyTypes = _.values(Constants.received_feedback_survey_types)
    @selectedSurveyType = Constants.received_feedback_survey_types.ALL

  toggleSection: -> @opened = !@opened

  isSorainen: () ->
    @$rootScope.currentPerson.currentCompany.id == 1911

  load: =>
    if _.isUndefined(@all)
      @all = new Array(0)
      @fetchSurveys().then =>
        @toggleSection()
    else if @paging
      @toggleSection()

  addSurveysToList: (surveys) =>
    @all[@paging.iterator()] = _.map surveys, (survey) => new @Survey(survey)

  changePage: (page) =>
    @paging.changePage(page)
    if _.isUndefined(@all[@paging.iterator()])
      @fetchSurveys(page)

  fetchSurveys: (page) =>
    @$rootScope.isStateLoading = true
    @SurveyRepository.getReceivedFeedbacks({
      person_id: @$stateParams.id,
      page: page, period: @selectedPeriod,
      surveyType: @selectedSurveyType
    }).then (response) =>
      @_refreshData(response)
      @$rootScope.isStateLoading = false

  _refreshData: (response) =>
    newPaging = new @Paging(response)
    if !@paging || newPaging.getTotal() != @paging.getTotal()
      @all = new Array(newPaging.getTotalPages())
    @paging = newPaging
    @addSurveysToList(response.data)

  uploadFile: (file) =>
    try
      id = @$scope.$parent.ctrl.person.id
      result = @Document.upload(file, id)
      if(result != undefined)
        result.then (response) =>
          status = response.status
          if(status >= 200 && status < 300)
            this.fetchSurveys().then =>
              @toastr.success @$translate.instant("uploads.uploaded")
          else
            @$rootScope.isStateLoading = false
            @toastr.error @$translate.instant("uploads.upload_failed")
      else
        @toastr.error @$translate.instant("uploads.upload_failed")

    catch e
      for error in _.uniq(e, false, (err) ->
          err[0]
        )
        @toastr.error @$translate.instant(error[0], error[1])

  downloadFile: (subjectId, fileId) =>
    @Document.downloadSurvey(subjectId, fileId)

  deleteFile: (file) =>
    try
      @$rootScope.isStateLoading = true
      result = @DocumentRepository.delete(file)
      if(result != undefined)
        result.then (response) =>
          status = response.status
          if(status >= 200 && status < 300)
            this.fetchSurveys().then =>
              @toastr.success @$translate.instant("general.file_deleted")
          else
            @$rootScope.isStateLoading = false
            @toastr.error @$translate.instant("general.delete_failed")
      else
        @toastr.error @$translate.instant("general.delete_failed")

    catch e
      for error in _.uniq(e, false, (err) ->
          err[0]
        )
        @toastr.error @$translate.instant(error[0], error[1])


  filterChanged: () ->
    @fetchSurveys()

  fetchPdf: (surveyId) ->
    @SurveyReportGeneratorService.fetchReportPdf(surveyId, @$rootScope.currentPerson)

  answeringLink: (survey) =>
    survey.answeringLink(@$rootScope.config.answering_app_host)
