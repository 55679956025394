angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'my_projects',
        parent: 'protected'
        url: "/my_projects"
        template: "<ui-view/>"
        views:
          '@':
            controller: "MyProjectsListCtrl"
            controllerAs: "ctrl"
            templateUrl: "projects/list/myProjectsList.html"

class MyProjectsListCtrl extends Controller
  constructor: (TableOptions, @Paging, @$rootScope, @ProjectRepository, @Project) ->
    @tableOptions = new TableOptions(translationScope: 'projects', sortAction: @sort);
    @projects = []
    @_getProjects()

  _getProjects: (page, options = {}) ->
    angular.extend(options, {page: page})
    @ProjectRepository.getForPerson(@$rootScope.currentPerson.id, options).then (response) =>
      @paging = new @Paging(response)
      @projects[@paging.iterator()] = _.map(response.data, (project) => new @Project(project))

  changePage: (page, options = {}) =>
    @paging.changePage(page)
    @_getProjects(page, options)

  sort: (sortOptions = {}) =>
    @changePage(1, sortOptions) if @paging
