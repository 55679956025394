angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'email_template_groups',
        parent: 'protected'
        url: '/emails/template_groups'
        template: "<ui-view/>"
        resolve:
          templateGroups: (EmailTemplateGroupRepository) ->
            EmailTemplateGroupRepository.getAll()
          deliveryConfiguration: (EmailDeliveryConfigurationRepository) ->
            EmailDeliveryConfigurationRepository.get()
          layouts: (EmailLayoutRepository) ->
            EmailLayoutRepository.getAll()
        views:
          '@':
            controller: "TemplateGroupListCtrl"
            controllerAs: "ctrl"
            templateUrl: "emails/template_groups/templateGroupList.html"

class TemplateGroupListCtrl extends Controller

  constructor: (templateGroups, @deliveryConfiguration, layouts, @toastr, @$translate, @EmailTemplateGroupRepository, @EmailDeliveryConfigurationRepository, @EmailTemplateRepository, @EmailTemplateLocaleRepository) ->
    @_groupTemplateGroups(templateGroups)
    @_groupLayouts(layouts)

  defaultName: (group) ->
    @$translate.instant(group.defaultNameKey)

  copyLayout: (layout) ->
    @EmailTemplateGroupRepository.createCopy(layout.id, 'Layout').then (newLayout) =>
      @customLayouts.push(newLayout)
      @toastr.success(@$translate.instant("emails.template_groups.copy_created", {template: layout.name}))

  copyDefault: (group) ->
    @copy(group, @defaultName(group))

  copyCustom: (group) ->
    @copy(group, group.name)

  copy: (group, name = "") ->
    @EmailTemplateGroupRepository.createCopy(group.id, name + " (copy)").then (newGroup) =>
      @customGroups.push(newGroup)
      @toastr.success(@$translate.instant("emails.template_groups.copy_created", {template: name}))

  delete: (index) ->
    updateParams = {active: false}
    customGroup = @customGroups[index]

    @EmailTemplateGroupRepository.update(customGroup.id, updateParams).then (templateGroup) =>
      @toastr.success(@$translate.instant("emails.template_groups.removed"))
      @customGroups.splice(index, 1)

  updateDeliveryConfiguration: () ->
    @EmailDeliveryConfigurationRepository.update(@deliveryConfiguration).then (response) =>
      @toastr.success(@$translate.instant("emails.delivery_configurations.updated"))

  updateLayout: () ->
    update = {html_content: @layout.html_content, title: @layout.title}
    @EmailTemplateLocaleRepository.updateOne(@layout.id, update).then (response) =>
      @toastr.success(@$translate.instant("emails.layouts.updated"))

  _groupTemplateGroups: (templateGroups) ->
    grouped = _.groupBy(templateGroups, (group) -> group.systemWide())
    @defaultGroups = grouped['true']
    @customGroups = grouped['false'] || []

  _groupLayouts: (layouts) ->
    grouped = _.groupBy(layouts, (group) -> group.systemWide())
    @defaultLayouts = grouped['true']
    @customLayouts = grouped['false'] || []
