class DirectReportsCtrl extends Controller
  constructor: (@$stateParams, response, @TableOptions, @Paging, @Employment, @Structure, @currentPerson, @DirectReportsService, @$rootScope, @StructureRepository) ->
    @options       = {}
    @tableOptions  = new @TableOptions(translationScope: 'employment')
    @paging        = new @Paging(response)
    @directReports = new Array(@paging.getTotalPages())
    @directReports[@paging.iterator()] = @mapEmployments(response.data)
    @structutures = []
    @_getStructures()

  mapEmployments: (employments) =>
    _.map employments, (employment) => new @Employment(employment)

  getFilteredDirectReports: ->
    @getDirectReports(@options)

  changePage: (page) =>
    @paging.changePage(page)
    if _.isUndefined @directReports[@paging.iterator]
      options         = @options
      options['page'] = page

      @getDirectReports(options)

  getDirectReports: (options) =>
    @DirectReportsService.getList(@$stateParams.id, options).then (response) =>
      @paging = new @Paging(response)
      @directReports[@paging.iterator()] = @mapEmployments(response.data)

  _getStructures: () =>
    @StructureRepository.getManagedStructuresWithDescendants(@$stateParams.id, @$rootScope.currentPerson.currentCompany.id).then (response) =>
      @structures = _.map(response.data, (structure) => new @Structure(structure))
