class SampleGantt extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      templateUrl: 'directives/sampleGantt.html'
      controller: 'SampleGanttCtrl',
      controllerAs: 'ganttCtrl'
    }

class SampleGanttCtrl extends Controller
  constructor: (@$modal) ->

  openChartModal: ->
     @$modal.open
       templateUrl: 'directives/sampleGanttModal.html'
       controller: "SampleGanttCtrl"
       controllerAs: 'ganttCtrl'
