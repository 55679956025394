class CustomLayoutRowCtrl extends Controller

  constructor: (@$scope, @toastr, @$state, @Constants, @$translate, @EmailTemplateGroupRepository, @EmailTemplateRepository) ->
    @editNameMode = false
    @originalName = undefined
    @emailListVisible = false
    @emailTemplates = []

  editName: () ->
    @editNameMode = true
    @originalName = @$scope.layout.name

  updateName: () ->
    return unless @editNameMode

    @editNameMode = false

    updateParams = {name: @$scope.layout.name}
    @EmailTemplateGroupRepository.update(@$scope.layout.id, updateParams).then (templateGroup) =>
      @toastr.success(@$translate.instant("emails.template_groups.updated"))

  updateOrRevertName: (event) ->
    if event.keyCode == @Constants.keycodes.ENTER
      @updateName()
    else if event.keyCode == @Constants.keycodes.ESCAPE
      @_revertName()

  showLayout: () ->
    @$state.go('email_template', {id: @$scope.layout.email_template_id})

  editTemplate: (templateId) ->
    @$state.go('email_template', {id: templateId})

  _revertName: () ->
    return unless @editNameMode

    @$scope.layout.name = @originalName
    @editNameMode = false

  _togglEmailListVisibility: () ->
    @emailListVisible = !@editNameMode && !@emailListVisible
