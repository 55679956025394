angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey',
        parent: 'protected'
        url: '/survey/{id: int}'
        template: '<ui-view/>'
        params:
          showResponders: null
        resolve:
          survey: ($stateParams, currentPerson, SurveyRepository, Structure) ->
            SurveyRepository.get($stateParams.id).then (survey) ->
              if $stateParams.showResponders == null
                $stateParams.showResponders = survey.isDraft()
              survey.decorateEntityWithPage(Structure, currentPerson)
          templates: (SurveyRepository, survey) ->
            if survey.category == "evaluation"
              SurveyRepository.getEvaluationTemplates()
            else if survey.category == "appraisal_interview"
              SurveyRepository.getAppraisalInterviewTemplates()
          surveyResult: (SurveyReportGeneratorService, survey) ->
            if survey.permissions.one('allowingToViewSurveyResults')
              SurveyReportGeneratorService.fetchReportResult(survey.id)
            else
              false
        views:
          '@':
            controller: 'ShowSurveyCtrl'
            controllerAs: 'ctrl'
            templateUrl: 'survey/showSurvey.html'

      .state 'survey.responders',
        showLoader: false,
        templateUrl: 'survey/respondersTab.html'

      .state 'survey.results',
        showLoader: false,
        templateUrl: 'survey/resultsTab.html'

class ShowSurveyCtrl extends Controller
  RESPONDERS_TAB: 0
  RESULTS_TAB: 1
  constructor: (
    @$state,
    @$stateParams,
    @$scope,
    @$rootScope,
    @$translate,
    @$modal,
    @$filter,
    @survey,
    @templates,
    @surveyResult,
    @toastr,
    @SurveyRepository,
    @SurveyReportGeneratorService,
    @Survey
  ) ->
    @currentLanguage = @$rootScope.currentPerson.profile_language
    @editDeadline = false
    @editTemplate = false
    @showDefaultTab()
    @initializeTooltips()

  showDefaultTab: ->
    if @surveyResult && not @$stateParams.showResponders
      @showResults()
    else
      @showResponders()

  showResponders: ->
    @activeTab = @RESPONDERS_TAB
    @$state.go 'survey.responders'

  showResults: ->
    @activeTab = @RESULTS_TAB
    @$state.go 'survey.results'

  initializeTooltips: ->
    @$translate.use(@currentLanguage)
    @$scope.excel = {
      "title": @$translate.instant('surveys.download_excel'),
      "checked": false
    }

    @$scope.pdf = {
      "title": @$translate.instant('surveys.download_pdf'),
      "checked": false
    }

    @$scope.send_survey = {
      "title": @$translate.instant('survey.send_out'),
      "checked": false
    }

    @$scope.open_survey = {
      "title": @$translate.instant('surveys.open_surveys')
      "checked": false
    }

    @$scope.close_survey = {
      "title": @$translate.instant('surveys.close_survey'),
      "checked": false
    }

    @$scope.open_survey_for_editing = {
      "title": @$translate.instant('surveys.open_surveys_for_editing')
      "checked": false
    }

    @$scope.finish_survey = {
      "title": @$translate.instant('surveys.finish_survey'),
      "checked": false
    }

    @$scope.delete_survey = {
      "title": @$translate.instant('surveys.delete_survey'),
      "checked": false
    }

  isReportFetchingAllowed: ->
    !!@surveyResult

  fetchPdf: ->
    if @isReportFetchingAllowed()
      @SurveyReportGeneratorService.fetchReportPdf(@surveyResult.info.id, @$rootScope.currentPerson)

  getSurveyCompanyName: ->
    if @surveyResult
      @surveyResult.info.company
    else
      @$rootScope.currentPerson.currentCompany.name

  specialCompany: ->
    surveyCompany = @getSurveyCompanyName()
    surveyCompany == "SORAINEN"

  isEditable: ->
    true

  toggleDeadlineEdit: () ->
    @editDeadline = !@editDeadline

  updateDeadline: () ->
    if @survey.deadline_at
      @SurveyRepository.setDeadline(@survey, @survey.deadline_at).then((response) =>
        @toggleDeadlineEdit()
        @survey = @survey.copyWithUnchangedEntity(response.data)
        @toastr.success @$translate.instant('survey.overview.deadline_edited')
      )
    else
      @toggleDeadlineEdit()

  toggleTemplateEdit: () ->
    @editTemplate = !@editTemplate

  updateTemplate: () ->
    if @survey.template && @survey.template.id
      @SurveyRepository.setTemplate(@survey, @survey.template.id).then((response) =>
        @toggleTemplateEdit()
        @survey = @survey.copyWithUnchangedEntity(response.data)
        @toastr.success @$translate.instant('survey.template_edited')
        @$state.go('survey', { id: @survey.id }, { reload: true })
      )
    else
      @toggleTemplateEdit()

  openSendOutModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'survey/transitions/surveySendOutModal.html'
      controller: 'SurveySendOutCtrl'
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  openCloseSurveyModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'survey/transitions/surveyCloseModal.html'
      controller: 'SurveyCloseModalCtrl'
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  openReOpenSurveyModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'survey/transitions/surveyReOpenModal.html'
      controller: 'SurveyReOpenModalCtrl'
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  openFinishSurveyModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'survey/transitions/surveyFinishModal.html'
      controller: 'SurveyFinishModalCtrl'
      controllerAs: 'ctrl'
      resolve:
        survey: => @survey
        roles: => @SurveyRepository.massGetNotifiableRolesForFinishing(@survey.id)
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  isResultUnificationNecessary: () ->
    @survey.result_unification == "necessary"

  openResultUnificationModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'survey/transitions/surveyResultUnificationModal.html'
      controller: 'SurveyResultUnificationModalCtrl'
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined

  openDeleteSurveyModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'survey/transitions/surveyDeleteModal.html'
      controller: 'SurveyDeleteModalCtrl'
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> $scope.modalInstance = undefined
