class VacancyEditConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'vacancy.edit',
        url: '/edit'
        views:
          '@':
            templateUrl: 'vacancy/edit/vacancyEdit.html'
            controller: "VacancyEditRouteCtrl"
            controllerAs: 'routeCtrl'
            resolve:
              employment: (vacancy) ->
                vacancy.employment if vacancy
              person: (employment) ->
                employment.person if employment
              allJobFamilies: (currentPerson, ProfessionalGroup) ->
                ProfessionalGroup.allForCompany(currentPerson.currentCompany.id)