angular.module 'Upsteem.Models', []
angular.module "phrase", ['pascalprecht.translate', 'ng']

dependencies = [
  'pascalprecht.translate',
  'ui.router',
  'restangular',
  'mm.foundation',
  'Upsteem.Models',
  'ngSanitize',
  'angularMoment',
  'ngCookies',
  'toastr',
  'ui.select',
  'ui.bootstrap',
  'phrase',
  'checklist-model',
  'rzSlider',
  'mgcrea.ngStrap.helpers.dateParser',
  'mgcrea.ngStrap.tooltip',
  'mgcrea.ngStrap.datepicker',
  'validation.match',
  'ngCsv',
  'checklist-model',
  'highcharts-ng',
  'daterangepicker',
  'ngFileUpload',
  'angular.filter',
]
angular.module 'Upsteem', dependencies, ->

angular.module 'Upsteem'
  .config ($locationProvider) ->
    $locationProvider.html5Mode(true)

  .config ($datepickerProvider) ->
    angular.extend $datepickerProvider.defaults,
      startWeek: 1


  .factory 'errorInterceptors', ($q, $rootScope, $cookies, toastr, $injector) ->
    responseError: (response) ->
      $translate = $injector.get '$translate'
      $state     = $injector.get '$state'

      if response.status == 401
        $rootScope.currentPerson = undefined
        url = 'login?url=' + encodeURIComponent(window.location.pathname + window.location.search)
        window.location = url

      if response.status == 400
        if response.data.error_code
          toastr.error($translate.instant("errors.#{response.data.error_code}"))
        if response.data.errors
          _.each response.data.errors, (error) =>
            toastr.error($translate.instant("errors.#{error}"))

      if response.status == 404
        toastr.error(response.statusText)
        # $state.go 'root'

      if response.status == 422
        if response.data.errors
          data = _.map response.data.errors, (error) => $translate("errors.#{error}")

          $q.all(data).then (messages) => toastr.error(messages.join(', '))

      if response.status == 502
        toastr.error(response.statusText)

      if response.status == 550
        toastr.error('Permission Denied')
        # $state.go 'root'

      $q.reject(response)

  .factory '$exceptionHandler', ($window, $injector) ->
    (exception, cause) ->
      # Avoid unnecessary spamming:
      if exception.stack.search("TooltipFactory") #https://github.com/mgcrea/angular-strap/issues/826
        return
      if exception.stack.search("element[0].nodeName") # Connected to the tooltip headache
        return

      rootScope = $injector.get('$rootScope')
      config = rootScope && rootScope.config
      exceptionHandlerConfig = config && config.frontend_exception_handler

      if exceptionHandlerConfig && exceptionHandlerConfig.project_id && exceptionHandlerConfig.project_key
        airbrake = new Airbrake.Notifier(
          projectId: exceptionHandlerConfig.project_id
          projectKey: exceptionHandlerConfig.project_key
        )
        airbrake.addFilter (notice) ->
          notice.context.environment = $window.location.hostname
          notice

        sessionData =
          if rootScope.currentPerson
            rootScope.currentPerson.plain()
          else
            {}
        airbrake.notify
          error: exception
          session: sessionData
          params: angular_cause: cause
        return
      else
        console.log exception
        console.log exception.stack

  .config ['$httpProvider', ($httpProvider) ->
    $httpProvider.interceptors.push('errorInterceptors')
  ]

  .config ($httpProvider) ->
    $httpProvider.defaults.withCredentials = true
    $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate'
    $httpProvider.defaults.headers.common['Pragma'] = 'no-cache'
    $httpProvider.defaults.headers.common['Expires'] = '-1'

  .config (RestangularProvider) ->
    RestangularProvider.setFullResponse(true)
    RestangularProvider
      .setBaseUrl 'api/v2'
      .setRequestSuffix('.json')
      .setDefaultHeaders({'Content-Type': 'application/json; charset=UTF-8'})
      .addResponseInterceptor((data, operation) ->
        if operation == 'getList'
          response             = if data.data then data.data else data
          response.permissions = data.permissions if data.permissions
          response
        else data
      )

  .config ($translateProvider) ->
    $translateProvider
      .useSanitizeValueStrategy 'escapeParameters' # Escapes HTML in the values of the interpolation parameters
      .useLoader 'TranslationLoader'
      .fallbackLanguage 'en'
      .use 'en'

  .config (toastrConfig) ->
    angular.extend toastrConfig,
      allowHtml: false,
      autoDismiss: false,
      closeButton: false,
      # closeHtml: '<button>&times;</button>',
      containerId: 'toast-container',
      extendedTimeOut: 0,
      iconClasses:
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning'
      maxOpened: 1,
      messageClass: 'toast-message',
      newestOnTop: true,
      # onHidden: null,
      # onShown: null,
      positionClass: 'toast-top-center',
      preventDuplicates: false,
      preventOpenDuplicates: false,
      progressBar: false,
      tapToDismiss: true,
      target: 'body',
      # templates:
      #   toast: 'directives/toast/toast.html',
      #   progressbar: 'directives/progressbar/progressbar.html'
      timeOut: 2000,
      titleClass: 'toast-title',
      toastClass: 'title'

  .run ($rootScope, $window, $state, $timeout) ->
    # Prevent prototype pollution attacks (https://security.snyk.io/vuln/SNYK-JS-ANGULAR-534884):
    Object.freeze Object.prototype

    $rootScope.$on '$stateNotFound', (event, unfoundState, fromState, fromParams) ->
      console.log "STATE NOT FOUND: " + event, unfoundState, fromState, fromParams
    $rootScope.$on '$stateChangeError', (event, toState, toParams, fromState, fromParams, error) ->
      console.log "STATE CHANGE ERROR: " + event, toState, toParams, fromState, fromParams, error

      if toState.name == 'user.confirmation'
        $state.go 'user.confirmation_resend'

      if toState.name =='user.password_reset'
        $state.go 'login'

    $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) ->
      if(toState.showLoader == undefined || toState.showLoader == true)
        $rootScope.isStateLoading = true

    $rootScope.$on '$stateChangeSuccess', (ev, to, toParams, from, fromParams) ->
      $rootScope.previousState = from.name
      $rootScope.currentState = to.name
      $rootScope.fromParams = fromParams
      $rootScope.isStateLoading = false

      # this is necessary for left-to-right scrolling.
      # Otherwise sidebar goes wider on the bottom of page when scrolling on a page that has less content
      angular.element(document.querySelector('.white-area')).css( 'padding-bottom': '0px' )
      $timeout ( =>
        windowHeight = window.innerHeight
        mainHeight   = angular.element(document.querySelector('ui-view.main-content')).prop('offsetHeight')
        sidebarBG    = angular.element(document.querySelector('.sidebar-nav-bg')).prop('offsetHeight')
        if (windowHeight - sidebarBG) > 10
          angular.element(document.querySelector('.white-area')).css( 'padding-bottom': windowHeight - mainHeight + "px" )
      ), 1500

    $rootScope.moveBack = -> $window.history.back()

class UiRouterConfig extends Config
  constructor: ($urlMatcherFactoryProvider) ->
    $urlMatcherFactoryProvider.strictMode(false)
