class EmailTemplate extends Service
  constructor: (Base, EmailTemplateLocale) -> return class EmailTemplate extends Base
    constructor: (json) ->
      angular.extend(@, json)
      @params = _.map(@params, (param) => "{{#{param}}}")
      @nameTranslationKey = 'emails.templates.' + @template_type
      @emailTemplateLocales = {}

      unless @email_template_locales == undefined
        @_groupTemplateLocalesByLocale()

    getTemplateLocaleByLocale: (locale) ->
      @emailTemplateLocales[locale]

    _groupTemplateLocalesByLocale: () ->
      templateLocales = _.map(@email_template_locales, (locale) => new EmailTemplateLocale(locale))
      groupedLocales = _.groupBy(templateLocales, (templateLocale) -> templateLocale.locale)

      _.each(groupedLocales, (value, key) => @emailTemplateLocales[key] = value[0])
