class GroupAveragesChart extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        translations: '='
      },
      templateUrl: 'directives/survey_result/group_averages_chart.html'
      controller: 'GroupAveragesChartCtrl',
      controllerAs: 'ctrl'
      # replace: true
    }

class GroupAveragesChartCtrl extends Controller
  constructor: (@$scope, @$rootScope, @$filter, @SurveyTranslatorService) ->
    @data = @$scope.data
    @translator = @SurveyTranslatorService
    @translations = @$scope.translations || {roles: {}}
    @group = @data.chart_id

    @_allDataPoints = [
      'manager',
      'subordinate',
      'peer',
      'colleague',
      'customer_or_partner',
      'others',
      'self',
    ]
    @_dataPoints = @_allDataPoints.filter(
      (point) => _.isObject(@data.records[point])
    , @)
    @_colors = {
      others: '#FF479B',
      self: '#79838A',
      manager: '#FE6C3B',
      peer: '#39B049',
      customer_or_partner: '#9C1500',
      subordinate: '#7cb5ec',
      overall: '#9E2C60',
    }

    @chartConfig = {
      chart: {
        width: 450,
        height: 300,
        style: {
          overflow: 'visible'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: @translator.translate('surveys.results')
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              text: 'Download image'
            }]
          }
        },
      },
      lang: {
        noData: 'No data to display'
      },
      xAxis: [
        {
          categories: _.map(@_dataPoints,
           (point) => @_nameForPoint(point)
          , @),
          labels: {
            enabled: true
          },
          minorTickLength: 0,
          tickLength: 0
        },
        {
          opposite: true,
          labels: { enabled: false },
          minorTickLength: 0,
          tickLength: 0
        }
      ],
      plotOptions: {
        columnrange: {
          grouping: false
        }
      },
      yAxis: {
        tickInterval: 1,
        title: {
          text: 0
        },
        # Average - just line, coming from data.overall
        plotLines: [{
          color: '#9E2C60',
          value: @data.overall,
          width: 1,
          label: {
            text: @translator.translate('surveys.overview.rating_overall'),
            align: 'right',
            x: 10
          },
          zIndex: 5
        }],
        min: @_yAxisMin(),
        max: @_yAxisMax(),
        startOnTick: false,
        endOnTick: false
      },
      series: [
       {
          type: 'line',
          name: @translator.translate('general.average'),
          color: '#696969',
          data: _.zip(@_namedDataPoints(), _.map(@_dataPoints, (point) =>
            if @data.records[point]
              _.round(@data.records[point].avg, 2)
            else
              null
          , @)),
          zIndex: 5
        },
        {
          type: 'columnrange',
          legend: false,
          data: _.map(@_dataPoints, (point, index) =>
            @_toColumnRangeDataItem(point, index, @data)
          , @)
          name: @translator.translate('surveys.overview.range'),
          color: '#000'
        },
      ] # series
    }

    @colors = Highcharts.getOptions().colors

    @dataSeries = {}

  _toColumnRangeDataItem: (point, index, data) ->
    item = (@data.records[point] || {})
    color = @_colorForPoint(point)
    name = @_nameForPoint(point)
    {
      x: index,
      low: item.min,
      high: item.max,
      color,
      name,
      tooltip: {
        headerFormat: '',
        pointFormat: @_pointFormat(name, color, item.min, item.max)
      },
      label: {
        text: '',
        align: 'center',
        x: 0
      },
    }

  _colorForPoint: (point) ->
    @_colors[point]

  _nameForPoint: (point) ->
    @translator.translatedRole(@translations, point)

  _yAxisMin: () ->
    0.01

  _yAxisMax: () ->
    if @data.scale_size
      @data.scale_size + 0.99
    else
      null # calculate automatically

  _namedDataPoints: () ->
    _.map(@_dataPoints, (point) =>
      @_nameForPoint(point)
    , @)

  _pointFormat: (name, color, min, max) ->
    '<span style="color: {color}">{name}: </span><span>{min} - {max}</span>'
