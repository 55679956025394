class LoginCtrl extends Controller
  constructor: (@$location, @toastr, @Person, @authOptions, @Restangular) ->
    @redirectUrl = @$location.$$search['url'] || '/dashboard'
    @form = {}

  login: (form) =>
    if form.$valid
      @Person.login(@form).then =>
        window.location = @redirectUrl

  loginWithOption: (name) ->
    @Restangular.one('auth', name).one('authorization_request').get().then (response) ->
      window.location.replace(response.data)

class ResetPasswordCtrl extends Controller
  constructor: (@$location, @toastr, @Person, @$state, @$translate) ->
    @redirectUrl = @$location.$$search['url'] or '/dashboard'
    @passwordResetForm = {}

  resetPassword: (form) =>
    if form.$valid
      @Person.initiatePasswordReset(@passwordResetForm).then =>
        @toastr.success @$translate.instant 'password.reset.instructions'
        @$state.go('login')


class LoginConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'login',
        parent: 'unprotected'
        url: '/login'
        views:
          'unprotected@':
            templateUrl: "templates/login/_login.html"
            controller: "LoginCtrl"
            controllerAs: 'ctrl'
        resolve:
          checkAuth: (Person, $state) ->
            Person.checkSession().then (data) ->
              $state.go 'personalDash' if data.session
          authOptions: (checkAuth, Restangular) ->
            Restangular.all('auth').get('options').then (response) ->
              options = {}
              _.each response.data, (name) ->
                options[name] = true
              options

      .state 'reset_password',
        parent: 'unprotected'
        url: '/reset_password'
        views:
          'unprotected@':
            templateUrl: "templates/login/_reset_password.html"
            controller: "ResetPasswordCtrl"
            controllerAs: 'ctrl'

      .state 'auth',
        url: '/users/auth/:optionName/callback'
        resolve:
          settings: (Restangular, $stateParams) ->
            Restangular.one('auth', $stateParams.optionName).one('authorization_callback', 'settings').get().then (response) ->
              response.data
          dataForParseAndSend: ($location, settings) ->
            if settings.disposition == 'hash'
              Object.fromEntries(new URLSearchParams($location.hash()))
            else
              $location.search()
          parseAndSend: (dataForParseAndSend, Restangular, $state, $stateParams, settings) ->
            Restangular.one('auth', $stateParams.optionName).one('authorization_callback', settings.type).post('', dataForParseAndSend).then((data) ->
              $state.go 'root'
            ).catch (response) ->
              $state.go 'login'
