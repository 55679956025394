class AnswerMassUploadDeletionController extends Controller
  constructor: (@$scope, @$state, @SurveyRepository, @toastr, @$translate, @survey, @selectionRepository, @answerMassUpload, @deleteAnswerMassUpload) ->

  confirmUploadedAnswersDeletion: () ->
    @_closeModal()
    @deleteAnswerMassUpload()

  cancelUploadedAnswersDeletion: () ->
    @_closeModal()

  _closeModal: () ->
    @$scope.modalInstance.close()
