class RemarkRepository extends Service

  constructor: (@Restangular, @Remark, @Paging) ->
    @options = {}

  create: (remark) ->
    @Restangular.all('remarks').post(remark).then (response) ->
      response

  getAll: (options = {}) ->
    @Restangular.all('remarks').getList(options)

  getAllForPerson: (person, options = {}) ->
    @Restangular.one('people', person).all('remarks').getList(options)

  getWithFilter: (options) ->
    @Restangular.all('remarks').getList(options).then (response) ->
      response

  initialize: (paging) ->
    if @remarks == undefined
      @remarks = []
      @fetchRemarksForPage(paging)

  addRemarksToList: (remarks, paging) =>
    @remarks[paging.iterator(paging.page)] = @Remark.mapRemarks(remarks)

  getForPage: (paging) ->
    @remarks[paging.iterator()]

  fetchRemarksForPage: (paging, forceDownload=false) ->
    if _.isUndefined(@remarks[paging.iterator()]) || forceDownload
      @options.page = paging.page
      @fetchRemarks()

  fetchRemarks: () =>
    @getAll(@options).then (response) =>
      paging = new @Paging(response)
      @addRemarksToList(response.data, paging)
      paging
