class Objective extends Service
  constructor: (Base, Restangular, Permissions, Constants, Employment, $state) -> return class Objective extends Base
    constructor: (json) ->
      angular.extend @, json

      @ownerEmployment   = new Employment(@owner_employment)   if @owner_employment
      @deciderEmployment = new Employment(@decider_employment) if @decider_employment
      @permissions       = new Permissions(@permissions)

      @people = {}
      @kpis = {}
      @goals = []

      @structure = {}

      @months = []
      @setMonths()

    setMonths: () =>
      return unless @start_date && @end_date
      @months = moment.range(moment(@start_date).startOf('month'), moment(@end_date).startOf('month')).toArray('month')

      months = _.map @months, (month) -> month.format('YYYY-MM-DD')
      _.each @goals, ((goal) -> @setMonthsForGoal(months, goal)), @
      @updateStructure()

    setMonthsForGoal: (months, goal) =>
      toDelete = _.difference(_.keys(goal.results), months)
      _.each toDelete, (month) -> delete(goal.results[month])
      _.each months, (month) ->
        if goal.results
          goal.results[month] ?= {target: null, result: null, date: month}

    addPerson: (person) =>
      return if @people[person.id]
      @people[person.id] = person
      @addGoalsForPerson(person)

    removePeopleByIds: (ids) =>
      _.each ids, ((id) ->
        delete(@people[id])
        while true
          i = _.findIndex(@goals, {'person_id': id})
          break if i < 0
          @goals.splice(i, 1)
      ), @

    removeKpisByIds: (ids) =>
      _.each ids, ((id) ->
        delete(@kpis[id])
        while true
          i = _.findIndex(@goals, {'kpi_id': id})
          break if i < 0
          @goals.splice(i, 1)
      ), @

    addGoalsForPerson: (person) =>
      _.each @kpis, ((kpi) -> @buildGoal(kpi, person)), @

    addGoalsForKpi: (kpi) =>
      _.each @people, ((person) -> @buildGoal(kpi, person)), @

    addKpi: (kpi) =>
      return if @kpis[kpi.id]
      @kpis[kpi.id] = kpi
      @addGoalsForKpi(kpi)

    updatePeople: (people) =>
      toDelete = _.difference(_.pluck(@people, 'id'), _.pluck(people, 'id'))
      @removePeopleByIds(toDelete)
      _.each people, ((person) -> @addPerson(person)), @
      @updateStructure()

    updateKpis: (kpis) =>
      toDelete = _.difference(_.pluck(@kpis, 'id'), _.pluck(kpis, 'id'))
      @removeKpisByIds(toDelete)
      _.each kpis, ((kpi) -> @addKpi(kpi)), @
      @updateStructure()

    buildGoal: (kpi, person) =>
      if typeof _.findWhere(@goals, {'person_id': person.id, 'kpi_id': kpi.id}) == "undefined"
        newGoal = {
          kpi_id: kpi.id, person_id: person.id, type: kpi.grading_type, weight: kpi.weight,
          completion_comment: '', rating: 0, description: '', target: 0, title: ''
        }
        if @kpiHasPeriods(kpi)
          newGoal.results = _.map @months, (month) ->
            { date: month.format('YYYY-MM-DD'), target: null, result: null }
        @goals.push(newGoal)

    kpiHasPeriods: (kpi) =>
      _.last(kpi.grading_type.split('.')).toUpperCase() == 'PERIOD'

    toRestangular: () =>
      start_date: @start_date,
      end_date: @end_date,
      title: @title,
      description: @description,
      owner_employment_id: @owner_employment_id,
      person_ids: (_.pluck @people, 'id'),
      kpi_ids: (_.pluck @kpis, 'id'),
      goals: _.map(@goals, (goal) -> {
        id: goal.id
        title: goal.title
        kpi_id: goal.kpi_id,
        person_id: goal.person_id
        weight: goal.weight
        completion_comment: goal.completion_comment
        rating: goal.rating
        description: goal.description
        target: goal.target
        results: _.map goal.results, (result) ->
          { date: moment(result.date).format('YYYY-MM-DD'), target: result.target, result: result.result }
      })

    addExistingGoal: (goal) ->
      unless _.findWhere(@goals, {id: goal.id})
        @goals.push(
          {
            id: goal.id
            kpi_id: goal.kpi.id
            person_id: goal.owner_employment_id
            title: goal.title
            weight: goal.weight
            completion_comment: goal.completion_comment
            rating: goal.rating
            description: goal.description
            target: goal.target
            results: _.map goal.results, (result) ->
              { date: result.start_date, target: parseFloat(result.target), result: parseFloat(result.result) }
          }
        )

    updateStructure: () =>
      groupedGoals = _.groupBy(@goals, (goal) -> goal.kpi_id)
      people = @people
      @structure = _.reduce(@kpis, ((structure, kpi) ->
        goals = groupedGoals[kpi.id]

        _calculateSum = (data) ->
          sum = _.sum(data)
          unless kpi.algorithm == 'AVERAGE'
            return sum
          if sum > 0
            return sum / _.size _.compact data
          else
            return 0

        _resultsFunc = _.memoize () ->
          _results = {target: [], results: []}
          _.each _.map(goals, (goal) -> goal.results), (results) ->
            _.each results, (data) ->
              data.target
              _results['targets'].push data.target
              _results['results'].push data.result
          _results

        _dates = _.memoize () ->
          dates = {}
          _.each _.map(goals, (goal) -> goal.results), (results) ->
            _.each results, (data) ->
              dates[data.date] ?= []
              dates[data.date].push(data)
          _.sortBy(_.map(dates, (data, date) -> {date: date, data: data}), 'date')

        _resultsTotal = (results, target) ->
          data = _.map results, (result) ->
            if target == 'result' then result.result else result.target
          _calculateSum(data)

        _summaryTotal = (target) ->
          subset = if target == 'result' then 'result' else 'target'
          data = _.map(_dates(), (date) -> _resultsTotal(date.data, subset))
          _calculateSum(data)

        structure[kpi.id] = {
          kpi: kpi
          partial: _.last(kpi.grading_type.split('.')).toUpperCase()
          goals: goals
          personName: (goal) ->
            if goal.person_id && people[goal.person_id]
              people[goal.person_id].name
          algorithm: ->
            "kpis.#{kpi.algorithm}"

          resultsTotal: _resultsTotal
          dates: _dates
          results: _resultsFunc
          summaryTotal: _summaryTotal
        }
        structure
      ), {})

    @fromRestangular: (data) =>
      o = new Objective(data)
      o.setMonths()
      _.each data.goals, (goal) -> o.addExistingGoal(goal)
      _.each data.kpis, (kpi)  -> o.kpis[kpi.id] = kpi
      _.each data.affiliations, (person)  -> o.people[person.id] = { id: person.id, name: person.person.name }
      o.updateStructure()
      o

    owner: () =>
      @ownerEmployment.person.name

    @mapObjectives: (objectives) => _.map objectives, (objective) -> new Objective(objective)

    @allForEmployment: (options = {}) ->
      Restangular.all('objectives').getList(options).then (response) -> response

    @one: (objective_id) ->
      Restangular.one('objectives', objective_id).get().then (response) ->
        Objective.fromRestangular(response.data)

    @getOverview: (options = {}) ->
      Restangular.one('objectives').one('overview').get(options)

    @create: (objective) ->
      Restangular.all('objectives').post(objective.toRestangular()).then (response) ->
        response.data

    @update: (objective) ->
      Restangular.one('objectives', objective.id).patch(objective.toRestangular()).then (response) ->
        response.data
