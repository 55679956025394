class SurveyResponderRoleRepository extends Service

  constructor: (@Restangular, @SurveyResponderRole) ->

  getRoles: (groupId, options = {}) ->
    @Restangular.one('survey_responder_role_group', groupId).all('roles').getList(options).then (response) =>
      _.map(response.data, (role) => new @SurveyResponderRole(role))

  get: (roleId, options = {}) ->
    @Restangular.one('survey_responder_role', roleId).get(options).then (response) =>
      new @SurveyResponderRole(response.data)

  update: (roleId, options = {}) ->
    @Restangular.one('survey_responder_role', roleId).patch(options)
