angular.module 'Upsteem'
  .directive 'columnHeader', () ->
    restrict: 'A'
    scope:
      field: '@columnHeader'
      disableSort: '@'
      translationScope: '@'
      defaultSort: '@'
      size: '@'
    replace: true
    templateUrl: 'tableSortHeader'
    controller: 'TableSortHeaderCtrl'
    controllerAs: 'ctrl'
