class ResponderMassUploadDeletionController extends Controller
  constructor: (@$scope, @$state, @SurveyRepository, @toastr, @$translate, @survey, @selectionRepository, @responderMassUpload, @deleteResponderMassUpload) ->

  confirmUploadedRespondersDeletion: () ->
    @_closeModal()
    @deleteResponderMassUpload()

  cancelUploadedRespondersDeletion: () ->
    @_closeModal()

  _closeModal: () ->
    @$scope.modalInstance.close()
