class AddResponderModalCtrl extends Controller
  constructor: (@$rootScope, @$scope, @Vacancy, @IndividualResponderRepository, @toastr, @SurveyRepository, @IndividualResponder, @$translate, @StructureRepository) ->
    @role = @$scope.role
    @survey = @$scope.survey
    @responders = @$scope.responders
    @newResponders = []
    @jobFamilies = @allJobFamilies()
    @formData = {}

  allJobFamilies: ->
    company_id = @$rootScope.currentPerson.currentCompany.id
    @StructureRepository.getAllProfessionalGroupsAsArray(company_id)

  getPeople: (name) ->
    if _.isUndefined @availablePeople
      @SurveyRepository.getAvailablePeople(@survey, null).then (response) =>
        @availablePeople = response.data
        @_filterAvailablePeople name
    else
      @_filterAvailablePeople name

  _filterAvailablePeople: (name) ->
    name = _.toLower name
    _.filter @availablePeople, (person) ->
      targetName = _.toLower person.name
      _.includes(targetName, name)

  closeModal: () ->
    @newResponders = []
    @formData = {}
    @$scope.modalInstance.close()

  responderAdded: (newResponder) ->
    responder = { id: newResponder.id, role: @role, name: newResponder.name }
    @newResponders.push(responder) unless _.some @newResponders, (r) -> r.id == responder.id
    @newResponder = undefined

  removeNewResponder: (index) ->
    @newResponders.splice(index, 1)

  addAnonymousResponder: () ->
    @formData.group_name = @$scope.role
    @IndividualResponderRepository.createAnonymous(@survey.id, @formData).then((response) =>
      @responders.push(response.data)
      @toastr.success @$translate.instant("survey.responder.invited")
    ).then((response) ->

    ).then(null, (err) =>
      @toastr.error @$translate.instant("survey.responder.error.duplicate")
    )
    @closeModal()

  addResponders: () ->
    _.forEach(@newResponders, (newResponder, idx) =>
      responder = {id: newResponder.id, role: @role}
      @IndividualResponderRepository.create(@survey.id, responder.id, responder).then((response) =>
        @responders.push(response.data)
        @toastr.success @$translate.instant("survey.responder.added")
        if (idx == @newResponders.length - 1)
          @closeModal()
          return false
      ).then(null, (err) =>
        if(err.data.error == "already_created")
          @toastr.error @$translate.instant("survey.responder.error.duplicate")
      )
    )
