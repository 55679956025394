class Permissions extends Service
  constructor: (toastr, $state) ->
    return class Permissions
      constructor: (obj) ->
        @array = obj

      showMessageAndRedirect: ->
        toastr.error('Permission Denied')
        $state.go 'root'

      areOwnGoalsAccessible: () ->
        @one('allowingToCreateGoals')

      areEmployeeGoalsAccessible: () ->
        @one('allowingToCreateGoals') && @one('allowingToViewSubordinates')

      areGoalSettingsAccessible: () ->
        @one('allowingToCreateGoals') && @one('allowingToManageGoals')

      one:      (item) -> _.includes @array, item

      any:      (array) -> _.some _.intersection @array, array
      checkAny: (array) => @showMessageAndRedirect() unless @any(array)

      merge: (other) -> new Permissions(_.uniq(this.array.concat(other.array)))
