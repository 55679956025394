angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'trainings',
        parent: 'protected'
        url: "/trainings"
        template: "<ui-view/>"
        resolve:
          response: (Training) ->
            Training.all()
          trainingCategories: (TrainingCategory) ->
            TrainingCategory.all()
          jobFamilies: (ProfessionalGroup, currentPerson) ->
            ProfessionalGroup.allForCompany(currentPerson.currentCompany.id)
          opts: ->
            {}
        views:
          '@':
            controller: "TrainingsListCtrl"
            controllerAs: "ctrl"
            templateUrl: "trainings/list/trainingsList.html"
      .state 'my_trainings',
        parent: 'protected'
        url: "/my_trainings"
        template: "<ui-view/>"
        resolve:
          response: (Training, currentPerson) ->
            Training.all({employment_id: currentPerson.currentEmployment.id})
          trainingCategories: (TrainingCategory) ->
            TrainingCategory.all()
          jobFamilies: (ProfessionalGroup, currentPerson) ->
            ProfessionalGroup.allForCompany(currentPerson.currentCompany.id)
          opts: (currentPerson) ->
            {employment_id: currentPerson.currentEmployment.id}
        views:
          '@':
            controller: "TrainingsListCtrl"
            controllerAs: "ctrl"
            templateUrl: "trainings/list/trainingsList.html"

class TrainingsListCtrl extends Controller
  constructor: (@$translate, @toastr, @$modal, TableOptions, response, @currentPerson, @jobFamilies, @trainingCategories, @Paging, @Training, @Enrollment, @opts) ->
    @paging = new @Paging(response)
    @all    = new Array(@paging.getTotalPages())

    @addTrainingsToList(response.data)

    @trainingTypes = @Training.trainingTypes()

    @tableOptions = new TableOptions(translationScope: 'trainings')

    @availableTextPhrases = [
      {
        translate: 'general.name',
        phrase: 'name'
      },
      {
        phrase: 'category_ids',
        translate: 'trainings.category'
      }
    ]

  getFilteredTrainings: => @fetchTrainings(@phrases)

  trainingInitiators: =>
    _.uniq( _.flattenDeep
      _.map( @all, (trainingsList) ->
        _.map(trainingsList, (training) -> training.initiator.name)
      )
    )
  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@all[@paging.iterator()])
      @fetchTrainings({page: page})

  fetchTrainings: (options = {}) =>
    angular.extend options, @opts
    @Training.all(options).then (response) =>
      @paging = new @Paging(response)
      @all[@paging.iterator()] = _.map response.data, (training) => new @Training(training)

  addTrainingsToList: (trainings) =>
    @all[@paging.iterator()] = _.map trainings, (training) => new @Training(training)

  classForTrainingParticipation: (training) =>
    enrollment = training.enrollmentForPerson(@currentPerson.currentEmployment.id)

    if enrollment
      if !enrollment.hasDecision() || enrollment.isAccepted()
        return 'green-border'
      else
        return 'red-border'

  removeEnrollment: (training) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'trainings/enrollments/deleteEnrollmentModal.html'
      controller: "DeleteEnrollmentModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        enrollment:    => training.enrollmentForPerson(@currentPerson.currentEmployment.id)
        training_name: => training.name
    .result.then (enrollment) =>
      @toastr.success @$translate.instant('trainings.participation_removed')
      training.enrollments = _.reject(training.enrollments, (e) => e.id == enrollment.id)

  openTrainingDeleteModal: (training) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'trainings/delete/deleteTrainingModal.html'
      controller: "DeleteTrainingModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        training: => training
    .result.then =>
      @toastr.success @$translate.instant('trainings.training_deleted')
      _.remove(@all[@paging.iterator()], training)

  addEnrollment: (training) =>
    training.addEnrollments([@currentPerson.currentEmployment.id]).then (response) =>
      training.enrollments.push(new @Enrollment(response.data[0]))

  allowedToSeeActionsButton: (training) =>
    training.permissions.any(["allowingToUpdate", "allowingToDelete", "allowingToParticipate"]) ||
    training.enrollmentForPerson(@currentPerson.currentEmployment.id)
