class UserConfirmationConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'user.confirmation',
        url: '/confirmation'
        views:
          'unprotected@':
            resolve:
              confirm: ($state, $location, $translate, Person, toastr) ->
                Person.confirmUser($location.search().confirmation_token).then ->
                  toastr.success $translate.instant 'people.confirmations.confirmed'
                  $state.go 'login'
      .state 'user.confirmation_resend',
        url: '/confirmation/resend'
        views:
          'unprotected@':
            templateUrl: 'user/confirmation/userConfirmation.html'
            controller: 'UserConfirmationRouteCtrl'
            controllerAs: 'routeCtrl'

class UserConfirmationRouteCtrl extends Controller
  constructor: (@$state, @Person, @toastr, @$translate) ->

  sendConfirmation: (form) ->
    if form.$valid
      @Person.resendConfirmation(@email).then =>
        @toastr.success @$translate.instant 'people.confirmations.sent'
        @$state.go 'login'
