angular.module('Upsteem').constant 'Constants',
  translations:
    en: 'locales/en.json'
    et: 'locales/et.json'
    fi: 'locales/fi.json'
    ru: 'locales/ru.json'
    lt: 'locales/lt.json'
    lv: 'locales/lv.json'
    pt: 'locales/pt.json'
    pl: 'locales/en.json'

  languages:
    short:
      et: 'et'
      en: 'en'
      ru: 'ru'
      fi: 'fi'
      pt: 'pt'
      lv: 'lv'
      lt: 'lt'
      pl: 'pl'
    long:
      et: 'estonian'
      en: 'english'
      ru: 'russian'
      fi: 'finnish'
      pt: 'portuguese'
      lv: 'latvian'
      lt: 'lithuanian'
      pl: 'polish'

  defaults:
    listViewType: 'grid'
    IS_SORT_ASC: true

  helpLinks:
    et:       "http://upsteemabi.uservoice.com/knowledgebase"
    fallback: "http://upsteem.uservoice.com/knowledgebase"

  icons:
    SORT_TEXT_ICON:      'fa-sort'
    SORT_TEXT_ASC_ICON:  'fa-caret-down'
    SORT_TEXT_DESC_ICON: 'fa-caret-up'
    ANGLE_DOWN:          'fa-angle-down'
    ANGLE_RIGHT:         'fa-angle-right'

  page_sizes:
    SURVEY_OVERVIEW: 30

  structures:
    PAGE_SIZE: 50

  training_statuses:
    DRAFT:    "draft"
    PUBLIC:   "public"
    PRIVATE:  "private"
    FINISHED: "finished"
    ARCHIVED: "archived"

  goals_statuses:
    NOT_STARTED: 'not_started'
    IN_PROGRESS: 'in_progress'
    DONE:        'done'

  survey_statuses:
    DRAFT:    'draft'
    ONGOING:  'ongoing'
    CLOSED:   'closed'
    FINISHED: 'finished'

  survey_types:
    APPRAISAL_INTERVIEW: 'appraisal_interview'
    EVALUATION: 'evaluation'
    PUBLIC_SURVEY: 'public_survey'
    STAFF_SATISFACTION_SURVEY: 'staff_satisfaction_survey'

  received_feedback_survey_types:
    APPRAISAL_INTERVIEW: 'appraisal_interview'
    EVALUATION: 'evaluation'
    FILE: 'file'
    ALL: 'all'

  survey_roles:
    EMPLOYEE: 'employee'
    MANAGER: 'manager'
    COLLEAGUE: 'colleague'
    SUBORDINATE: 'subordinate'
    PEER: 'peer'
    CUSTOMER_OR_PARTNER: 'customer_or_partner'
    PUBLIC: 'public'

  personal_feedback_periods:
    ALL: 'all'
    QUARTERLY: 'quarterly'
    HALF_YEARLY: 'half-yearly'
    YEARLY: 'yearly'

  remark_periods:
    ALL: 'all'
    QUARTERLY: 'quarterly'
    HALF_YEARLY: 'half-yearly'
    YEARLY: 'yearly'

  keycodes:
    ENTER: 13
    ESCAPE: 27
