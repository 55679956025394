class AffiliationConfirmationConfig extends Config
  constructor: (@$stateProvider) ->
    @$stateProvider
      .state 'affiliation_confirmations',
        parent: 'unprotected'
        url: '/affiliation_confirmations/:affiliation_confirmation_id'
        abstract: true

      .state 'affiliation_confirmations.activate',
        url: '/activate'
        resolve:
          activateAndRedirect: ($stateParams, Restangular, $state, toastr) ->
            Restangular.one('affiliation_confirmations', $stateParams.affiliation_confirmation_id).put().then (data) =>
              # TODO
              # toastr.success 'Fix Me'
              $state.go 'root'

      .state 'affiliation_confirmations.reject',
        url: '/reject'
        resolve:
          activateAndRedirect: ($stateParams, Restangular, $state, toastr) ->
            Restangular.one('affiliation_confirmations', $stateParams.affiliation_confirmation_id).remove().then (data) =>
              # TODO
              # toastr.success 'Fix me'
              $state.go 'root'
