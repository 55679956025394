class SurveyResponderRoleGroup extends Service
  constructor: (Base) ->
    return class SurveyResponderRoleGroup extends Base
      constructor: (json) ->
        angular.extend(@, json)

      nameWithSourceTemplateTitle: ->
        if @source_template
          "#{@name} (#{@source_template.title})"
        else
          @name
