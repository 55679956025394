class Paging extends Service
  constructor: (Base) -> return class Paging extends Base
    constructor: (json) ->
      angular.extend(@, json)
      @page = @getPage()

    changePage: (page) -> @page = page
    iterator: -> @page - 1

    getNextPage:   -> @_getIntHeader('x-next-page')
    getOffset:     -> @_getIntHeader('x-offset')
    getPage:       -> @_getIntHeader('x-page')
    getPerPage:    -> @_getIntHeader('x-per-page')
    getPrevPage:   -> @_getIntHeader('x-prev-page')
    getTotal:      -> @_getIntHeader('x-total')
    getTotalPages: -> @_getIntHeader('x-total-pages')

    _getIntHeader: (name) ->
      header = @headers(name)
      if header
        parseInt(header)
      else
        null
