class SurveyResponderRoleGroupRepository extends Service
  constructor: (@Restangular, @SurveyResponderRoleGroup) ->

  getDefault: (options = {}) ->
    @Restangular.all('survey_responder_role_groups').all('default').getList(options = {}).then (response) =>
      _.map(response.data, (group) => new @SurveyResponderRoleGroup(group))

  getCustom: (options = {}) ->
    @Restangular.all('survey_responder_role_groups').all('custom').getList(options = {}).then (response) =>
      _.map(response.data, (group) => new @SurveyResponderRoleGroup(group))

  create: (groupId, options = {}) ->
    @Restangular.one('survey_responder_role_group', groupId).customPOST(options).then (response) =>
      new @SurveyResponderRoleGroup(response.data)

  delete: (groupId, options = {}) ->
    @Restangular.one('survey_responder_role_group', groupId).remove()

  update: (groupId, options = {}) ->
    @Restangular.one('survey_responder_role_group', groupId).patch(options)
