class SurveyOverviewCtrl extends Controller
  constructor: (
    @$rootScope,
    @$scope,
    @$state,
    response,
    structures,
    jobFamilies,
    @Survey,
    @Paging,
    TableOptions,
    @Constants,
    @$window,
    @$modal,
    @$translate,
    @SurveyRepository,
    @Permissions
  ) ->
    @tableOptions = new TableOptions(translationScope: 'surveys', sortAction: @sort)

    @massSelectionOperationAvailable = true
    @massSelectionOptions = {
      allSelected: false,
      allowDelete: true,
      allowedActions: []
    }

    @options = {}
    @states = @Survey.allStatuses()
    @jobFamilies = jobFamilies
    @structures = structures
    @_updateAvailableCategories(response)
    @_updateAvailableTemplates(response)
    @_updateAvailableProjects(response)
    @subjects = response.data.subjects

    @addDateRangePickerOptions()
    @_resetPageInfo(response)
    @addSurveysToList(response)
    @initializeTooltips()
    @clear = false

  allowMassSelection: () ->
    @massSelectionOperationAvailable && !@massSelectionOptions.allSelected

  allowMassUnselection: () ->
    @massSelectionOperationAvailable && @massSelectionOptions.allSelected

  showSurveyInOldApp: (survey) ->
    !@showSurveyInCurrentApp(survey)

  showSurveyInCurrentApp: (survey) ->
    survey.canRenderSurveyWithinCurrentApp()

  surveyCategoryText: (survey) ->
    key = "surveys." + survey.category + @_surveyCategoryTextSuffix(survey)
    @$translate.instant(key)

  _surveyCategoryTextSuffix: (survey) ->
    if @isSorainen()
      ".sorainen"
    else
      ".default"

  isSorainen: () ->
    @$rootScope.currentPerson.currentCompany.id == 1911

  emailStatistics: (survey) ->
    @$state.go('email_statistics', {bounced: survey.bounced_emails, survey_id: survey.id})

  clearFilters: () =>
    @clear = !@clear
    @clearDateRange()
    @options = {}
    @fetchFilteredSurveysOnCategoryChange()

  clearDateRange: () ->
    @period = {}
    angular.element(
      document.querySelectorAll('#period')).html(@$translate.instant('general.period')
    )

  initializeTooltips: ->
    @$translate.use(@$rootScope.currentPerson.profile_language)
    @$scope.check_emails = { "title": @$translate.instant('emails.check_emails') }

  addDateRangePickerOptions: =>
    @period = {
      period: {}
    }

    @rangeOpts = {
      locale: {
        format: 'DD.MM.YYYY',
        firstDay: 1
      }
      startDate: moment().startOf('week'),
      endDate: moment(),
      opens: 'left',
      ranges: {
        'This Week': [
          moment().startOf('week'),
          moment().endOf('week')
        ],
        'Last Week': [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week')
        ],
        'This Month': [
          moment().startOf('month'),
          moment().endOf('month')
        ],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ],
        'This Year': [
          moment().startOf('year'),
          moment().endOf('year')
        ],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year')
        ]
      }
      autoApply: true
      eventHandlers: {
        'apply.daterangepicker': (ev, picker) =>
          angular.element(document.querySelectorAll('#period')).html(
            ev.model.startDate.format('DD.MM.YYYY') + ' - ' + ev.model.endDate.format('DD.MM.YYYY')
          )
          @fetchFilteredSurveys()
      }
    }

  showReminderLink: () ->
    @permissions.one('allowingToSendReminders') &&
    @initialStatistics.unanswered_count != null && @initialStatistics.unanswered_count != 0

  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@all[@paging.iterator()])
      @options.page = page
      @fetchFilteredSurveys(page)

  sort: (sortOptions = {}) =>
    angular.extend(@options, sortOptions)
    @fetchFilteredSurveys()

  massUpdateCallback: (data) =>
    @fetchFilteredSurveys()

  fetchFilteredSurveysOnCategoryChange: () ->
    delete(@options.templates)
    delete(@options.projects)
    @fetchFilteredSurveys().then (response) =>
      @_updateAvailableTemplates(response)
      @_updateAvailableProjects(response)

  fetchFilteredSurveys: (page = null) ->
    @options.per_page = @Constants.page_sizes.SURVEY_OVERVIEW
    @options.page = 1 unless page

    if (@period != null) && @period
      @options.started = @period.startDate
      @options.deadline = @period.endDate

    _.each(_.keys(@options), ((key) =>
      if _.isArray(@options[key]) && _.isEmpty(@options[key])
        delete(@options[key])
    ), @)

    @_fetchFilteredSurveysFromRepository()

  _fetchFilteredSurveysFromRepository: (errors = null) =>
    @SurveyRepository.filterOverviewStatistics(@options).then (response) =>
      @selectedRoles = []
      @addSurveysToList(response)
      response

  addSurveysToList: (response) =>
    @_refreshPageInfo(response)
    data = response.data
    @all[@paging.iterator(@paging.page)] = _.map(data.surveys, (survey) => new @Survey(survey))
    @initialStatistics = data.statistics
    @roles = @populateRoleList(data.statistics)
    @permissions = new @Permissions(data.permissions)
    @addChartData(data.statistics)
    @setAvailableMassActions()

  _updateAvailableCategories: (response) ->
    @categories = response.data.available_categories

  _updateAvailableTemplates: (response) ->
    @templates = response.data.available_templates
    _.each @templates, (template) =>
      if template.title == "title_not_set"
        template.title = @$translate.instant('general.title_not_set')

      # Assign fields for items attribute provided by selectpicker directive:
      template.value = template.id
      template.text = template.title

  _updateAvailableProjects: (response) ->
    @projects = _.sortBy response.data.available_projects, 'name'
    _.each @projects, (project) =>
      # Assign fields for items attribute provided by selectpicker directive:
      project.value = project.id
      project.text = project.name

  _refreshPageInfo: (response) ->
    if @_allSurveyIdsDiffer(response.data.all_survey_ids)
      @_resetPageInfo(response)

  _resetPageInfo: (response) ->
    @allSurveyIds = response.data.all_survey_ids
    @unSelectAll()
    @paging = new @Paging(response)
    @all = new Array(@paging.getTotalPages())

  _allSurveyIdsDiffer: (newAllSurveyIds) ->
    !_.isEqual(@allSurveyIds, newAllSurveyIds)

  selectAll: () ->
    @initializeSelectedSurveys()
    _.each @allSurveyIds, (id) =>
      @selectedSurveys[id] = true
    @massSelectionOptions.allSelected = true

  unSelectAll: () ->
    @initializeSelectedSurveys()
    @massSelectionOptions.allSelected = false

  populateRoleList: (statistics) ->
    _.uniq (_.map statistics.answered, (v, k) -> k).concat(_.map statistics.unanswered, (v, k) -> k)

  initializeSelectedSurveys: () ->
    @selectedSurveys = {}

  setAvailableMassActions: () ->
    if _.size(@options['status']) != 1
      @massSelectionOptions.allowedActions = {}
    else
      @massSelectionOptions.allowedActions = switch _.first(@options['status'])
        when 'draft'
          { send_out: true }
        when 'ongoing'
          { close: true }
        when 'closed'
          { open: true, finish: true }
        when 'finished'
          { unfinish: true }

  selectedSurveyIds: () ->
    _.reduce(@selectedSurveys, ((result, n, key) -> (
      result.push(key) if n == true
      result
    )), [])

  openConfirmationModal: (action) ->
    selectedSurveyIds = @selectedSurveyIds()
    callback = @massUpdateCallback
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'surveys/overview/overviewConfirmationModal.html'
      controller: 'OverviewConfirmationCtrl'
      controllerAs: 'ctrl'
      resolve: {
        action: () -> action,
        targetIds: () -> selectedSurveyIds,
        callback: () -> callback
      }
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> @$scope.modalInstance = undefined

  openFinishModal: () ->
    selectedSurveyIds = @selectedSurveyIds()
    roles = @SurveyRepository.massGetNotifiableRolesForFinishing(selectedSurveyIds)
    callback = @massUpdateCallback
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'small'
      templateUrl: 'surveys/overview/overviewFinishModal.html'
      controller: 'OverviewFinishCtrl'
      controllerAs: 'ctrl'
      resolve: {
        targetIds: () -> selectedSurveyIds,
        roles: () -> roles,
        callback: () -> callback
      }
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> @$scope.modalInstance = undefined

  addChartData: (statistics) ->
    @answeringChartConfig = angular.extend(@baseChartOptions(), @addAnsweringChartData(statistics))
    @stateChartConfig = angular.extend(@baseChartOptions(), @addStateChartData(statistics))

  switchChart: () ->
    data = {}

    if @selectedRoles.length > 0
      data = {
        answered_count: @sumStatistics(@initialStatistics.answered),
        unanswered_count: @sumStatistics(@initialStatistics.unanswered)
      }
    else
      data = @initialStatistics
    @answeringChartConfig = angular.extend(@baseChartOptions(), @addAnsweringChartData(data))

  sumStatistics: (collection) ->
    totals = _.map @selectedRoles, (role) -> collection[role] || 0
    _.reduce totals, (sum, n) -> sum + n

  baseChartOptions: () ->
    {
      options: {
        chart: {
          plotBackgroundColor: null
          plotBorderWidth: null
          plotShadow: false
          type: 'pie'
        }
        exporting: {enabled: false}
        plotOptions: {
          pie: {
            dataLabels: {
              distance: 10
              connectorWidth: 0
            }
            size: 300
          }
        }
      }
    }

  addAnsweringChartData: (statistics) =>
    {
      series: [{
        name: @$translate.instant('survey.chart.count')
        innerSize: '50%'
        data: [{
          name: @$translate.instant('survey.chart.answered'),
          y: statistics.answered_count,
          color: '#009688'
        }, {
          name: @$translate.instant('survey.chart.unanswered'),
          y: statistics.unanswered_count,
          color: '#E91E63'
        }]
      }]
      title: {
        text: ''
      }
    }

  addStateChartData: (statistics) =>
    data = _.map statistics.states, (v, k) =>
      color = 'black'

      switch k
        when 'draft'
          color = '#9E9E9E'
        when 'ongoing'
          color = '#FFEB3B'
        when 'closed'
          color = '#E91E63'
        when 'finished'
          color = '#009688'

      { name: @$translate.instant("survey.chart.#{k}"), y: v, color: color }

    {
      series: [{
        innerSize: '50%'
        name: @$translate.instant('survey.chart.count')
        data: data
    }]
     title: {
       text: ''
     }
    }
