class EmploymentRepository extends Service
  constructor: (@Restangular) ->

  getInvalidEmploymentEmails: (emails) ->
    @Restangular.all('employments').all('validate_in_current_company').post({ emails: emails })

  getUndraftableAppraisalEmails: (emails) ->
    @Restangular.all('employments')
                .all('validate_appraisal_interview_drafting')
                .post({ emails: emails })
