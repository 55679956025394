class UserConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'user',
        parent: 'unprotected'
        url: '/users'
        abstract: true

      .state 'user.invitation',
        url: '/invitation'
        abstract: true

      .state 'user.invitation.accept',
        url: '/accept'
        resolve:
          invitationToken: ($location) ->
            $location.search().invitation_token
          form: (Restangular, invitationToken) ->
            Restangular.all('people').one('invitations', invitationToken)
          preparedForm: (form, AnonymousVisitor, LanguageService) ->
            form.get().then (response) ->
              visitor = new AnonymousVisitor(response.data)
              form.language = visitor.language
              LanguageService.changeLanguageByShort(form.language)
            form
        views:
          'unprotected@':
            templateUrl: 'user/accept.html'
            controller: 'UserInvitationController'
            controllerAs: 'ctrl'

class UserInvitationController extends Controller
  constructor: (@$location, @$state, @$translate, @form) ->

  passwordsMatch: () ->
    @form.password_confirmation == @form.password
  save: ->
    @form.language = @$translate.use()
    @form.put().then =>
      if @$location.search().redirect_to
        window.location = @$location.search().redirect_to
      else
        @$state.go 'root'
