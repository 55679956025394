class SurveyCloseModalCtrl extends Controller
  constructor: (@$scope, @SurveyRepository, @toastr, @$state, @$translate) ->
    @survey = @$scope.$parent.ctrl.survey

  closeModal: () ->
    @$scope.modalInstance.close()

  # This code needs refactoring, too many modal ctrls, postRequest from Surveys overview is perf for this functionality

  postRequest: () ->
    @closeModal()
    @SurveyRepository.massClose(@survey.id).then (response) =>
      @$state.go('survey', { id: @survey.id }, { reload: true })
