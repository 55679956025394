class IndividualResponderRepository extends Service

  constructor: (@$http, @Restangular) ->

  create: (survey_id, responder_id, params) ->
    @$http.post(
      @_respondersUri(survey_id, responder_id),
      params
    )

  delete: (survey_id, responder_id) ->
    @$http.delete(@_respondersUri(survey_id, responder_id))

  update: (survey_id, responder_id, params = {}) ->
    @$http.patch(
      @_respondersUri(survey_id, responder_id), params
    )

  createAnonymous: (survey_id, responder) ->
    @$http.post(@_inviteUri(survey_id), responder)

  sendReminder: (survey_id, responder_id) ->
    @$http.post(@_sendReminderUri(survey_id, responder_id))

  _inviteUri: (survey_id) ->
    "/api/v2/survey/" + survey_id + "/responders/" + "invite"

  _respondersUri: (survey_id, responder_id) ->
     "/api/v2/survey/" + survey_id + "/responders/" + responder_id

  _sendReminderUri: (survey_id, responder_id) ->
    @_respondersUri(survey_id, responder_id) + "/send_reminder"

  updateRole: (surveyId, personId, params) ->
    @Restangular.one('survey', surveyId).one('individual_responders', personId).one('role').patch(params)
