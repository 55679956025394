angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'goal.edit',
        url: "/edit"
        template: "<ui-view/>"
        views:
          '@':
            controller: "EditGoalCtrl"
            controllerAs: "ctrl"
            templateUrl: "goals/edit/editGoal.html"

class EditGoalCtrl extends Controller
  constructor: (@$scope, @$state, @Vacancy, @currentPerson, @goal, @Employment) ->
    @editOnlyProgress = @goal.canCurrentPersonEditOnlyProgress()
    @targetExceeded   = @goal.isTargetExceeded()

    @people = []
    @selectedPeople = []

    # need to refactor !!
    unless @goal.partialUpdateAllowed()
      @$scope.$watchCollection('ctrl.selectedPeople', @updatePeople)
      if @currentPerson.currentCompany.id == 1911
        @Employment.shortEmploymentsForSorainen(@currentPerson.currentCompany.id).then ((employments) ->
          @people =
            _.uniq(_.map(_.sortBy(employments, 'name'), (person) -> {id: person.id, name: person.name}))
          @selectedPeople = _.filter(@people, ((person) -> person.id in @goal.affilationIds()), @)
        ).bind(@)
      else
        @Employment.shortEmploymentsForStructure(@currentPerson.currentEmployment.entity.id).then ((employments) ->
          @people =
            _.uniq(_.map(_.sortBy(employments, 'name'), (person) -> {id: person.id, name: person.name}))
          @selectedPeople = _.filter(@people, ((person) -> person.id in @goal.affilationIds()), @)
        ).bind(@)

    @ratingSlider = { floor: 0, ceil: 5 }

  getProgressSliderOptions: =>
    { floor: 0, ceil: @goal.target }

  save: =>
    @goal.responsible = ""
    @goal.update().then (goal) =>
      @goal = goal
      @$state.go('goal', {goal_id: @goal.id}, {reload: true})

  back: =>
    @$state.go('goal', {goal_id: @goal.id})

  isSorainen: =>
    @currentPerson.currentEmployment.employer.id == 1911    # DEV-718: Sorainen goal view changes

  canSetRatingAndCompletionComment: =>
    @goal.permissions.one('allowingToUpdate') and
      @currentPerson.currentEmployment.id != @goal.owner_employment_id

  addPerson: (person) =>
    @goal.addPerson(person)

  updatePeople: (people) =>
    @goal.updatePeople(people)
