angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'planning_activity_settings',
        parent: 'protected'
        url: '/planning/settings/classificators'
        template: "<ui-view/>"
        views:
          '@':
            controller: "GroupListCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/settings/ActivitySettingsView.html"

class ActivitySettingsCtrl extends Controller
  constructor: () ->
