class Feedback extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      scope: {
        person: '=',
        style: '@'
      },
      templateUrl: 'directives/feedback.html',
      controller: 'FeedbackDirectiveCtrl',
      controllerAs: 'ctrl'
    }

class FeedbackDirectiveCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$modal, @PersonRepository) ->
    @$scope.personRepository = @PersonRepository
    @style = @$scope.style

  whiteButton: () ->
    @style != undefined && @style == 'white'

  openModal: () ->
    @$modal.open
      windowClass: "medium"
      templateUrl: 'directives/feedbackModal.html'
      controller: "FeedbackModalCtrl"
      controllerAs: 'ctrl'
      scope: @$scope
      resolve:
        availablePeople: (@$rootScope, @StructureRepository) ->
          @StructureRepository.getEmployments(
            @$rootScope.currentPerson.currentCompany.id
          ).then (response) ->
            _.map response.data, (employment) ->
              id: employment.person_id,
              name: employment.person_name,
              entity_id: employment.entity_id,
              department: employment.department_name

        categories: (@PersonalFeedbackCategoryRepository, @PersonalFeedbackCategory) ->
          options = {is_visible: true}
          @PersonalFeedbackCategoryRepository.getAll(options).then (response) ->
            _.map(response.data, (category) -> new @PersonalFeedbackCategory(category))
