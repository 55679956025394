class MassUploadHeaderErrorsCtrl extends Controller
  constructor: (@$rootScope, @$scope, @Paging, @SurveyRepository) ->
    @category = 'header'
    @columnsMetaData = [
      { label: 'column' }
      { label: 'error' }
    ]

    @parentController = @$scope.$parent.ctrl
    @initialResponse = @parentController.categorizedResponses.header
    @_pageErrors(@initialResponse)

  _pageErrors: (response) ->
    @paging = new @Paging(response)
    @errors = new Array(@paging.getTotalPages())
    @_addErrorsPage(response.data)

  changePage: (page) ->
    @paging.changePage(page)
    if _.isUndefined(@getForPage())
      @_fetchErrors(page)

  getForPage: () ->
    @errors[@paging.iterator()]

  _fetchErrors: (page) ->
    @$rootScope.isStateLoading = true
    @SurveyRepository.getMassUploadErrors(
      @parentController.surveyId, @parentController.massUploadId, { category: @category, page: page }
    ).then (response) =>
      @_addErrorsPage(response.data)
      @$rootScope.isStateLoading = false

  _addErrorsPage: (errors) ->
    @parentController.normalizeErrorList(errors)
    @errors[@paging.iterator()] = errors
