class RelationshipsCtrl extends Controller
  constructor: (@$scope, @$modal) ->
    @selectedRelationships

  addReationshipModal: () ->
    @$scope.modalInstance = @$modal.open
      windowClass: "medium"
      templateUrl: 'planning/activity/relationships/relationshipsModal.html'
      controller: "RelationshipsModalCtrl"
      controllerAs: 'ctrl'
      scope: @$scope

  updateRelationships: (newRelationship) ->

