class AdditionalInfoModalCtrl extends Controller
  constructor: (@$scope, @$modalInstance, @enrollment, @currentPerson) ->
    @newRequest           = ""
    @requestBeingAnswered = null

  canPersonRespondToRequests: =>
    @enrollment.participant_employment.id == @currentPerson.currentEmployment.id

  addNewRequest: =>
    result = @newRequest
    @clearNewRequest()
    @enrollment.requestAdditionalInfo(result).then (new_request) =>
      @enrollment.additional_info_requests.push(new_request)

  respondToRequest: =>
    @enrollment.addResponseToRequest(@requestBeingAnswered).then (result) =>
      @requestBeingAnswered = result
      @clearRequestBeingAnswered()

  setRequestBeingAnswered: (request) => @requestBeingAnswered =  request
  isRequestBeingAnswered:  (request) => @requestBeingAnswered == request
  clearRequestBeingAnswered:         => @requestBeingAnswered = null

  clearNewRequest: -> @newRequest = null

  isEnrollmentForCurrentPerson: => @enrollment.participant_employment.id == @currentPerson.currentEmployment.id

  cancel: => @$modalInstance.dismiss('cancelled')
