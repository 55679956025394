angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'objective.edit',
        url: "/objectives/{objective_id:int}/edit"
        parent: 'protected'
        template: "<ui-view/>"
        resolve:
          objective: ($stateParams, Objective) ->
            Objective.one($stateParams.objective_id)
          people: (Employment, currentPerson) ->
            Employment.shortEmploymentsForStructure(currentPerson.currentEmployment.entity.id)
        params:
          employment_id: null
        views:
          '@':
            controller: "EditObjectiveCtrl"
            controllerAs: "ctrl"
            templateUrl: "objectives/edit/editObjective.html"

class EditObjectiveCtrl extends Controller
  constructor: (@$scope, @$state, @currentPerson, people, @Objective, @$stateParams, objective, @Kpi, @toastr, @$translate) ->
    @objective = objective

    @people = _.uniq _.map _.sortBy(people, 'name'), (person) -> {id: person.id, name: person.name}
    @selectedPeople = _.map @objective.people, (person) -> {id: person.id, name: person.name}
    @$scope.$watchCollection('ctrl.selectedPeople', @updatePeople)

    @kpis = []
    @visibleElements = {}
    @selectedKpis = _.values @objective.kpis
    @$scope.$watchCollection('ctrl.selectedKpis', @updateKpis)
    @getKpis()

    @ratingSlider = {
      floor: 0,
      ceil: 5
    }

  save: =>
    @Objective.update(@objective, @objective.id).then (objective) =>
      @toastr.success @$translate.instant('toastr.actions.updated', { item: objective.title })
      @$state.go('objective', {objective_id: objective.id})

  back: =>
    @$state.go('objectives', {objective_id: @objective.id})

  getKpis: =>
    @Kpi.getCustom().then (response) =>
      @kpis = response.data

  addPerson: (person) =>
    @objective.addPerson(person)

  activeGoals: () =>
    []

  updatePeople: (people) =>
    @objective.updatePeople(people)

  updateKpis: (kpis) =>
    @objective.updateKpis(kpis)

  setMonths: =>
    @objective.setMonths()

  isErroneous: (field) =>
    (field.$dirty || field.$touched) && field.$invalid

  toggle: (key, index) =>
    @visibleElements["#{key}_#{index}"] = !@visibleElements["#{key}_#{index}"]

  isVisible: (key, index) =>
    !!@visibleElements["#{key}_#{index}"]
