class TableFilterItem extends Service
  constructor: -> return class TableFilterItem
    constructor: (@item, @phrases) ->
      @separator = '.'

    deepMatch: (path, pattern, obj) =>
      obj = obj || @item

      if _.isArray obj
        return _.find(obj, (item) => @deepMatch(path, pattern, item))?

      segments = path.split(@separator)
      last     = _.last segments
      current  = segments.shift()

      if current is last and _.isObject(pattern)
        selectedValues = _.compact _.map(pattern, (value, key) -> key if value)
        return true if _.isEmpty selectedValues

        _.includes selectedValues, @value(obj, current)

      else if current is last
        value = @value(obj, current)
        _.includes value.toLowerCase(), pattern.toLowerCase() if value
      else
        value = @value(obj, current)
        @deepMatch(segments.join(@separator), pattern, value) if value

    call: ->
      _.compact(_.map @phrases, (value, key) =>
        if _.isEmpty value
          true
        else
          @deepMatch(key, value)).length is _.size @phrases

    value: (obj, current) ->
      if _.isFunction(obj[current])
        obj[current]()
      else
        obj[current]


class TableFilter extends Filter
  constructor: (TableFilterItem) ->
    return (list, phrases) ->
      _.filter list, (item) -> new TableFilterItem(item, phrases or {}).call()
