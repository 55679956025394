class TableSortHeaderCtrl extends Controller
  constructor: (@$scope, @Constants) ->
    @defaultSize = 1
    @field = @$scope.field
    @scope = @$scope.$parent.ctrl
    @tableOptions = @$scope.$parent.ctrl.tableOptions
    @tableOptions.sortByField = @field if @$scope.defaultSort
    @size = @$scope.size || @defaultSize
    @fieldName =
      if @$scope.translationScope
        "#{@$scope.translationScope}.#{_.last @field.split('.')}"
      else if @tableOptions.translationScope
        "#{@tableOptions.translationScope}.#{_.last @field.split('.')}"
      else
        @field

    @isSortEnabled = not @$scope.disableSort
    @divClass = "medium-#{@size}"

  iconClass: ->
    switch
      when not @isSortEnabled then ''
      when @tableOptions.isAscFor(@field)
        @Constants.icons.SORT_TEXT_ASC_ICON
      when @tableOptions.isDescFor(@field)
        @Constants.icons.SORT_TEXT_DESC_ICON
      else @Constants.icons.SORT_TEXT_ASC_ICON

  sort: =>
    @isSortEnabled and
      @tableOptions.sortBy @field

  isSortIconVisible: ->
    @isSortEnabled and
      @tableOptions.sortByField != @field

  isCaretUpIconVisible: ->
    @isSortEnabled and
      @tableOptions.sortByField == @field and
        @tableOptions.sortDirectionAsc

  isCaretDownIconVisible: ->
    @isSortEnabled and
      @tableOptions.sortByField == @field and
        not @tableOptions.sortDirectionAsc
