angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
    .state 'objectives_overview',
      parent: 'protected'
      url: '/objectives/overview'
      template: "<ui-view/>"
      resolve:
        response: (Objective) ->
          Objective.getOverview()
        # structures: (Structure, currentPerson) ->
        #   Structure.structureWithDecendants(currentPerson.current_company.id, {per_page: 9999}).then (response) ->
        #     _.map response.data, (structure) => new Structure(structure)
        # jobFamilies: (ProfessionalGroup, currentPerson) ->
        #   ProfessionalGroup.allForCompany(currentPerson.current_company.id)
        people: (Employment, currentPerson) ->
          Employment.shortEmploymentsForStructure(currentPerson.currentEmployment.entity.id)
      views:
        '@':
          controller: "ObjectivesOverviewCtrl"
          controllerAs: "ctrl"
          templateUrl: "objectives/overview/objectivesOverview.html"

class ObjectivesOverviewCtrl extends Controller
  constructor: (response, people, @Objective, @$translate) ->
    @resetChartData()
    @options = {}
    @people = _.sortBy(people, 'name')

    @addDateRangePickerOptions()
    @addObjectivesToList(response.data)

  clearFilters: () =>
    @options['people[]'] = []
    @options['structures[]'] = []
    @options['job_families[]'] = []
    @addDateRangePickerOptions()
    @fetchFilteredObjectives()

  resetChartData: () =>
    @chartData = {
      months: []
      kpis: []
      goals: []
      title: []
    }

  addDateRangePickerOptions: =>
    @period = {
      startDate: moment().startOf('year')
      endDate: moment().endOf('year')
    }

    @rangeOpts = {
      locale: {
        format: "DD.MM.YYYY"
        firstDay: 1
      }
      startDate: @period.startDate
      endDate: @period.endDate
      opens: "left"
      ranges: {
        'This Week': [moment().startOf('week'), moment().endOf('week')]
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')]
        'This Month': [moment().startOf('month'), moment().endOf('month')]
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        'This Year': [moment().startOf('year'), moment().endOf('year')]
        'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
      }
      autoApply: true
      eventHandlers: {
        'apply.daterangepicker': (ev, picker) =>
          angular.element(document.querySelectorAll('#period')).html(ev.model.startDate.format('DD.MM.YYYY') + ' - ' + ev.model.endDate.format('DD.MM.YYYY'))
          @fetchFilteredObjectives()
      }
    }

  addObjectivesToList: (data) =>
    @chartData = {
      kpis: data.kpis
      goals: _.map data.goals, (goal) -> ObjectivesOverviewCtrl.sanitizeGoalResults(goal)
      months: _.map data.months, (month) -> moment(month)
      title: ''
    }

  fetchFilteredObjectives: () ->
    if @period
      @options.start_date = moment(@period.startDate).format('YYYY-MM-DD')
      @options.end_date = moment(@period.endDate).format('YYYY-MM-DD')
    @fetchObjectives()

  fetchObjectives: () =>
    @Objective.getOverview(@options).then (response) =>
      @addObjectivesToList(response.data)

  @sanitizeGoalResults: (goal) =>
    goal.results = _.reduce(goal.results, ((r, result) ->
      r[result.start_date] = { date: result.start_date, target: parseFloat(result.target), result: parseFloat(result.result) }
      r
    ), {})
    goal
