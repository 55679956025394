angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'feedback_categories',
        parent: 'protected'
        url: "/feedback_categories"
        template: "<ui-view/>"
        resolve:
          categories: (PersonalFeedbackCategoryRepository, PersonalFeedbackCategory) ->
            PersonalFeedbackCategoryRepository.getAll().then (response) =>
              _.map(response.data, (category) => new PersonalFeedbackCategory(category))
          currentDefault: (categories) ->
            _.find(categories, (cat) => cat.is_default)
        views:
          '@':
            controller: "PersonalFeedbackCategoriesCtrl"
            controllerAs: "ctrl"
            templateUrl: "feedback/personal_feedback/personalFeedbackCategories.html"

class PersonalFeedbackCategoriesCtrl extends Controller
  constructor: (@$timeout, @$scope, @$rootScope, @PersonalFeedbackCategoryRepository, @PersonalFeedbackCategory, TableOptions, @$modal, @toastr, @$translate, @categories, @currentDefault) ->
    @tableOptions = new TableOptions(translationScope: 'feedbacks');
    @entity = @$rootScope.currentPerson.currentCompany
    @lastSavedDefaultCategory = undefined

  add: () ->
    newCategory = new @PersonalFeedbackCategory()
    newCategory.is_visible = true
    @categories.unshift(newCategory)

  save: (category) ->
    return if category.name == undefined
    if(!category.isSaved())
      @PersonalFeedbackCategoryRepository.create(category).then (response) =>
        category.id = response.data.id
        @toastr.success @$translate.instant('toastr.actions.updated', { item: category.name })
    else
      @PersonalFeedbackCategoryRepository.update(category).then (response) =>
        @toastr.success @$translate.instant('toastr.actions.updated', { item: category.name })


  delete: (index) ->
    category = @categories[index];
    if(!category.isSaved())
      if(category.is_default && @lastSavedDefaultCategory != undefined)
        @_setDefaultCategory(@lastSavedDefaultCategory)
      @categories.splice(index, 1);
    else
      @PersonalFeedbackCategoryRepository.delete(category).then (response) =>
        if(response.status >= 200 && response.status < 300)
          @categories.splice(index, 1);
          @toastr.success @$translate.instant('toastr.actions.deleted', { item: category.name })
          if(category.is_default)
            @_pickNewDefaultCategory()

  changeVisibility: (category) ->
    if(category.is_default && !category.is_visible)
      newDefault = _.find(@categories, (cat) => cat.is_visible)
      if(newDefault == undefined)
        category.is_visible = true
      else
        newDefault.is_default = true
        @changeDefault(newDefault)
    @save(category)

  changeDefault: (category) ->
    if(category.is_default)
      if(!category.isSaved() && @currentDefault.isSaved())
        @lastSavedDefaultCategory = @currentDefault
      @_setDefaultCategory(category)
      @save(category)
    else
      @currentDefault.is_default = true

  _setDefaultCategory: (category) ->
    @currentDefault.is_default = false if @currentDefault != undefined
    if(!category.is_visible)
      category.is_visible = true
    category.is_default = true
    @currentDefault = category

  _pickNewDefaultCategory: () ->
    newDefault = _.find(@categories, (category) => category.isSaved() && category.is_visible)
    newDefault.is_default = true
    @changeDefault(newDefault)

  openDeleteModal: (index) ->
    category = @categories[index]
    if category.name == undefined
      @delete index
    else
      @$modal.open
        windowClass: "small"
        templateUrl: 'feedback/personal_feedback/deleteCategoryModal.html'
        controller: "DeleteCategoryModalCtrl"
        controllerAs: 'ctrl'
        resolve:
          category: -> category
      .result.then =>
        @delete index
