class Employment extends Service
  constructor: ($filter, $translate, Restangular, Base, Person) ->
    return class Employment extends Base
      constructor: (json) ->
        angular.extend(@, json)
        @employed_at     = $filter('date')(json.employed_at, "d.MM.yyyy")    if @employed_at
        @employed_until  = $filter('date')(json.employed_until, "d.MM.yyyy") if @employed_until
        @person          = new Person(json.person) if @person
        @isActive        = false
        @contacts        = @person.contacts if @person
        @employment_name = @employment_name || $translate.instant('general.not_set')

      isTerminated: -> @status == 'terminated'
      isActivated:  -> @status == 'activated'

      @one: (employment_id) ->
        Restangular.one('employments', employment_id).get().then (response) ->
          new Employment(response.data)

      @get: (structure_id, employment_id) ->
        Restangular.one('structures', structure_id).one('employments', employment_id)
          .get().then (data) -> new Employment(data.data)

      @shortEmploymentsForStructure: (structure_id, family_ids = []) ->
        Restangular.one('structures', structure_id).all('short_employments').getList({'family_ids[]': family_ids}).then (response) ->
          _.map response.data, (vacancy) -> new Employment(vacancy)

      @shortEmploymentsForSorainen: (structure_id) ->
        Restangular.one('structures', structure_id).all('short_employments').one('getall').getList().then (response) ->
          _.map response.data, (vacancy) -> new Employment(vacancy)

      @shortEmploymentsForStructureMultiple: (structure_ids, family_ids = []) ->
        promises = []
        for id in structure_ids
          promises.push(
            @shortEmploymentsForStructure(id, family_ids)
          )
        # Since Promise.all(promises) is not IE compatible, we use this.
        $.when.apply($, promises).then((data) ->
          result = []
          for subarray in data
            result = result.concat(subarray)
          return _.uniq(result, 'id')
        )

      @oneShortForStructure: (structure_id, employment_id) ->
        Restangular.one('structures', structure_id).one('short_employments', employment_id).get().then (response) ->
            new Employment(response.data)

      @create: (vacancy_id, options = {}) ->
        Restangular.one('vacancies', vacancy_id).all('employments').post(options)

      @goals: (options = {}) ->
        Restangular.all('employments').all('goals').getList(options).then (response) -> response

      @trainings: (options = {}) ->
        Restangular.all('employments').all('trainings').getList(options).then (response) -> response
