angular.module("Upsteem").factory "TranslationLoader", ($http, $q, Constants) ->
  (options) ->
    deferred = $q.defer()

    $http(
      method: "GET"
      url: Constants.translations[options.key]
    ).then(
      (response) -> deferred.resolve response.data,
      () -> deferred.reject options.key
    )

    deferred.promise
