class Person extends Service
  constructor: (Base, Restangular, Permissions) -> return class Person extends Base
    constructor: (json) ->
      angular.extend @, json
      @permissions = new Permissions(@permissions)
      @isActive = false

    @get: (person_id) ->
      Restangular.one('people', person_id).get()

    @allForStructure: (structure_id) ->
      Restangular.one('structures', structure_id).all('people').getList().then (response) =>
        _.map response.data, (person) => new Person(person)

    @confirmUser: (confirmationToken) ->
      Restangular.all('people').one('confirmations', confirmationToken).remove().then (response) ->
        response.data

    @initiatePasswordReset: (form) ->
      Restangular.one('people').all("passwords").post(form).then (response) ->
        response.data

    @resetPassword: (form) ->
      Restangular.all('people').all('passwords').customPUT(form).then (response) ->
        response.data

    @resendConfirmation: (email) ->
      Restangular.all('people').all('confirmations').post(email: email).then (response) ->
        response.data

    @checkToken: (resetToken) ->
      Restangular.all('people').one('passwords', resetToken).get().then (response) ->
        response.data

    @login: (form) ->
      Restangular.all('sessions').post(form).then (response) =>
        response.data

    @logout: ->
      Restangular.all('sessions').remove().then (response) =>
        response.data

    @checkSession: ->
      Restangular.all('sessions').customGET().then (response) ->
        response.data

    isEstonian:           -> @profile.country == "EE"
    withoutEmptyContacts: -> _.pick(@contacts, _.isString)
    hasAnyContactInfo:    -> !_.isEmpty @withoutEmptyContacts()

    noExtraContactInfo: ->
      extraInfo = {}
      if @contacts.work_phone
        extraInfo.work_phone = @contacts.work_phone
      if @contacts.internet
        extraInfo.internet = @contacts.internet
      if @contacts.location
        extraInfo.location = @contacts.location
      if @contacts.room_number
        extraInfo.room_number = @contacts.room_number
      _.isEmpty extraInfo
