class SampleKanban extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      templateUrl: 'directives/sampleKanban.html'
      controller: 'SampleKanbanCtrl',
      controllerAs: 'kanbanCtrl'
    }

class SampleKanbanCtrl extends Controller
  constructor: (@$modal) ->

  openChartModal: ->
     @$modal.open
       templateUrl: 'directives/sampleKanbanModal.html'
       controller: "SampleKanbanCtrl"
       controllerAs: 'kanbanCtrl'
