class Project extends Service
  constructor: (Base, $translate) ->
    return class Project extends Base
      constructor: (json) ->
        angular.extend(@, json)
        @date_from = new Date(@details.date_from)
        @date_to = new Date(@details.date_to)

      status: () ->
        current_date = new Date()
        if current_date > @date_from && current_date < @date_to
          $translate.instant("general.status.ongoing")
        else if current_date < @date_from
          $translate.instant("general.status.upcoming")
        else
          $translate.instant("general.status.completed")
