class EmailTemplateGroup extends Service
  constructor: (Base, $translate) -> return class EmailTemplateGroup extends Base
    constructor: (json) ->
      angular.extend(@, json)
      @defaultNameKey = 'emails.template_groups.' + @group_type

    systemWide: () ->
      @company_id == null

    nameWithSourceTemplateTitle: ->
      if @source_template
        "#{@name} (#{@source_template.title})"
      else
        @name
