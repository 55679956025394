class PersonSearch extends Directive
  constructor: () ->
    return {
      restrict: 'E'
      scope: {
        create: "="
        showLegend: "="
        showLabels: "="
      }
      templateUrl:  'directives/personSearch.html'
      controller:   'PersonSearchDirectiveCtrl'
      controllerAs: 'ctrl'
    }

class PersonSearchService extends Service
  constructor: (@$q, @Restangular, @PersonRepository) ->
    @selectedPerson = undefined
    @newPerson      = undefined
    @newPersonForm  = undefined

  searchAgain: ->
    @selectedPerson = undefined
    @newPerson      = undefined
    @filterPhrases  = undefined

  loadCandidatesFor: (structure) =>
    @PersonRepository.getFromStructure(structure).then (response) =>
      @availablePeople = response.data

  getPerson: ->
    if @selectedPerson
      @$q (resolve) => resolve @selectedPerson
    else @createPerson()

  isPersonPresent: ->
    @selectedPerson? || @isNewPersonValid()

  isNewPersonValid: ->
    @newPersonForm && @newPersonForm.$valid

  createPerson: ->
    if @newPersonForm.$valid
      @Restangular.all('people').all('invitations').post(@newPerson).then (response) =>
        @selectedPerson = response.data
    else @$q (_, reject) ->
      reject @newPerson

class PersonSearchDirectiveCtrl extends Controller
  constructor: (@$scope, @$rootScope, PersonSearchService) ->
    @service = PersonSearchService
    @service.loadCandidatesFor(@$rootScope.currentPerson.currentCompany)
    @$scope.$watch 'ctrl.service.newPerson',
      ((newPerson) => @service.newPersonForm = @$scope.newPerson), true

  selectPerson: (person)->
    @service.filterPhrases.name = ""
    @service.selectedPerson = person
