class PermissionService extends Service
  constructor: (Restangular, Permissions, Department) -> return class PermissionService
    constructor: (@currentPerson) ->
      @permissions_refs = {}
      @permissions_refs[@currentPerson.currentCompany.id] = @currentPerson.permissions
      descendants_ids = @currentPerson.currentCompany.descendants_ids
      if descendants_ids != undefined
        for department_id in descendants_ids
          @permissions_refs[department_id] = @currentPerson.permissions

      for department in _.values(@currentPerson.affiliatable_departments)
        department_permissions = @currentPerson.affiliatable_departments[department.id].permissions
        for id in department.descendants_ids.concat([department.id])
          if @permissions_refs[id]
            @permissions_refs[id] = @permissions_refs[id].merge(department_permissions)
          else
            @permissions_refs[id] = department_permissions

    getEntityPermissions: (id) ->
      @permissions_refs[id]

    # Returns uppermost entity/entities where user has requested permission.
    entitiesWithPermission: (permission) ->
      if @check([@currentPerson.currentCompany.id], [permission])
        [@currentPerson.currentCompany]
      else
        _.filter(_.values(@currentPerson.affiliatable_departments), (dep) => @check([dep.id], [permission]))

    check: (entity_ids, items) ->
      for id in entity_ids
        permissions = @getEntityPermissions(id)
        if permissions && permissions.any(items)
          return true
      return false


    one_with_descendants: (item) ->
      @entitiesWithPermission(item).length ? true : false

    any_with_descendants: (items) ->
      for item in items
        if @one_with_descendants(item)
          return true
      return false

    @fetch: (currentPerson) ->
      Restangular.one('current_person').one('permissions').get().then (response) ->
        departments = {}
        for department in response.data
          departments[department.id] = new Department(department)
        currentPerson.affiliatable_departments = departments
        new PermissionService(currentPerson)
