class CurrentPerson extends Service
  constructor: (Base, Company, Structure, Person, Vacancy, Employment, Permissions, Restangular, $translate, amMoment) -> return class CurrentPerson extends Base
    constructor: (json) ->
      angular.extend @, json

      @currentEmployment = new Employment(@current_employment) if @current_employment
      @permissions       = new Permissions(@permissions)
      @currentCompany    = new Company(@current_company)
      @vacancies         = _.map json.vacancies, (vacancy) -> new Vacancy(vacancy)
      @employments       = _.map @vacancies, (vacancy) -> new Employment(vacancy.employment)

      amMoment.changeLocale($translate.use())

    @fetch: ->
      Restangular.one('current_person').get().then (response) ->
        new CurrentPerson(response.data)

    employmentsForCurrentCompany: =>
      _.filter @vacancies, (vacancy) =>
        entityPathIds = _.map vacancy.entity.path.split('.'), (id) -> parseInt(id)
        _.includes entityPathIds, @currentCompany.id

    department_url: (department) ->
      "structures.people({structure_type: '#{department.type.tableize()}', structure_id: #{department.id}})"

    selectableVacancies: =>
      _.filter @vacancies, (vacancy) =>
        vacancy.employment.employer.id == @currentCompany.id

    setCurrentEmployment: (vacancy_id) =>
      if @currentCompany.vacancies.length > 0
        vacancy =
          if vacancy_id
             _.find(@currentCompany.vacancies, (vacancy) => vacancy.id == vacancy_id)
          else
            @currentCompany.vacancies[0]

        @currentEmployment = vacancy.employment
      else
        @currentEmployment = null

    setCurrentCompany: (company, vacancy_id) =>
      @currentCompany = company
      @setCurrentEmployment(vacancy_id)
