class Activity extends Service
  constructor: () ->
    return class Activity
      constructor: () ->
        @statusType = new Enum(['CLOSED', 'OPEN', 'IN_PROGRESS'])
        @priorityTypes = new Enum(['LOW', 'MEIDUM', 'HIGH'])
        @applyNewTypes()
        @priority(@priorityTypes.LOW)
        @description = ""
        @expectedResult = ""
        @planIdList = []
        @ownerList = []
        @followerList = []
        @costRows = []
        @startDate
        @endDate
        @title
        @level
        @createdBy
        @status
        @type
        @activityId
        @planId

      progress: (@progress) -> @

      priority: (@priority) -> @

      status: (@status) -> @

      statusOpen: () -> @status(@statusType.OPEN)

      statusClosed: () -> @status(@statusType.CLOSED)

      statusInProgress: () -> @status(@statusType.IN_PROGRESS)

      applyNewTypes: () -> @progress(0).statusOpen()

      getAPIObject: () ->
        {
          id: @activityId,
          status: _.pluck(@status, 'id')[0], # status can have only one element
          item_type: _.pluck(@type, 'id')[0], # activity can have only one element
          @priority,
          @progress,
          planning_categories: @planIdList,
          responsible_people: _.pluck(@ownerList, 'id'),
          co_responsible_people: _.pluck(@coOwnerList, 'id'),
          followers: @followerList,
          cost_rows: angular.toJson(@costRows),
          @description,
          expected_result: @expectedResult,
          start_date: @startDate,
          end_date: @endDate,
          @title,
          @level,
          owner_employment_id: @createdBy
      }

      responseToModel: (response) ->
        @activityId = response.id
        @status = response.status
        @type = response.item_type
        @priority = response.priority
        @progress = response.progress
        @planIdList = response.planning_categories
        @ownerList = _.pluck(response.responsible_people, 'affiliation_id')
        @coOwnerList = _.pluck(response.co_responsible_people, 'affiliation_id')
        @followerList = response.followers
        @costRows = response.cost_rows
        @description = response.description
        @expectedResult = response.expected_result
        @startDate = response.start_date
        @endDate = response.end_date
        @title = response.title
        @level = response.level
        @createdBy = response.owner_employment_id
        return @
