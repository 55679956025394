class Selectpicker extends Directive
  constructor: ($timeout) ->
    return {
      restrict: 'A'
      scope: {
        clear: '='
        items: '='
      }
      link: (scope, elem, attrs) ->
        $timeout (->
          $el = $(elem)

          width = '100%'
          style = 'select-dropdown'
          size = 4
          liveSearch = true

          if $el.attr("width")
            width = $el.attr("width")
          if $el.attr("style")
            style = $el.attr("style")
          if $el.attr("size")
            size = $el.attr("size")
          if $el.attr("no-search")
            liveSearch = false

          $el.selectpicker(size: size, selectedTextFormat: 'count', style: style, actionsBox: true, width: width, liveSearch: liveSearch)

          scope.$watch 'items', (items) ->
            if items
              $el.empty()
              _.each items, (item) ->
                option = $('<option></option>').attr('value', item.value).text(item.text)
                $el.append(option)
              $el.selectpicker('render')
              $el.selectpicker('refresh')

          scope.$watch 'clear', ( ->
            $el.val('').selectpicker('refresh')
          )

          if attrs.ngOptions and RegExp(' in ').test(attrs.ngOptions)
            scope.$watch attrs.ngOptions.split(' in ')[1], (->
                scope.$applyAsync ->
                  $el.selectpicker 'refresh'
                  return
                return
              ), true
          return
        ), 0
        return
    }
