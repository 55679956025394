class EmailEvent extends Service
  constructor: (Base) -> return class EmailEvent extends Base
    constructor: (json) ->
      angular.extend(@, json)

      @iconColor = switch @status
        when "dispatched" then "grey"
        when "sent" then "blue"
        when "open" then "limegreen"
        when "click" then "green"
        when "bounce" then "red"
        when "blocked" then "black"
        when "spam" then "orange"
        when "unsub" then "aqua"
        else "black"
