class LargePersonProfile extends Directive
  constructor: ->
    return {
      restrict: 'E'
      replace: true
      templateUrl: 'directives/largePersonProfile.html'
      controller: 'LargePersonProfileCtrl'
      controllerAs: 'largePersonProfileCtrl'
      scope:
        size: '@'
        person: '='
    }

class LargePersonProfileCtrl extends Controller
  constructor: ($scope) ->
