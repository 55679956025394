angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'training.show',
        url: '/show'
        views:
          '@':
            controller: "TrainingCtrl"
            controllerAs: "ctrl"
            templateUrl: "trainings/show/training.html"

class TrainingCtrl extends Controller
  constructor: (@$state, @$modal, @$translate, @toastr, @training, @currentPerson, @Training, TableOptions, @Enrollment) ->
    @tableOptions   = new TableOptions(translationScope: 'employment')

    @availableTextPhrases = [
      {
        phrase: 'participant.name',
        translate: 'trainings.participant_name'
      },
      {
        phrase: 'employment_name',
        translate: 'vacancy.role_name'
      }
    ]

    @currentPersonEnrollment = @training.enrollmentForPerson(@currentPerson.currentEmployment.id)

    @backState = 'employees_trainings({entity_id: ' + @currentPerson.currentEmployment.entity.id + '})'

  isCurrentPersonEnrollmentPresent: =>
    !_.isUndefined(@currentPersonEnrollment)

  isEnrollmentForCurrentPerson: (enrollment) => enrollment == @currentPersonEnrollment

  createPlannedTraining: =>
    @training.type = 'planned'
    @training.parent_training_id = @training.id

    @Training.create(@training).then (training) =>
      @$state.go('training.edit', { training_id: training.id }, {reload: true})

  classForParticipation: =>
    if @currentPersonEnrollment
      if !@currentPersonEnrollment.hasDecision() || @currentPersonEnrollment.isAccepted()
        return 'green'
      else
        return 'red'

  removeParticipation: ->
    @$modal.open
      windowClass: "small"
      templateUrl: 'trainings/enrollments/deleteEnrollmentModal.html'
      controller: "DeleteEnrollmentModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        enrollment:    => @currentPersonEnrollment
        training_name: => @training.name
    .result.then (enrollment) =>
      @toastr.success @$translate.instant('trainings.participation_removed')
      _.remove @training.enrollments, enrollment
      @currentPersonEnrollment = undefined

  addEnrollment: (employment) =>
    @training.addEnrollment(employment).then (response) =>
      newEnrollment = new @Enrollment(response.data[0])
      @training.enrollments.push(newEnrollment)
      @currentPersonEnrollment = newEnrollment

  openTrainingDeleteModal: =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'trainings/delete/deleteTrainingModal.html'
      controller: "DeleteTrainingModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        training: => @training
    .result.then =>
      @toastr.success @$translate.instant('trainings.training_deleted')
      @$state.go('trainings', { reload: true })
