class CategoryRowCtrl extends Controller
  constructor: (@$scope, @$timeout) ->
    @category = @$scope.$parent.category
    @timeout = undefined;
    @$scope.$watch '$parent.category.name', ((oldVal, newVal) => @formChanged(oldVal, newVal))
    @$scope.$watch '$parent.category.description', ((oldVal, newVal) => @formChanged(oldVal, newVal))


  formChanged: (oldVal, newVal) =>
    if(oldVal != newVal && @category.name != undefined)
      if(@timeout)
        @$timeout.cancel(@timeout)
        @timeout = undefined
      @timeout = @$timeout(@save, 500)

  save: () =>
    @$scope.$parent.ctrl.save(@category)
