class TrainingCategory extends Service
  constructor: (Base, Restangular) -> return class TrainingCategory extends Base
    constructor: (json) ->
      angular.extend @, json

    @all: ->
      Restangular.all("trainings").all("categories").getList().then (result) ->
        _.map result.data, (category) -> new TrainingCategory(category)

    @create: (name) ->
      Restangular.all("trainings").all('categories').post({name: name}).then (response) -> response
