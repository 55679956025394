class ProjectRepository extends Service
  constructor: (@Restangular, @ProjectService, @Survey, @Structure, @SurveyResultUtils) ->

  getForPerson: (person_id, options = {}) ->
    @Restangular.one('people', person_id).all('projects').getList(options)

  getSurveyProjectsOverview: (options = {}) ->
    @Restangular.one('surveys/projects').get(options).then (response) =>
      response.data = @ProjectService.wrapProjects response.data
      response

  getSurveyProject: (projectId) ->
    @Restangular.one('surveys/project', projectId).get().then (response) =>
      @ProjectService.wrapProject(response.data)

  getSurveyProjectSurveys: (projectId, options = {}) ->
    @Restangular.one('surveys/project', projectId).one('surveys').get(options).then (response) =>
      response.data = _.map response.data, (json) => new @Survey(json)
      response

  getSurveyProjectGroups: (projectId, options = {}) ->
    @Restangular.one('surveys/project', projectId).one('groups').get(options).then (response) =>
      data = response.data
      data.entities = _.map data.entities, (entity) => new @Structure(entity)
      @SurveyResultUtils.extendData data
      response

  createProject: (entityId, params) ->
    @Restangular.all('projects').all(entityId).post(params).then (response) =>
      response.data
