class PersonalFeedbackRepository extends Service
  constructor: (@Restangular, @PersonalFeedback, @Paging) ->
    @options = {}

  create: (feedback) ->
    @Restangular.all('personal_feedbacks').post(feedback).then (response) ->
      response

  getAll: (options = {}) ->
    @Restangular.all('personal_feedbacks').getList(options)

  getAllForPerson: (person, options = {}) ->
    @Restangular.one('people', person).all('personal_feedbacks').getList(options)

  getStatsForPerson: (person, options = {}) ->
    @Restangular.one('people', person).all('personal_feedbacks').one('stats').get(options)

  areas: () ->
    _.map Constants.personal_feedback_areas, (area) -> area

  getWithFilter: (options) ->
    @Restangular.all('personal_feedbacks').getList(options).then (response) ->
      response

  initialize: (paging) ->
    @feedbacks = []
    @fetchFeedbacksForPage(paging)

  addFeedbacksToList: (feedbacks, paging) =>
    @feedbacks[paging.iterator(paging.page)] = @PersonalFeedback.mapPersonalFeedbacks(feedbacks)

  getForPage: (paging) ->
    @feedbacks[paging.iterator()]

  fetchFeedbacksForPage: (paging, forceDownload=false) ->
    if _.isUndefined(@feedbacks[paging.iterator()]) || forceDownload
      @options.page = paging.page
      @fetchPersonalFeedbacks()

  fetchPersonalFeedbacks: () =>
    @getAll(@options).then (response) =>
      paging = new @Paging(response)
      @addFeedbacksToList(response.data, paging)
      paging
