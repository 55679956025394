class PriorityList extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '='
      },
      controller: 'PriorityListCtrl',
      controllerAs: 'ctrl'
      templateUrl: 'directives/survey_result/priority_list.html'
    }

class PriorityListCtrl extends Controller
  constructor: (@$scope, @$rootScope) ->
    @index = @$scope.index
    @data = @$scope.data
    @topList = @_topList(@data.answers)
    @groupedAnswers = @_groupedAnswers(@data.answers)

  presentChoice: (choices) ->
    choices.join(' > ')

  _groupedAnswers: (answers) ->
    _.groupBy(answers, (a) ->
      if a.content.rating < 0 then 'dont-know' else a.content.rating + 1
    )

  _topList: (answers) ->
    options = _.uniq(_.flattenDeep(_.map(answers, (a) -> a.content.choices)))
    results = _.reduce(options, ((result, option) ->
      result[option] = { option, rank: 0 }
      result
    ), {})
    _.each(answers, (a) ->
      _.each(_(a.content.choices).reverse().value(), (choice, index) ->
        results[choice].rank += (index + 1)
      )
    )
    _.map(_.sortBy(results, 'rank'), _.values)

