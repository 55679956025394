angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $urlRouterProvider.otherwise ->
      window.location.pathname = '/dashboard'

    $stateProvider
      .state 'root',
        resolve:
          redirect: ($location) ->
            window.location = "/dashboard"

      .state 'protected',
        abstract: true
        resolve:
          config: (ConfigService) ->
            ConfigService.getConfig()
          currentCompany: (CurrentCompanyService, $location) ->
            CurrentCompanyService.setCurrentCompany(null)
          currentPerson: (CurrentPerson, currentCompany) ->
            CurrentPerson.fetch()
          goalsAppAccessManager: (GoalsAppAccessManager, config, currentPerson) ->
            new GoalsAppAccessManager(config, currentPerson)
          permissionService: (PermissionService, currentPerson) ->
            PermissionService.fetch(currentPerson)
          protectedScope: ($rootScope, $translate, currentPerson, OldApp, config, permissionService) ->
            $rootScope.currentPerson = currentPerson
            $rootScope.oldApp = OldApp
            $rootScope.config = config
            $rootScope.permissionService = permissionService
            $translate.use currentPerson.profile_language
            $rootScope.isInfoDropdownVisible = false
            $rootScope.isLanguageDropdownVisible = false
            $rootScope.isSmallTopDropdownVisible = false

        views:
          'topbar@':
            templateUrl: 'topbar.html'
          'sidebar@':
            templateUrl: 'sidebar.html'

      .state 'unprotected',
        abstract: true
        views:
          'topbar@':
            templateUrl: 'topbar.html'

      .state 'token',
        abstract: true
        views:
          'topbar@':
            templateUrl: 'topbar.html'
