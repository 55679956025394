class LanguageService extends Service
  constructor: (@Constants, @$translate, @$window) ->
    @selectedLanguage = undefined

    @languages = [
      {
        short: @Constants.languages.short.et,
        long: @Constants.languages.long.et
      },
      {
        short: @Constants.languages.short.en,
        long: @Constants.languages.long.en
      },
      {
        short: @Constants.languages.short.ru,
        long: @Constants.languages.long.ru
      },
      {
        short: @Constants.languages.short.fi,
        long: @Constants.languages.long.fi
      },
      {
        short: @Constants.languages.short.pt,
        long: @Constants.languages.long.pt
      },
      {
        short: @Constants.languages.short.lv,
        long: @Constants.languages.long.lv
      },
      {
        short: @Constants.languages.short.lt,
        long: @Constants.languages.long.lt
      }
    ]

  changeLanguageByShort: (shortLanguage) =>
    @changeLanguage(@_findLanguageByShort(shortLanguage))

  changeLanguage: (language) =>
    @selectedLanguage = language
    @$translate.use language.short

  isLanguageSelected: (language) -> language == @selectedLanguage

  userLanguage: ->
    if window.navigator.language || window.navigator.userLanguage
      (window.navigator.language || window.navigator.userLanguage).slice(0, 2)
    else
      @Constants.languages.short.en

  setUserLanguage: ->
    @selectedLanguage = @_findLanguageByShort(@userLanguage())

  _findLanguageByShort: (shortLanguage) ->
    _.find @languages, (language) => language.short == shortLanguage
