class AddResponder extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      controller: 'AddResponderDirectiveCtrl'
      controllerAs: 'ctrl',
      templateUrl: 'directives/survey_responders/addResponder.html',
      scope: {
        role: "=",
        survey: "=",
        responders: "="
      }
    }

class AddResponderDirectiveCtrl extends Controller
  constructor: (@$scope, @$modal) ->

  openModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: "small"
      templateUrl: "directives/survey_responders/addResponderModal.html"
      controller: "AddResponderModalCtrl"
      controllerAs: 'ctrl'
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> @$scope.modalInstance = undefined
