class MyActivityListService extends Service
  constructor: (@MyActivityListRepository, @ActivityService, @SinglePlanService) ->

  getAllPlans: (person_id) ->
    @MyActivityListRepository.getPlanningCategoriesByPerson(person_id).then (response) =>
      _.map(response.data, (p) => @SinglePlanService.createSinglePlanInstance().responseToModel(p))

  getPlanActivities: (planId) ->
    @MyActivityListRepository.getPlanActivitiesByPlanId(planId).then (response) =>
      _.map(response.data, (p) => @ActivityService.newActivityInstance().responseToModel(p))

  getFilters: () ->
    {
      statusList: @getStatus()
      departmentsList: @getDepartments()
      activityTypesList: @getActivityTypes()
      planTypesList: @getPlanTypes()
      planNamesList: @getPlanNames()
      peopleNamesList: @getPeopleNames()
    }

  getStatus: () -> [{name: "Planeerimisel"}, {name: "Kehtiv"}, {name: "Arhiveeritud"}]
  getDepartments: () -> [{name: "Osakond 1"}, {name: "Osakond 2"}, {name: "Osakond 3"}]
  getActivityTypes: () -> [{name: "Muu tegevus(rutiin)"}, {name: "Projektid"}, {name: "Hanked (ja muud tegevused)"}]
  getPlanTypes: () -> [{name: "Eelarveplaan"}, {name: "Strateegiaplaan"}, {name: "Riskiplaan"}, {name: "Tööplaan"}]
  getPlanNames: () -> [{name: "Ministeeriumi Hankeplaan 2018"}, {name: "Õigusloomeplaan 2018"}]
  getPeopleNames: () -> [{name: "Karl Laas"}, {name: "Jüri Siilivask"}]
