class Training extends Service
  constructor: (Base, Restangular, $translate, $state, TrainingCategory, Constants, toastr, $filter, Permissions, Enrollment) -> return class Training extends Base
    constructor: (json) ->
      angular.extend @, json

      @initiator       = @proposer || @requester || @planner
      @permissions     = new Permissions(@permissions)
      @translated_type = 'trainings.type.' + inflection.underscore(@type)

      @planned_trainings = @mapped_planned_trainings if @mapped_planned_trainings
      @parent_training   = @mapped_parent_training if @mapped_parent_training

      @enrollments = _.map @enrollments, (enrollment) =>
        Restangular.restangularizeElement(null, new Enrollment(enrollment), 'enrollments')

    @trainingTypes: -> ['proposed', 'requested', 'planned']
    @mapIds: (collection) => _.map collection, (element) -> element.id
    @all: (options = {}) ->
      options.start_date = $filter('date')(options.start_date, "yyyy-MM-dd") if options.start_date
      options.end_date   = $filter('date')(options.end_date, "yyyy-MM-dd")   if options.end_date

      @service().getList(options).then (response) -> response

    @one: (id) =>
      training = Restangular.one("trainings", id).get().then (response) =>
        training = new Training(response.data)
        training.job_family_ids = Training.mapIds(training.job_families)
        training

    @create: (training) =>
      training.job_family_ids = Training.mapIds(training.job_families)
      training.category_ids   = Training.mapIds(training.categories)
      @service().post(training).then (resonse) ->
        toastr.success $translate.instant('trainings.training_added')
        Restangular.restangularizeElement(null, new Training(resonse.data), 'trainings')

    update: =>
      @job_family_ids = Training.mapIds(@job_families)
      @category_ids   = Training.mapIds(@categories)
      @.customPUT(@).then (training) =>
        toastr.success $translate.instant('trainings.training_updated')
        @updateFields(training.data)
        training.data

    delete: ->
      @.remove().then (training) ->
        if $state.current.name != "trainings"
          $state.go("trainings")
        toastr.success $translate.instant('trainings.training_deleted')

    updateFields: (training) ->
      @name         = training.name
      @description  = training.description
      @categories   = training.categories
      @job_families = training.job_families

      @manager_accepting_required = training.manager_accepting_required

      @start_date = $filter('date')(training.start_date, 'd.MM.yyyy') if training.start_date
      @end_date   = $filter('date')(training.end_date, 'd.MM.yyyy') if training.end_date

    sendReminder: ->
      Restangular.all('reminders').all('trainings').post({training_id: @id}).then ->
        toastr.success $translate.instant('general.reminder_sent')

    participantsNames: ->
      _.map(@enrollments, (enrollment) -> enrollment.participant_employment.name)

    enrollmentForPerson: (employment_id) ->
      _.find @enrollments, (enrollment) -> enrollment.participant_employment.id == employment_id

    categoryNames:  -> _.map(@categories, 'name').join(', ')
    jobFamilyNames: -> _.map(@job_families, 'name').join(', ')

    isProposed:  -> @type == 'proposed'
    isRequested: -> @type == 'requested'
    isPlanned:   -> @type == 'planned'

    acceptedEnrollments: -> _.filter @enrollments, (enrollment) -> enrollment.isAccepted()
    rejectedEnrollments: -> _.filter @enrollments, (enrollment) -> enrollment.isRejected()

    allowedToModify: ->
      @permissions.any([
        "allowingToUpdate",
        "allowingToDelete",
        "allowingToArchive"
      ])

    mapTrainings: (trainings) =>
      _.map trainings, (training) -> new Training(training)

    addEnrollment: (employment)     => Enrollment.create(@id, employment.id)
    addEnrollments: (employmentIds) => Enrollment.create(@id, employmentIds)
    removeEnrollment: (enrollment)  => Enrollment.remove(enrollment)
    belongsToCompany: (company)     -> company.id == @structure_id

    @allCategories:  -> TrainingCategory.all()

    @service: -> Restangular.service("trainings")
