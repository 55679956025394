class CurrentCompanyService extends Service
  constructor: (@Restangular, @Structure) ->

  setCurrentCompany: (company_id, vacancy_id) =>
    @Restangular.one('current_companies').put({id: company_id, vacancy_id: vacancy_id}).then (response) =>
      new @Structure(response.data)

  getPermittedEntities: (permissions) =>
    @Restangular.one("current_companies/permitted_entities/parents").get(
      { permissions: permissions }
    ).then (response) =>
      roleAssociatedEntities = _.map(response.data, (structure_data) => new @Structure(structure_data))

      promises = []
      results = []
      for entity in roleAssociatedEntities
        promises.push(
          @Structure.structureWithDecendants(entity.id, {per_page: 9999}).then (response) =>
            for structure in response.data
              results.push(new @Structure(structure))
        )

      # Since Promise.all(promises) is not IE compatible, we use this.
      $.when.apply($, promises).then(() ->
        results
      )
