class MultipleChoices extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '='
      },
      controller: 'MultipleChoicesCtrl',
      controllerAs: 'ctrl'
      templateUrl: 'directives/survey_result/multiple_choices.html'
    }

# {
#   "id": 27424,
#   "answerer_name": "anonymous",
#   "content": [{
#     "choice": "Option2",
#     "comment": "valik 2"
#   }, {
#     "choice": "Option3",
#     "comment": "valik 3"
#   }, {
#     "choice": "Option4",
#     "comment": "valik 4"
#   }]
# }

# {
#   "id": 27451,
#   "answerer_name": "anonymous",
#   "content": {
#     "choice": "dog",
#     "comment": "siilikas msn koer"
#   }
# }

class MultipleChoicesCtrl extends Controller
  constructor: (@$scope, @$rootScope) ->
    @index = @$scope.index
    @data = @$scope.data
    @groupedAnswers = @_groupedAnswers(@data.answers)

  _groupedAnswers: (answers) ->
    flat = []
    _.each(answers, (a) ->
      _.each(a.content, (c) ->
        flat.push({
          id: a.id,
          answerer_name: a.answerer_name,
          content: c
        })
      )
    )
    _.groupBy(flat, (a) -> a.content.choice)
