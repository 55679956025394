class EmailTemplateGroupRepository extends Service

  constructor: (@Restangular, @EmailTemplateGroup) ->

  getAll: (options = {}) ->
    @Restangular.all('email_template_groups').getList(options).then (response) =>
      _.map(response.data, (group) => new @EmailTemplateGroup(group))

  createCopy: (parentGroupId, name) ->
    options = {parent_group_id: parentGroupId, name: name}
    @Restangular.all('email_template_group').post(options).then (response) =>
      new @EmailTemplateGroup(response.data)

  update: (templateGroupId, options) ->
    @Restangular.one('email_template_group', templateGroupId).patch(options).then (response) =>
      new @EmailTemplateGroup(response.data)
