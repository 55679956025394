angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'goals_new',
        url: "/goals/new?employment_id"
        parent: 'protected'
        template: "<ui-view/>"
        resolve:
          employment: (Employment, currentPerson, $stateParams) ->
            if $stateParams.employment_id
              Employment.oneShortForStructure(currentPerson.currentCompany.id, $stateParams.employment_id)
          people: (Employment, currentPerson, $stateParams) ->
            if $stateParams.employment_id
              if currentPerson.currentCompany.id == 1911 # Check if current company is Sorainen, in which case need to fetch all people from root company
                Employment.shortEmploymentsForSorainen(currentPerson.currentCompany.id)
              else
                Employment.shortEmploymentsForStructure(currentPerson.currentEmployment.entity.id)
        params:
          employment_id: null
        views:
          '@':
            controller: "NewGoalCtrl"
            controllerAs: "ctrl"
            templateUrl: "goals/new/newGoal.html"

class NewGoalCtrl extends Controller
  constructor: (@$scope, @$state, @currentPerson, people, @Goal, @$stateParams, employment, @Kpi) ->
    @goal = { progress: 0, target: 0, weight: 0, rating: 0 }
    @employment = employment
    @targetExceeded = false

    if @employment
      @goal.owner_employment_id = @employment.id
      @selectedPeople = [{id: @employment.id, name: @employment.name}]
    else
      @goal.owner_employment_id = @currentPerson.currentEmployment.id
      @selectedPeople = []

    @goal.created_by_person = @currentPerson.id

    if people
      @people = _.uniq _.map _.sortBy(people, 'name'), (person) -> {id: person.id, name: person.name}
    else
      @people = []

    @goal.people = []
    @$scope.$watchCollection('ctrl.selectedPeople', @updatePeople)
    @kpis = []
    @chosen_kpis = []
    @getKpis()

    @ratingSlider = { floor: 0, ceil: 5 }

  getProgressSliderOptions: =>
    { floor: 0, ceil: @goal.target }

  save:  =>
    @goal.responsible = ""

    @Goal.create(@goal).then (goal) =>
      @$state.go('goal', {goal_id: goal.id})

  isSorainen: =>
    @currentPerson.currentCompany.id == 1911    #DEV-718: Sorainen goal view changes

  canSetRatingAndCompletionComment: =>
    @currentPerson.permissions.one('allowingToManageGoals') and
      !_.isUndefined(@employment) and @currentPerson.currentEmployment.id != @goal.owner_employment_id

  back: =>
    @$state.go('goals')

  getKpis: =>
    @Kpi.getCustom().then (response) =>
      @kpis = response.data

  addPerson: (person) =>
    return if person.id in @goal.people
    @goal.people.push person.id

  updatePeople: (people) =>
    @goal.people = _.pluck(people, 'id')
