class MyActivityListRepository extends Service
  constructor: (@Restangular) ->

  getPlanningCategoriesByPerson: (employment_id) ->
    @Restangular.all('planning_categories/my_plans').getList('created_by_employment_id': employment_id).then (response) =>
      response

  getPlanActivitiesByPlanId: (planId) ->
    @Restangular.one('planning_categories', planId).all('planning_items').getList().then (response) =>
      response

  getPlanTestObject: () ->
    {
      "items": [
        {
          "plan_id": "1",
          "activity_id": 56,
          "name": "Tööplaani tegevus Linda"
        },
        {
          "plan_id": "1",
          "activity_id": 57,
          "name": "Tööplaani tegevus Tammy"
        },
        {
          "plan_id": "1",
          "activity_id": 58,
          "name": "Tööplaani tegevus Ryan"
        }
      ]
    }

  getAllPlans: () ->
    {
      "items": [
        {
          "id": 1,
          "created_by": 42,
          "entity_id": 6,
          "name": "Tooplaan Juan"
        },
        {
          "id": 2,
          "created_by": 40,
          "entity_id": 45,
          "name": "Tooplaan Audrey"
        },
        {
          "id": 3,
          "created_by": 46,
          "entity_id": 8,
          "name": "Tooplaan Cynthia"
        },
        {
          "id": 4,
          "created_by": 37,
          "entity_id": 36,
          "name": "Tooplaan Sherri"
        },
        {
          "id": 5,
          "created_by": 11,
          "entity_id": 11,
          "name": "Tooplaan Bonnie"
        },
        {
          "id": 6,
          "created_by": 22,
          "entity_id": 37,
          "name": "Tooplaan Neal"
        },
        {
          "id": 7,
          "created_by": 39,
          "entity_id": 12,
          "name": "Tooplaan Neal"
        },
        {
          "id": 8,
          "created_by": 41,
          "entity_id": 9,
          "name": "Tooplaan Linda"
        },
        {
          "id": 9,
          "created_by": 9,
          "entity_id": 22,
          "name": "Tooplaan Milton"
        },
        {
          "id": 10,
          "created_by": 13,
          "entity_id": 35,
          "name": "Tooplaan Ann"
        }
      ]
    }
