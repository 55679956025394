class SurveyFinishModalCtrl extends Controller
  constructor: (@$scope, @SurveyRepository, @toastr, @$state, @$translate, @survey, @roles) ->
    @notificationConfig = {}

  closeModal: () ->
    @$scope.modalInstance.close()

  postRequest: () ->
    @closeModal()
    @SurveyRepository.massFinish(@survey.id, @notificationConfig).then (response) =>
      @$state.go 'survey', { id: @survey.id }, { reload: true }
