class SinglePlan extends Service
  constructor: () ->
    return class SinglePlan
      constructor: () ->
        @responsibleEmploymentId
        @createdByEmploymentId
        @title
        @startDate
        @endDate
        @description
        @expectedResult
        @status
        @plannedBudget
        @actualBudget
        @locked
        @planId
        @typeId
        @levelId
        @isToggledVisibleForView = false
        @planItems = []

      isVisible: () =>
        @isToggledVisibleForView = !@isToggledVisibleForView
        return @isToggledVisibleForView

      getAPIObject: () ->
        {
          id: @planId,
          responsible_employment_id: @responsibleEmploymentId,
          @title,
          start_date: @startDate,
          end_date: @endDate,
          @description,
          planned_budget: @plannedBudget,
          actual_budget: @actualBudget,
          @locked,
          @status,
          @level,
          category_type: @typeId,
          expected_result: @expectedResult
        }

      responseToModel: (response) ->
        @planId = response.id
        @responsibleEmploymentId = response.responsible_employment_id
        @createdByEmploymentId = response.created_by_employment_id
        @title = response.title
        @startDate = response.start_date
        @endDate = response.end_date
        @description = response.description
        @plannedBudget = response.planned_budget
        @actualBudget = response.actual_budget
        @locked = response.locked
        @status = response.status
        @level = response.level
        @typeId = response.category_type
        @expectedResult = response.expected_result
        return @
