angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'satisfaction-surveys',
        parent: 'protected'
        url: '/satisfaction-surveys'
        template: '<ui-view/>'
        resolve:
          response: (SatisfactionSurveyRepository) ->
            SatisfactionSurveyRepository.getAll()
          opts: ->
            {}
        views:
          '@':
            controller: "SatisfactionSurveysListCtrl"
            controllerAs: "ctrl"
            templateUrl: "satisfaction_surveys/satisfactionSurveysList.html"

class SatisfactionSurveysListCtrl extends Controller
  constructor: (@$translate, TableOptions, response, @currentPerson, @Paging, @SatisfactionSurveyRepository, @SatisfactionSurvey, @Enrollment, @opts, @SurveyReportGeneratorService) ->
    @paging = new @Paging(response)
    @all    = new Array(@paging.getTotalPages())
    @addSurveysToList(response.data)
    @tableOptions = new TableOptions()

  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@all[@paging.iterator()])
      @fetchSurveys({page: page})

  fetchSurveys: (options = {}) =>
    angular.extend options, @opts
    @SatisfactionSurveyRepository.getAll(options).then (response) =>
      @paging = new @Paging(response)
      @addSurveysToList(response.data)

  addSurveysToList: (surveys) =>
    @all[@paging.iterator()] =
      _.map surveys, (survey) =>
        new @SatisfactionSurvey(survey)

  fetchPdf: (surveyId) =>
    @SurveyReportGeneratorService.fetchReportResult(surveyId).then (surveyResult) ->
      @SurveyReportGeneratorService.fetchReportPdf(surveyResult)
