class ProjectCreationModalCtrl extends Controller
  constructor: (
    @$rootScope, @$scope, @toastr, @$translate, @$state, @ProjectRepository,
    @availableParents, @selectedParent, @returnStateName, @returnStateParams
  ) ->
    @currentPerson = @$rootScope.currentPerson
    @currentCompany = @currentPerson.currentCompany
    @formData = {}

  save: () ->
    @_finalizeFormData()
    @ProjectRepository.createProject(@currentCompany.id, @formData).then (project) =>
      if Object.keys(project.errors).length
        @toastr.error(@$translate.instant('projects.creation_failed'))
      else
        @toastr.success(@$translate.instant('projects.creation_successful'))
        @_close()
        @$state.go(@returnStateName, @returnStateParams, { reload: true })

  cancel: () ->
    @_close()

  _finalizeFormData: () ->
    if @selectedParent && @selectedParent.id
      @formData.parent_id = @selectedParent.id
    else
      @formData.parent_id = null

  _close: () ->
    @$scope.modalInstance.close()
