class FileDropzone extends Directive
  constructor: () ->
    return {
      restrict: 'A',
      scope: {
        img: '='
        file: '='
        fileName: '='
        hide: '='
      },
      link: (scope, element, attrs) ->

        processDragOverOrEnter = (event) ->
          event?.preventDefault()
          # Bugs Firefox
          #event.dataTransfer = event.originalEvent.dataTransfer
          #event.dataTransfer.effectAllowed = 'copy'
          false

        validMimeTypes = attrs.fileDropzone

        checkSize = (size) ->
          if attrs.maxFileSize in [undefined, ''] or (size / 1024) / 1024 < attrs.maxFileSize
            true
          else
            alert "File must be smaller than #{attrs.maxFileSize} MB"
            false

        isTypeValid = (type) ->
          if validMimeTypes in [undefined, ''] or validMimeTypes.indexOf(type) > -1
            true
          else
            alert "Invalid file type.  File must be one of following types #{validMimeTypes}"
            false

        element.bind 'dragover', processDragOverOrEnter
        element.bind 'dragenter', processDragOverOrEnter

        element.bind 'drop', (event) ->
          event?.preventDefault()
          reader = new FileReader()
          reader.onload = (evt) ->
            if checkSize(size) and isTypeValid(type)
              scope.$apply ->
                scope.img = evt.target.result
                scope.fileName = name
                scope.hide = 1

          file = event.originalEvent.dataTransfer.files[0]
          name = file.name
          type = file.type
          size = file.size
          scope.file = file
          reader.readAsDataURL(file)
          return false
    }
