class SurveyDeleteModalCtrl extends Controller
  constructor: (@$scope, @SurveyRepository, @toastr, @$state, @$translate) ->
    @survey = @$scope.$parent.ctrl.survey

  closeModal: () ->
    @$scope.modalInstance.close()

  postRequest: () ->
    @closeModal()
    @SurveyRepository.massDelete(@survey.id).then (response) =>
      @$state.go 'survey_overview'
