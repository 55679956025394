class DirectReportRowCtrl extends Controller
  constructor: (@$scope, @VacancyRepository, @StructureRepository, @Vacancy, @AffiliatableRole, @$modal, @Restangular, @$rootScope, @$translate) ->
    @structure = @$scope.$parent.structure
    @isPeopleVisible = false
    @peopleLoaded = false
    @vacancies = []
    @roles = []

    @_active = ''

  togglePeopleVisibility: () ->
    @isPeopleVisible = !@isPeopleVisible

  getPeople: =>
    @togglePeopleVisibility()

    if _.isEmpty @vacancies
      @VacancyRepository.allForStructure(@structure.id, {consider_role: true}).then (vacancies) =>
        if(vacancies.length > 0)
          @_setVacancies(vacancies)
        else
          @_getManagers()

  displayRoleName: (role) ->
    vacancyRoleName = role.employment && role.employment.vacancy && role.employment.vacancy.role_name
    roleName = vacancyRoleName || role.role_name
    roleName = inflection.capitalize(roleName) if roleName
    roleName || @$translate.instant('general.not_set')

  _setVacancies: (vacancies) ->
    @vacancies = vacancies
    @peopleLoaded = true

  _getManagers: () ->
    @StructureRepository.getAllManagers(@structure.id).then (roles) =>
      @_setRoles(roles)

  _setRoles: (roles) ->
    @roles = roles
    @peopleLoaded = true
