class Vacancy extends Service
  constructor: (Restangular, Base, VacancyJobFamily, Employment, AffiliatableRole) -> return class Vacancy extends Base
    constructor: (json) ->
      angular.extend(@, json)

      if json.employment
        @employment = new Employment(json.employment)

    uniqueJobFamilies: -> _.uniq(@job_families,  (a) -> return a['professional_group_id'])

    @one: (vacancy_id) ->
      Restangular.one('vacancies', vacancy_id).get().then (response) ->
        new Vacancy(response.data)

    @update: (vacancy_id, options = {}) =>
      Restangular.one("vacancies", vacancy_id).customPUT(options)

    @transfer: (vacancy, structure) ->
      Restangular.one("vacancies", vacancy.id).one("transfer")
        .customPUT({target_structure_id: structure.id})

    @getDeletableRoles: (vacancyId) ->
      Restangular.one('vacancies', vacancyId).one('deletable_roles').get().then (response) =>
        @_extractDeletableAffiliatableRoles(response.data.plain())

    @_extractDeletableAffiliatableRoles: (data) ->
      entities = {}
      people = {}

      _.each data.entities, (entity_data) ->
        entities[entity_data.id] = entity_data

      _.each data.people, (person_data) ->
        people[person_data.id] = person_data

      _.map data.affiliatable_roles, (role_data) ->
        role = new AffiliatableRole(role_data)
        role.entity = entities[role_data.entity_id]
        role.person = people[role_data.person_id]
        role
