angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'personalDash',
        parent: 'protected'
        url: '/dashboard'
        views:
          '@':
            controller: "PersonalDashCtrl",
            controllerAs: "ctrl",
            templateUrl: "dashes/personalDash/personalDash.html"
      .state 'personalDash.activityFeed',
        showLoader: false,
        resolve:
          response: (PersonalFeedbackRepository) ->
            PersonalFeedbackRepository.getAll()
        views:
          'personalDashContent':
            controller: 'ActivityFeedCtrl'
            controllerAs: "ctrl",
            templateUrl: "dashes/personalDash/_activityFeed.html"
      .state 'personalDash.toDos',
        showLoader: false,
        resolve:
          response: (SurveyRepository, currentPerson) ->
            SurveyRepository.getActiveFeedbacks({person_id: currentPerson.id})
        views:
          'personalDashContent':
            controller: 'TodosCtrl'
            controllerAs: "ctrl",
            templateUrl: "dashes/personalDash/_toDos.html"

class PersonalDashCtrl extends Controller
  constructor: (@$state) ->
    @showTodos()

  showActivityFeed: () =>
    @activityFeedVisible = true
    @$state.go('personalDash.activityFeed')

  showTodos: () =>
    @activityFeedVisible = false
    @$state.go('personalDash.toDos')
    
