class Scale extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '=',
        translations: '=',
      },
      controller: 'ScaleCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'directives/survey_result/scale.html',
    }

class ScaleCtrl extends Controller
  constructor: (@$scope, @SurveyTranslatorService) ->
    @index = @$scope.index
    @data = @$scope.data
    @translations = @$scope.translations

    @translator = @SurveyTranslatorService
    @groupedAnswers = @_groupedAnswers(@data.answers, @translator)
    @roleRatings = @_roleRatings(@data.role_ratings, @translations)

  _groupedAnswers: (answers, translator) ->
    _.groupBy(answers, (a) ->
      if a.content.rating < 0
        translator.translate('general.dont-know')
      else
        a.content.rating + 1
    )

  _roleRatings: (ratings, translations) ->
    _.reduce(ratings, (result, rating, role) =>
      result[@translator.translatedRole(translations, role)] = rating
      result
    , {}, @)
