class EmploymentService extends Service
  constructor: (@Restangular, @Employment, @AffiliatableRole) ->

  getDeletableRoles: (employment_id) ->
    @Restangular.one('employments', employment_id).one('deletable_roles').get().then (response) =>
      @_extractDeletableAffiliatableRoles(response.data.plain())

  terminateWithRoles: (employment_id, roles) ->
    deletableRoleIds = []
    _.each roles, (role) ->
      if role.id && role.deletable
        deletableRoleIds.push(role.id)

    @terminate(employment_id, { "role_ids[]": deletableRoleIds })

  terminate: (employment_id, options = {}) ->
    @Restangular.one('employments', employment_id).remove(options)

  _extractDeletableAffiliatableRoles: (data) ->
    entities = {}

    _.each data.entities, (entity_data) ->
      entities[entity_data.id] = entity_data

    _.map data.affiliatable_roles, (role_data) =>
      role = new @AffiliatableRole(role_data)
      role.entity = entities[role_data.entity_id]
      role
