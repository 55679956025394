class SinglePlanRepository extends Service
  constructor: (@Restangular, @$rootScope) ->

  createPlan: (planObject) ->
    @$rootScope.isStateLoading = true # ???
    @Restangular.all("planning_categories").post(planObject.getAPIObject()).then (response) =>
      @$rootScope.isStateLoading = false # ???
      response

  getPlanById: (planId) ->
    @$rootScope.isStateLoading = true # ???
    @Restangular.one('planning_categories', planId).get().then (response) =>
      @$rootScope.isStateLoading = false # ???
      response.data
