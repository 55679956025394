class SatisfactionSurvey extends Service
  constructor: (Base, Restangular, Permissions, Constants, $state, toastr, $translate) -> return class SatisfactionSurvey extends Base
    constructor: (json) ->
      angular.extend @, json
      @permissions       = new Permissions(@permissions)

    @one: (id) ->
      Restangular.one('satisfaction_surveys', id).get().then (response) ->
        new SatisfactionSurvey(response.data)

    @getSelectionRepository: (id) ->
      Restangular.one('satisfaction_surveys', id).one('selection_repository').get().then (response) ->
        response.data
