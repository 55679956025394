class TrainingEnrollmentsListCtrl extends Controller
  constructor: (@$scope, @Employment, @Vacancy, @Enrollment, @toastr, @$translate, @$modal) ->
    @training      = @$scope.$parent.ctrl.training
    @currentPerson = @$scope.$parent.ctrl.currentPerson
    @structure     = @currentPerson.currentCompany

    @selectedEmployments = []
    @employments         = []
    @peopleParticipated  = []
    @canParticipationBeSet = false
    @areAllSelected        = @areAllPeopleParticipating()

    if @training.permissions.one("allowingToAddParticipants")
      @getEmployments()

  toggleAreAllSet: =>
    if @areAllSelected
      @areAllSelected = false
    else
      @areAllSelected = true

    _.each @training.enrollments, (enrollment) =>
      enrollment.participated = @areAllSelected
      return

  setParticipation:        -> @canParticipationBeSet = !@canParticipationBeSet
  currentPersonEnrollment: -> @$scope.$parent.ctrl.currentPersonEnrollment

  areAllPeopleParticipating: =>
    (_.filter @training.enrollments, (enrollment) -> enrollment.participated).length == @training.enrollments.length

  canAcceptRejectEnrollment: (enrollment) =>
    @training.manager_accepting_required && enrollment.permissions.one('allowedToDecide')

  canPersonOpenAdditionalInfoModal: (enrollment) =>
    @isEnrollmentForCurrentPerson(enrollment) || enrollment.permissions.one('allowedToDecide')

  isEnrollmentForCurrentPerson: (enrollment) =>
    if @currentPersonEnrollment()
      enrollment.id == @currentPersonEnrollment().id
    else
      false

  saveParticipation: ->
    participations = _.map @training.enrollments, (enrollment) ->
      {id: enrollment.id, participated: enrollment.participated}
    @Enrollment.setParticipations(participations).then =>
      @toastr.success(@$translate.instant('general.participations_set'))
      @canParticipationBeSet = false

  openAcceptRejectModal: (type, enrollment) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'trainings/enrollments/acceptRejectModal.html'
      controller: "AcceptRejectModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        enrollment:   => enrollment
        decisionType: => type
    .result.then (new_enrollment) =>
      enrollment = new_enrollment

  openAdditionalInfoModal: (enrollment) =>
    @$modal.open
      windowClass: "medium"
      templateUrl: 'trainings/enrollments/additionalInfoModal.html'
      controller: "AdditionalInfoModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        enrollment:    => enrollment
        currentPerson: => @currentPerson
    .result.then (new_enrollment) =>
      enrollment = new_enrollment

  getEmployments: =>
    @Employment.shortEmploymentsForStructure(@structure.id, @training.job_family_ids).then (employments) =>
      @employments = @filterEmployments(employments)

  addEnrollments: =>
    employmentIds = _.map @selectedEmployments, (employment) -> employment.id
    @training.addEnrollments(employmentIds).then (response) =>
      _.each response.data, (enrollment) =>
        newEnrollment = new @Enrollment(enrollment)
        @training.enrollments.push(newEnrollment)
        @removeEmploymentFromList(newEnrollment.participant_employment)

    @selectedEmployments = []

  filterEmployments: (employments) =>
    employments = _.reject employments, (employment) => _.isNull employment

    _.each @training.enrollments, (enrollment) =>
      employments = _.reject employments, (employment) -> employment.id == enrollment.participant_employment.id

    _.sortBy employments, 'name'

  filterVacanciesByJobFamilies: (vacancies) =>
    _.filter vacancies, (vacancy) =>
      _.find vacancy.job_families, (family) => _.includes @training.job_family_ids, family.professional_group_id

  removeEmploymentFromList: (removed_employment) =>
    @employments = _.reject @employments, (employment) => employment.id == removed_employment.id

  addEmploymentToList: (removed_employment) =>
    @employments.push(removed_employment)
    @employments = _.sortBy @employments, 'name'

  removeEnrollment: (enrollment) =>
    @training.removeEnrollment(enrollment).then =>
      @removeDeletedEnrollment(enrollment)
      @addEmploymentToList(enrollment.participant_employment)

  isCurrentPersonEnrollmentBeingDeleted: (deleted_enrollment) =>
    @currentPersonEnrollment() && @currentPersonEnrollment().id == deleted_enrollment.id

  removeDeletedEnrollment: (deleted_enrollment) =>
    @training.enrollments = _.reject @training.enrollments, (enrollment) => enrollment.id == deleted_enrollment.id
    @toastr.success @$translate.instant('trainings.participation_removed')
    if @isCurrentPersonEnrollmentBeingDeleted(deleted_enrollment)
      @$scope.$parent.ctrl.currentPersonEnrollment = undefined

  allowedToSeeEnrollmentStatus: (enrollment) ->
    if enrollment.participant_employment.id == @currentPerson.currentEmployment.id
      true
    else if @training.manager_accepting_required && @training.permissions.one('allowingToAddParticipants')
      true
    else
      false
