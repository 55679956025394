angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'employees_trainings',
        parent: 'protected'
        url: "/employees_trainings?employment_id&entity_id"
        template: "<ui-view/>"
        resolve:
          entitiesWithPermission: (permissionService) ->
            _.map(permissionService.entitiesWithPermission("allowingToManageTrainings"), 'id')
          employments: (Employment, entitiesWithPermission) ->
            Employment.shortEmploymentsForStructureMultiple(entitiesWithPermission)
          entities: (StructureRepository, entitiesWithPermission) ->
            StructureRepository.structureWithDecendantsAsArrayMultiple(
              entitiesWithPermission,
              {paginate: false}
            )
          trainingCategories: (TrainingCategory) ->
            TrainingCategory.all()
          jobFamilies: (ProfessionalGroup, currentPerson) ->
            ProfessionalGroup.allForCompany(currentPerson.currentCompany.id)
        views:
          '@':
            controller: "EmploymentTrainingsListCtrl"
            controllerAs: "ctrl"
            templateUrl: "trainings/employmentList/employmentTrainingList.html"

class EmploymentTrainingsListCtrl extends Controller
  constructor: (@$translate, @toastr, @$modal, TableOptions, @currentPerson, @jobFamilies, @trainingCategories, @Paging, @Training, @Enrollment, employments, @Employment, entities, @Structure, @$stateParams) ->
    @tableOptions  = new TableOptions(translationScope: 'trainings')
    @paging        = {}
    @trainingTypes = @Training.trainingTypes()
    @selectedEmployments = []
    @selectedEntities = []
    @employmentsWithTrainings = []

    if @$stateParams.employment_id
      @selectedEmployments = _.filter employments, (employment) => employment.id.toString() == @$stateParams.employment_id

    if @$stateParams.entity_id
      @selectedEntities = _.filter entities, (entity) => entity.id.toString() == @$stateParams.entity_id

    @filterEmployments(employments)
    @filterEntities(entities)
    @getFilteredTrainings()

  getTrainingsForSelectedEmployments: (page) =>
    if @selectedEntities.length || @selectedEmployments.length
      @Employment.trainings(@generateParams(page)).then (result) =>
        @paging = new @Paging(result)
        @employmentsWithTrainings = []
        @addEmploymentsToList(result.data)

  filterEmployments: (employments) =>
    @employments = _.sortBy(
      _.compact(_.map employments, (employment) -> employment if employment), 'name'
    )

  filterEntities: (entities) =>
    @entities = _.compact(_.map entities, (entity) -> entity if entity)

  addEmploymentsToList: (employments) =>
    employments = @mapEmploymentsWithTrainings(employments)
    @employmentsWithTrainings[@paging.iterator()] = employments

  generateParams: (page) ->
    selectedEmploymentIds = _.map @selectedEmployments, (employment) -> employment.id
    selectedEntityIds     = _.map @selectedEntities, (entity) -> entity.id
    options               = { 'employment_ids[]': selectedEmploymentIds, 'entity_ids[]': selectedEntityIds }
    options.page          = page if page
    if @phrases
      options['types[]']          = @phrases['types[]']          if @phrases['types[]']
      options['category_ids[]']   = @phrases['category_ids[]']   if @phrases['category_ids[]']
      options['job_family_ids[]'] = @phrases['job_family_ids[]'] if @phrases['job_family_ids[]']
      options['name']             = @phrases['name']             if @phrases['name']

    options

  getFilteredTrainings: (page) => @getTrainingsForSelectedEmployments(page)

  mapEmploymentsWithTrainings: (employments) =>
    _.each employments, (employment) =>
      employment.trainings = _.map employment.trainings, (training) => new @Training(training)
    employments

  changePage: (page) =>
    @paging.changePage(page)
    @getFilteredTrainings(page)

  isEmploymentInList: (employment) =>
    _.find @employmentsWithTrainings, (employmentWithTraining) =>  employmentWithTraining.id == employment.id
