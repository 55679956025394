class SurveyReportGeneratorService extends Service
  constructor: (@$rootScope, @SurveyRepository, @$http) ->

  fetchReportResult: (surveyId) ->
    @SurveyRepository.getResult(surveyId).then (response) =>
      return response.data.plain()

  fetchReportPdf: (surveyId, person, options = {}) ->
    base_url = @$rootScope.config.report_generator_url
    language = options.language || person.language
    url = "#{base_url}/#{surveyId}?lang=#{language}&token=#{person.token}&format=pdf"
    window.open(url)

  fetchProjectSurveysExcel: (projectId, person, options = {}) ->
    language = options.language || person.language
    relativeUrl = "projects/#{projectId}/statistics/surveys?lang=#{language}&token=#{person.token}"
    @_openReportGeneratorUrl(relativeUrl)

  fetchProjectReportPdf: (projectId, person, options = {}) ->
    base_url = @$rootScope.config.report_generator_url
    language = options.language || person.language
    profGroupId = options.profGroupId
    url = "#{base_url}/projects/#{projectId}?lang=#{language}&token=#{person.token}&format=pdf"
    if (profGroupId)
      url += "&profGroupId=#{profGroupId}"
    window.open(url)

  _openReportGeneratorUrl: (relativeUrl) ->
    baseUrl = @$rootScope.config.report_generator_url
    url = "#{baseUrl}/#{relativeUrl}"
    window.open(url)
