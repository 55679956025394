class StructureRowCtrl extends Controller
  constructor: (@Restangular, @SurveyRepository, @$rootScope, @$state, @$modal, @$translate, @$location, @toastr, @$scope, @$filter, @Constants, @Vacancy, @VacancyRepository) ->
    @structure = @$scope.$parent.structure
    @vacancies = []
    @isPeopleVisible = false
    @vacanciesLoaded = false
    @_active = ''
    @_initializePeopleVisibility()

  _initializePeopleVisibility: ->
    if @$location.hash() == "structure-entity-" + @structure.id
      @getStructureVacancies()

  activatePane: (name) -> @_active = if @_active is name then '' else name
  paneClass:    (name) -> 'active' if name is @_active
  moreIcon:     (name) -> if @_active is name then 'fa-angle-down' else 'fa-angle-right'

  iconClass: ->
    if @isPeopleVisible then @Constants.icons.ANGLE_DOWN else @Constants.icons.ANGLE_RIGHT

  togglePeopleVisibility: -> @isPeopleVisible = !@isPeopleVisible
  isStructureVisible:     => @vacancies.length > 0
  anyVacancies:           => !_.isEmpty(@vacancies) && @isStructureVisible()

  createVacancyAndRedirect: =>
    @Restangular.one('structures', @$scope.structure.id).all('vacancies').post({}).then (response) =>
      @$state.go('vacancy.edit', {vacancy_id: response.data.id})

  createSurveyDraftAndRedirect: (employment_id, survey_type) =>
    @toastr.success "", @$translate.instant('general.starts')
    @SurveyRepository.createSurveyDraft(employment_id, survey_type).then (response) =>
      @$state.go('survey', { id: response.data.id }, { reload: true }).then () =>
        @toastr.success "", @$translate.instant('general.started')

  startVacancyDeletionFlow: (vacancy) =>
    @Vacancy.getDeletableRoles(vacancy.id).then (roles) =>
      if _.isEmpty(roles)
        @_openVacancyDeleteModal(vacancy, roles, { "windowClass": "small", "templateUrl": "vacancy/delete/vacancyDelete.html" })
      else
        @_openVacancyDeleteModal(vacancy, roles, { "windowClass": "medium", "templateUrl": "vacancy/delete/vacancyDeleteWithRoles.html" })

  _openVacancyDeleteModal: (vacancy, roles, config) =>
    @$modal.open
      windowClass: config["windowClass"]
      templateUrl: config["templateUrl"]
      controller: "VacancyDeleteModalCtrl"
      controllerAs: 'vacancyModalCtrl'
      resolve:
        vacancy: => @Restangular.restangularizeElement(null, vacancy, 'vacancies')
        roles: => roles
    .result.then =>
      @toastr.success @$translate.instant('vacancy.deleted')
      _.remove(@vacancies, vacancy)

  sendAffiliationReminderFor: (employment) ->
    @Restangular.one('affiliations', employment.id).all('reminders').post().then (response) =>
      @toastr.success "", @$translate.instant('affiliation.reminder_sent')

  getStructureVacancies: =>
    @togglePeopleVisibility()

    if _.isEmpty @vacancies
      @VacancyRepository.allForStructure(@structure.id).then (vacancies) =>
        @vacancies = vacancies
        @vacanciesLoaded = true

  isSorainen: () ->
    @$rootScope.currentPerson.currentCompany.id == 1911

  displayManagerNames: (structure) ->
    names = _.map structure.managers, "name"
    names.join(", ")

  canAddManager: (structure, employment) ->
    person = @_getEditableManager(structure, employment)
    person && !@_isManager(structure, person)

  canRemoveManager: (structure, employment) ->
    person = @_getEditableManager(structure, employment)
    person && @_isManager(structure, person)

  _getEditableManager: (structure, employment) ->
    if structure.permissions.one("allowingToEditManagerRoles")
      employment && employment.person
    else
      null

  _isManager: (structure, person) ->
    structure.managersById && structure.managersById[person.id]

  addManager: (structure, employment, currentPage) ->
    structure.addManager(employment).then =>
      @_reloadCurrentState(structure, currentPage).then =>
        @toastr.success @$translate.instant("structure.manager_added")

  removeManager: (structure, employment, currentPage) ->
    structure.removeManager(employment).then =>
      @_reloadCurrentState(structure, currentPage).then =>
        @toastr.success @$translate.instant("structure.manager_removed")

  _reloadCurrentState: (structure, currentPage) ->
    @$state.go(
      "structures.people"
      {
        structure_type: 'companies', structure_id: @$rootScope.currentPerson.currentCompany.id,
        page: currentPage, '#': 'structure-entity-' + structure.id
      },
      { reload: true }
    )
