class OldAppLink extends Directive
  constructor: (OldApp) ->
    return {
      restrict: 'E'
      replace: true
      scope:
        url: '@'
        title: '@'
      template: '<a href="{{url}}" target="_self">{{translated}}</a>'
      controller: ($scope, $translate) ->
        $translate($scope.title).then (data) -> $scope.translated = data
    }
