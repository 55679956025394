class EmailLayoutRepository extends Service

  constructor: (@Restangular, @EmailLayout) ->

  getAll: (options = {}) ->
    @Restangular.all('email_layouts').getList(options).then (response) =>
      _.map(response.data, (layout) => new @EmailLayout(layout))


  get: (layoutId, options = {}) ->
    @Restangular.one('email_layout', layoutId).get(options).then (response) =>
      new @EmailLayout(response.data)
