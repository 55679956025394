class ActivityRepository extends Service

  constructor: (@$rootScope, @Restangular) ->

  updateActivity: (activity) ->
    @Restangular.one('planning_items', activity.id).patch(activity).then (response) ->
      response.data

  getActivityById: (activityId) ->
    @Restangular.one('planning_items', activityId).get().then (response) ->
      response.data

  createActivity: (activity) ->
    @Restangular.all("planning_items").post(activity).then (response) ->
      response.data

  allCostRowTypes: ->
    @Restangular.all('planning_items').all('cost_row_types').getList().then (response) ->
      response.data
