class PasswordResetConfig extends Config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'user.password_reset',
        url: '/password'
        views:
          'unprotected@':
            templateUrl: 'user/passwordReset.html'
            controller: 'PasswordResetRouteCtrl'
            controllerAs: 'ctrl'
            resolve:
              resetToken: ($location, Person) ->
                Person.checkToken($location.search().reset_token)

class PasswordResetRouteCtrl extends Controller
  constructor: (@$state, @toastr, @resetToken, @Person, @$translate) ->
    @resetForm = { reset_token: @resetToken }

  resetPassword: (form) =>
    if form.$valid
      @Person.resetPassword(@resetForm).then =>
        @toastr.success @$translate.instant 'password.reset.success'
        @$state.go 'login'