angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey_mass_upload_errors',
        parent: 'protected'
        url: '/survey/{surveyId:int}/mass-uploads/{massUploadId:int}/errors'
        resolve:
          surveyId: ($stateParams) ->
            $stateParams.surveyId
          massUploadId: ($stateParams) ->
            $stateParams.massUploadId
          headerErrorsResponse: (surveyId, massUploadId, SurveyRepository) ->
            SurveyRepository.getMassUploadErrors(surveyId, massUploadId, { category: 'header' })
          dataErrorsResponse: (surveyId, massUploadId, SurveyRepository) ->
            SurveyRepository.getMassUploadErrors(surveyId, massUploadId, { category: 'data' })
        views:
          '@':
            controller: "MassUploadErrorsCtrl"
            controllerAs: "ctrl"
            templateUrl: "survey/massUploadErrors.html"

class MassUploadErrorsCtrl extends Controller
  constructor: (@$scope, @$translate, @surveyId, @massUploadId, headerErrorsResponse, dataErrorsResponse) ->
    @categorizedResponses = { header: headerErrorsResponse, data: dataErrorsResponse}

    @anyKnownErrorsToShow = false
    _.each @categorizedResponses, (response, category) =>
      if response.data.length
        @anyKnownErrorsToShow = true

  normalizeErrorList: (errors) ->
    _.each errors, (error) =>
      @_normalizeError(error)

  _normalizeError: (error) ->
    @_normalizeErrorMessage(error)
    _.each error.details, (value, name) ->
      error[name] = value
    delete error['details']

  _normalizeErrorMessage: (error) ->
    text = error.error
    key = 'survey.mass_upload_errors.error.' + text
    normalized = @$translate.instant(key)
    if normalized == key
      error.error = text.replaceAll('_', ' ')
    else
      error.error = normalized
