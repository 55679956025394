class IndividualResponderCtrl extends Controller

  constructor: (@$scope, @$modal, @IndividualResponderRepository, @toastr, @$translate) ->
    @responder = @$scope.responder
    @role = @$scope.role
    @survey = @$scope.survey
    @reassignableResponderRoles = @$scope.reassignableResponderRoles
    @modalInstance = null
    @reminder = @responder.reminder_sent_at

  openCommentModal: () ->
    @modalInstance = @$modal.open
      scope: @$scope
      templateUrl: "directives/survey_responders/addCommentModal.html"
      controller: "IndividualResponderCtrl"
      controllerAs: 'ctrl'

  isResponderDeletable: () ->
    !@responder.answered_at && @role != 'employee'

  deleteResponder: () ->
    @IndividualResponderRepository.delete(@survey.id, @responder.id).then (response) =>
      @$scope.$parent.ctrl.deleteResponder(@responder, @role)
      @toastr.success @$translate.instant("survey.responder.removed")

  addComment: () ->
    comment = {comment: @responder.comment}
    @IndividualResponderRepository.update(@survey.id, @responder.id, comment).then (response) =>
      @toastr.success @$translate.instant("general.comment_added")
      @modalInstance.close()

  isResponderRemindable: () ->
    if @responder.answered_at
      @survey.isAppraisalInterview() && @survey.isClosed()
    else
      true

  sendReminder: () ->
    @IndividualResponderRepository.sendReminder(@survey.id, @responder.id).then (response) =>
      @reminder = response.data.reminder_sent_at
      @toastr.success @$translate.instant("general.reminder_sent")
