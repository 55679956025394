angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'goals',
        parent: 'protected'
        url: "/goals?employment_id&entity_id"
        template: "<ui-view/>"
        resolve:
          entitiesWithPermission: (permissionService) ->
            _.map(permissionService.entitiesWithPermission("allowingToManageGoals"), 'id')
          employments: (Employment, currentPerson, entitiesWithPermission) ->
            if currentPerson.currentCompany.id == 1911 # Check if current company is Sorainen, in which case need to fetch people from all departments
              Employment.shortEmploymentsForSorainen(currentPerson.currentCompany.id)
            else
              Employment.shortEmploymentsForStructureMultiple(entitiesWithPermission)
          entities: (StructureRepository, entitiesWithPermission) ->
            StructureRepository.structureWithDecendantsAsArrayMultiple(
              entitiesWithPermission
              {paginate: false}
            )
        views:
          '@':
            controller: "GoalsListCtrl"
            controllerAs: "ctrl"
            templateUrl: "goals/list/goalsList.html"

class GoalsListCtrl extends Controller
  constructor: (employments, @Goal, TableOptions, @Paging, @$modal, @toastr, @$translate, @Employment, @$stateParams, @currentPerson, entities, @Structure, @GoalRepository) ->
    @tableOptions = new TableOptions(translationScope: 'goals')
    @statuses     = @Goal.statuses()
    @paging       = {}
    @selectedEmployments = []
    @selectedEntities = []
    @employmentsWithGoals = []

    if @$stateParams.employment_id
      @selectedEmployments = _.filter employments, (employment) => employment.id.toString() == @$stateParams.employment_id

    if @$stateParams.entity_id
      @selectedEntities = _.filter entities, (entity) => entity.id.toString() == @$stateParams.entity_id

    @filterEmployments(employments)
    @filterEntities(entities)
    @getFilteredGoals()

  isSorainen: =>
    @currentPerson.currentCompany.id == 1911      #DEV-718: Sorainen goal view changes

  getGoalsForSelectedEmployments: (page) =>
    if @selectedEmployments.length || @selectedEntities.length
      @Employment.goals(@generateParams(page)).then (result) =>
        @paging = new @Paging(result)
        @employmentsWithGoals = []
        @addEmploymentsToList(result.data)

  filterEmployments: (employments) =>
    @employments = _.sortBy(
      _.compact(_.map employments, (employment) -> employment if employment), 'name'
    )

  filterEntities: (entities) =>
    @entities = _.compact(_.map entities, (entity) -> entity if entity)

  setGoalDone: (goal) ->
    goal.setDone().then (updated_goal) ->
      goal = updated_goal

  addEmploymentsToList: (employments) =>
    employments = @mapEmploymentsWithGoals(employments)
    @employmentsWithGoals[@paging.iterator()] = employments

  generateParams: (page) ->
    selectedEmploymentIds = _.map @selectedEmployments, (employment) -> employment.id
    selectedEntityIds     = _.map @selectedEntities, (entity) -> entity.id
    selectedCompanyId     = @currentPerson.currentCompany.id
    options               = { 'employment_ids[]': selectedEmploymentIds, 'entity_ids[]': selectedEntityIds, 'current_company_id': selectedCompanyId }
    options.page        = page if page
    if @options
      options['title']    = @options['title']    if @options['title']
      options['status[]'] = @options['status[]'] if @options['status[]']

    options

  newGoalParams: (goalRow) =>
    if @currentPerson.currentEmployment.id != goalRow.employment.id
      {employment_id: goalRow.employment.id}
    else
      {}

  getFilteredGoals: (page) =>
    @getGoalsForSelectedEmployments(page)

  mapEmploymentsWithGoals: (employments) =>
    _.each employments, (employment) =>
      employment.goals = _.map employment.goals, (goal) => new @Goal(goal)
    employments

  changePage: (page) =>
    @paging.changePage(page)
    @getFilteredGoals(page)

  isEmploymentInList: (employment) =>
    _.find @employmentsWithGoals, (employmentWithGoal) =>  employmentWithGoal.id == employment.id

  openGoalDeleteModal: (goal) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'goals/delete/deleteGoalModal.html'
      controller: "DeleteGoalModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        goal: => goal
    .result.then =>
      @GoalRepository.delete(goal).then =>
        @toastr.success @$translate.instant('goals.deleted')
        @goalsVisible = true
        @getFilteredGoals()
