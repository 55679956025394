angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'direct_reports',
        parent: 'protected'
        url: "/people/:id/direct_reports"
        resolve:
          response: (DirectReportsService, $stateParams) ->
            DirectReportsService.getList($stateParams.id)
        views:
          '@':
            templateUrl: "directReports/directReports.html"
            controller: "DirectReportsCtrl"
            controllerAs: 'ctrl'

angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
    .state 'survey_overview',
      parent: 'protected'
      url: '/survey_overview'
      template: "<ui-view/>"
      resolve:
        structures: (CurrentCompanyService) ->
          CurrentCompanyService.getPermittedEntities("allowingToViewSurveysOverview")
        jobFamilies: (ProfessionalGroup, currentPerson) ->
          ProfessionalGroup.allForCompany(currentPerson.current_company.id)
        response: (SurveyRepository, Constants) ->
          SurveyRepository.getOverviewStatistics({ per_page: Constants.page_sizes.SURVEY_OVERVIEW })
      views:
        '@':
          controller: "SurveyOverviewCtrl"
          controllerAs: "ctrl"
          templateUrl: "surveys/overview/overviewList.html"

angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
    .state 'simple-goals',
      parent: 'protected'
      url: '/v3/goals'
      template: "<ui-view/>"
      resolve:
        manageAccess: (goalsAppAccessManager) ->
          goalsAppAccessManager.manageOwnGoalsAccess()
        options: (manageAccess) -> {
          path: 'goals',
          title: 'goals.mygoals',
          name: 'goals',
          personal: true,
          canAdd: true,
        }
      views:
        '@':
          controller: "ServiceFrameCtrl"
          controllerAs: "ctrl"
          templateUrl: "admin_panel/service_frame/serviceFrame.html"

angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
    .state 'simple-goals-overview',
      parent: 'protected'
      url: '/v3/goals-overview'
      template: "<ui-view/>"
      resolve:
        manageAccess: (goalsAppAccessManager) ->
          goalsAppAccessManager.manageEmployeeGoalsAccess()
        options: (manageAccess) -> {
          path: 'goals',
          title: 'goals.multiple',
          name: 'goals-overview'
          canAdd: true,
          filters: true,
        }
      views:
        '@':
          controller: "ServiceFrameCtrl"
          controllerAs: "ctrl"
          templateUrl: "admin_panel/service_frame/serviceFrame.html"

angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
    .state 'simple-goal-categories',
      parent: 'protected'
      url: '/v3/goal-settings'
      template: "<ui-view/>"
      resolve:
        manageAccess: (goalsAppAccessManager) ->
          goalsAppAccessManager.manageSettingsAccess()
        options: (manageAccess) -> {
          path: 'settings',
          title: 'goals.settings',
          name: 'goal-settings',
          canAdd: false,
        }
      views:
        '@':
          controller: "ServiceFrameCtrl"
          controllerAs: "ctrl"
          templateUrl: "admin_panel/service_frame/serviceFrame.html"
