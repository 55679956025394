angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'planning_classificator_settings',
        parent: 'protected'
        url: '/planning/settings/classificators'
        template: "<ui-view/>"
        views:
          '@':
            controller: "ClassificatorSettingsCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/settings/ClassificatorSettingsView.html"

class ClassificatorSettingsCtrl extends Controller
  constructor: () ->
