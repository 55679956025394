angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey_mass_drafts_errors',
        parent: 'protected'
        url: '/create_mass_drafts/{taskApiPath:string}/{taskId:int}/errors'
        params:
          pageSize: 50
        resolve:
          taskApiPath: ($stateParams) ->
            $stateParams.taskApiPath
          taskId: ($stateParams) ->
            $stateParams.taskId
          initialResponse: (taskApiPath, taskId, SurveyRepository, $stateParams) ->
            SurveyRepository.getMassDraftsErrors(taskApiPath, taskId, { per_page: $stateParams.pageSize })
        views:
          '@':
            controller: "MassDraftsErrorsCtrl"
            controllerAs: "ctrl"
            templateUrl: "surveys/massDraftCreation/massDraftsErrors.html"

class MassDraftsErrorsCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$translate, @$stateParams, @SurveyRepository, @Paging, @taskApiPath, @taskId, @initialResponse) ->
    @_pageErrors(@initialResponse)
    @anyKnownErrorsToShow = @initialResponse.data.length

  _pageErrors: (response) ->
    @paging = new @Paging(response)
    @errors = new Array(@paging.getTotalPages())
    @_addErrorsPage(response.data)

  changePage: (page) ->
    @paging.changePage(page)
    if _.isUndefined(@getForPage())
      @_fetchErrors(page)

  getForPage: () ->
    @errors[@paging.iterator()]

  _fetchErrors: (page) ->
    @$rootScope.isStateLoading = true
    @SurveyRepository.getMassDraftsErrors(
      @taskApiPath, @taskId, { page: page, per_page: @$stateParams.pageSize }
    ).then (response) =>
      @_addErrorsPage(response.data)
      @$rootScope.isStateLoading = false

  _addErrorsPage: (errors) ->
    @errors[@paging.iterator()] = errors

  translateErrorMessage: (error) ->
    text = error.error
    key = 'surveys.mass_drafts_creation.errors.' + text
    translated = @$translate.instant(key)
    if translated == key
      text.replaceAll('_', ' ')
    else
      translated
