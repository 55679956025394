angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $stateProvider
      .state 'training',
        parent: 'protected'
        url: '/trainings/{training_id:int}'
        abstract: true
        resolve:
          training: ($stateParams, Training) ->
            Training.one($stateParams.training_id)
