angular.module 'Upsteem'
  .directive 'profile', () ->
    restrict: 'E'
    templateUrl: 'templates/profile.html'
    scope:
      person: '='
      personId: '@'
    controller: ($scope, Restangular) ->
      if $scope.personId
        Restangular
          .one('people', $scope.personId)
          .get().then (profile) ->
            $scope.profile = profile
      else if $scope.person
        $scope.profile = $scope.person
