angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'person_profile',
        parent: 'protected'
        url: '/people/{id:int}/profile',
        params: {
          id: null
        }
        template: "<ui-view/>"
        resolve:
          response: ($stateParams, Person) ->
            Person.get($stateParams.id)
        views:
          '@':
            controller: "PersonProfileCtrl"
            controllerAs: "ctrl"
            templateUrl: "profiles/person/profile.html"

class PersonProfileCtrl extends Controller
  constructor: (response, @$rootScope, Person, @currentPerson) ->
    @person = new Person(response.data)
    @profile = @person.profile
    @phones = _.compact([@person.contacts.mobile_phone, @person.contacts.work_phone]).join(' / ')

  profileBelongsToCurrentPerson: =>
    if @person && @$rootScope.currentPerson
      @$rootScope.currentPerson.id == @person.id
    else
      false

  isSorainen: () ->
    @currentPerson.currentCompany.id == 1911        # DEV-745: Sorainen profile view changes

  isRemarkAdditionAllowed: ->
    @person.permissions.one("allowingToAddRemarks")

  isRemarksFetchingAllowed: ->
    @person.permissions.one("allowingToViewRemarks")

  allowedToUpload: =>
    @person.permissions.one("allowingToUploadReceivedFeedback")
