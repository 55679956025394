class OverviewFinishCtrl extends Controller
  constructor: (@$scope, @SurveyRepository, @toastr, @$translate, @targetIds, @roles, @callback) ->
    @targetCount = _.size(@targetIds)
    @notificationConfig = {}

  closeModal: () ->
    @$scope.modalInstance.close()

  handleResponse: (response) ->
    data = response.data
    @callback(data).then (callbackResult) =>
      if _.size(data.success) > 0
        @toastr.success @$translate.instant(
          "surveys.finish_success", { count: _.size(data.success) }
        )
      if _.size(data.errors) > 0
        @toastr.error  @$translate.instant(
          "surveys.finish_error", { count: _.size(data.errors) }
        )

  postRequest: () ->
    @SurveyRepository.massFinish(@targetIds, @notificationConfig)
      .then((response) => @handleResponse(response))
    @closeModal()
