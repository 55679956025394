angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey-projects',
        parent: 'protected'
        url: '/survey-projects'
        params:
          pageSize: 50
        template: '<ui-view/>'
        resolve:
          responseOptions: ($stateParams) ->
            { per_page: $stateParams.pageSize }
          response: (ProjectRepository, responseOptions) ->
            ProjectRepository.getSurveyProjectsOverview(responseOptions)
        views:
          '@':
            controller: "ProjectListCtrl"
            controllerAs: "ctrl"
            templateUrl: "survey_projects/list.html"

class ProjectListCtrl extends Controller
  constructor: (@Paging, @ProjectRepository, @Structure, @$stateParams, @responseOptions, response) ->
    @paging = new @Paging(response)
    @all = new Array(@paging.getTotalPages())
    @_addProjectsToList(response.data)

  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@all[@paging.iterator()])
      @_fetchProjects({ page: page })

  _fetchProjects: (options = {}) =>
    angular.extend options, @responseOptions
    @ProjectRepository.getSurveyProjectsOverview(options).then (response) =>
      @paging = new @Paging(response)
      @_addProjectsToList(response.data)

  _addProjectsToList: (projects) =>
    @all[@paging.iterator()] = _.map projects, (project) =>
      new @Structure(project)
