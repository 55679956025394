angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey-project',
        parent: 'protected'
        url: '/survey-projects/{projectId:int}'
        params:
          surveysPerPage: 20
        template: '<ui-view/>'
        resolve:
          project: (ProjectRepository, $stateParams) ->
            ProjectRepository.getSurveyProject($stateParams.projectId)
          surveysResponseOptions: ($stateParams) ->
            { per_page: $stateParams.surveysPerPage }
          surveysResponse: (ProjectRepository, project, surveysResponseOptions) ->
            ProjectRepository.getSurveyProjectSurveys(project.id, surveysResponseOptions)
          groupsResponse: (ProjectRepository, project) ->
            ProjectRepository.getSurveyProjectGroups(project.id)
        views:
          '@':
            controller: "SurveyProjectCtrl"
            controllerAs: "ctrl"
            templateUrl: "survey_projects/item.html"

class SurveyProjectCtrl extends Controller
  constructor: (@$rootScope, @$translate, @Constants, @project, @surveysResponseOptions, @surveysResponse, @groupsResponse) ->
    @reportLanguages = _.values @Constants.languages.short
    @reportLanguage = @$rootScope.currentPerson.language

  getLanguageText: (code) ->
    @$translate.instant 'general.languages.' + @Constants.languages.long[code]
