class RelationshipsModalCtrl extends Controller
  constructor: (@$scope) ->
    @planTypesList = [{name: "Eelarveplaan"}, {name: "Riskiplaan"}, {name: "Strateegiaplaan"}, {name: "Tööplaan"}]
    @strategyList = [{name: "Seapõletite plaan 2018"}]
    @relationshipType = [{name: "Ülenev"}, {name: "Alanev"}, {name: "Seotud"}]
    @selectedPlan = ""
    @selectedPlanType = ""
    @selectedRelationshipType = ""

  isErroneous: (field) -> (field.$dirty || field.$touched) && field.$invalid

  close: () ->
    @$scope.modalInstance.close()