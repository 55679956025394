class SurveyProjectGroupsCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$translate, @ProjectRepository, @SurveyReportGeneratorService, @SurveyResultUtils) ->
    @project = @$scope.$parent.ctrl.project
    groups = @$scope.$parent.ctrl.groupsResponse.data
    statistics = @SurveyResultUtils.countRespondersInSubjectGroups groups
    @SurveyResultUtils.attachStatisticsToGroups statistics, groups

    entities = groups.entities
    _.each entities, (entity) ->
      entity.rgGroupId = entity.id # ID for fetchGroupReport()
      entity.label = entity.structure_tree.join(' » ') # Display name in table

    @reportableProject = groups.entitiesById[@project.id] # Contains external responders associated with project
    @company = @SurveyResultUtils.findParentCompany(groups.entitiesById, @reportableProject)
    @company.rgGroupId = null # Main report does not accept an ID
    @company.label = @$translate.instant 'projects.project_overall'

    specialEntityIds = [@reportableProject.id, @company.id]
    @structure = _.filter groups.entities, (entity) => !_.includes(specialEntityIds, entity.id)

    @professionalGroups = groups.professional_groups
    _.each @professionalGroups, (group) ->
      group.rgGroupId = group.id
      group.label = group.name

    @tables = [
      { titleKey: 'survey.subject_department', data: [@company] },
      { titleKey: 'survey.subject_professional_group', data: @professionalGroups }
    ]

  isReportable: (group) ->
    group.statistics.all.all > 0

  fetchGroupReport: (groupId) ->
    @SurveyReportGeneratorService.fetchProjectReportPdf(
      @project.id, @$rootScope.currentPerson,
      { language: @_getReportLanguage(), profGroupId: groupId }
    )

  # Report language can change in parent controller, so we can't simply store it as @reportLanguage.
  _getReportLanguage: () ->
    @$scope.$parent.ctrl.reportLanguage
