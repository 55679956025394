class Remark extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      scope: {
        person: '=',
        style: '@'
      },
      templateUrl: 'directives/remark.html',
      controller: 'RemarkDirectiveCtrl',
      controllerAs: 'ctrl'
    }

class RemarkDirectiveCtrl extends Controller
  constructor: (@$rootScope, @$scope, @$modal, @PersonRepository) ->
    @$scope.personRepository = @PersonRepository
    @style = @$scope.style

  whiteButton: () ->
    @style != undefined && @style=='white';

  openModal: () ->
    @$modal.open
      windowClass: "medium"
      templateUrl: 'directives/remarkModal.html'
      controller: "RemarkModalCtrl"
      controllerAs: 'ctrl'
      scope: @$scope
      resolve:
        availablePeople: (@$rootScope, @PersonRepository) ->
          @PersonRepository.getFromStructure(
            @$rootScope.currentPerson.currentCompany, by_permission: "allowingToAddRemarks"
          ).then (response) ->
            return _.uniq(
              _.map(
                response.data, (
                  (person) -> {id:person.id, name:person.name}
                )
              )
            );
