class ServiceFrameCtrl extends Controller
  constructor: (@$sce, @currentPerson, options, @$rootScope, @$scope, @$modal, @$translate, @$window, config) ->
    @title = @$translate.instant(options.title)
    @iframeId = 'service-frame-' + @$scope.$id
    @simple = options.simple

    @baseUrl = (path, params = '') ->
      server = options.server
      lang = @currentPerson.language
      host = config.goals_app_url
      host + '/' + lang + '/' + options.path + '/' + path + '?' + params

    @label = (key) ->
      name = options.name
      name + '.' + key

    @showAddButton = options.canAdd

    tokenFilter = '&token=' + @currentPerson.token
    listFilter = tokenFilter
    if options.personal
      listFilter += '&personId=' + @currentPerson.id
    if options.filters
      listFilter += '&filter=1'
    @goalsUrl = @$sce.trustAsResourceUrl(@baseUrl('', listFilter))

    @subscribeToMessages()

  subscribeToMessages: () ->
    x = @
    @$window.addEventListener 'message', ((event) ->
      data = event.data
      token = data.token
      if token
        if data.action == 'contextDetected'
          x.contextedTokenReceived(token)
        else if data.action == 'edit'
          x.openModal('edit', '&id=' + data.id, data)
      return
    ), true

  # Now when we have a correct token, we can set up base urls for add and edit.
  contextedTokenReceived: (token) ->
    context = token.context
    @contextedToken = token
    @baseEditUrl = @baseUrl('edit', @paramsForToken(token))

  paramsForToken: (token, prefix = '') ->
    context = token.context
    prefix + 'token=' + token.value + '&usage=' + context.usage

  iframe: () ->
    iframe = document.getElementById(@iframeId)

  openModal: (action = 'add', params = '', data = {}) ->
    if @baseEditUrl
      @modalUrl = @$sce.trustAsResourceUrl(@baseEditUrl + params)
      @_determineGoalStatusAndAvailableActions(data)
      @$modal.open
        windowClass: "medium"
        templateUrl: 'directives/serviceModal.html'
        controller: "ServiceModalCtrl"
        controllerAs: 'ctrl'
        scope: @$scope
        resolve:
          url: () => @modalUrl
          title: () => @label(action)
          token: () => @contextedToken
          actionAvailabilities: () => @actionAvailabilities
          goalStatus: () => @goalStatus

  _determineGoalStatusAndAvailableActions: (data) ->
    @actionAvailabilities = data.actionAvailabilities || {}
    if data.id
      if data.archivedAt
        @goalStatus = 'archived'
      else
        @goalStatus = 'active'
    else
      @goalStatus = 'new'
      @actionAvailabilities.save = true
