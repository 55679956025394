class VacancyDeleteModalCtrl extends Controller
  constructor: (@$modalInstance, @Structure, @AffiliatableRole, @vacancy, roles) ->
    @_initializeRoles(roles)

  remove: ->
    deletableRoleIds = []
    _.each @roles, (role) ->
      if role.id && role.deletable
        deletableRoleIds.push(role.id)

    @vacancy.remove({ "role_ids[]": deletableRoleIds }).then =>
      @$modalInstance.close("closed")

  cancel: ->
    @$modalInstance.dismiss("dismissed")

  _initializeRoles: (roles) ->
    @roles = _.map roles, (role_data) =>
      role = new @AffiliatableRole(role_data)
      if role.entity
        role.entity = new @Structure(role_data.entity)
      role.deletable = true
      role
