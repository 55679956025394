angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
    .state 'design',
      parent: 'protected'
      url: '/design'
      template: "<ui-view/>"
      resolve:
        company_id: (currentPerson) ->
          currentPerson.currentCompany.id
        style: (AnsweringStyleRepository, company_id) ->
          AnsweringStyleRepository.getCompanyStyle(company_id).then (response) ->
            response.data
        styleOptions: (style) ->
          style.css_attributes
      views:
        '@':
          controller: "DesignCtrl"
          controllerAs: "ctrl"
          templateUrl: "admin_panel/design/design.html"

class DesignCtrl extends Controller
  constructor: (@ImageRepository, @AnsweringStyleRepository, @Paging, @$scope, @$rootScope, @$stateParams, @toastr, @$translate, @company_id, @style, @styleOptions) ->

  uploadImage: (file) =>
    try
      result = @ImageRepository.upload(file, @company_id)

      if(result != undefined)
        result.then (response) =>
          status = response.status
          if(status >= 200 && status < 300)
            @toastr.success @$translate.instant("uploads.uploaded")
            @AnsweringStyleRepository.getCompanyImage(@company_id).then (response) =>
              @style.image = response.data
          else
            @toastr.error @$translate.instant("uploads.upload_failed")
          @$rootScope.isStateLoading = false
      else
        @toastr.error @$translate.instant("uploads.upload_failed")

    catch e
      for error in _.uniq(e, false, (err) ->
          err[0]
        )
        @toastr.error @$translate.instant(error[0], error[1])

  save: (form) ->
    if form.$valid
      @style.css_attributes = @styleOptions
      @AnsweringStyleRepository.createNewStyle(@style, @company_id).then (response) =>
        @toastr.success @$translate.instant("design.design_saved")
