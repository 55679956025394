angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'email_statistics',
        parent: 'protected'
        url: "/email_statistics"
        params:
          bounced: null
          survey_id: null
          pageSize: 50
        template: "<ui-view/>"
        resolve:
          emails: ($stateParams, @EmailRepository) ->
            @EmailRepository.getAll({ per_page: $stateParams.pageSize })
          structures: (CurrentCompanyService) ->
            CurrentCompanyService.getPermittedEntities("allowingToViewEmailStatistics")
          selectedStructures: (structures) ->
            structures.map((el) -> el.id)
        views:
          '@':
            controller: "EmailStatisticsCtrl"
            controllerAs: "ctrl"
            templateUrl: "emailStatistics/emailStatistics.html"

class EmailStatisticsCtrl extends Controller
  constructor: (
    @$scope,
    @$state,
    @$stateParams,
    @currentPerson,
    @$translate,
    emails,
    Constants,
    @Email,
    @EmailRepository,
    @Paging,
    structures,
    selectedStructures
  ) ->
    @paging = new @Paging(emails)
    @all = new Array(@paging.getTotalPages())
    @surveyCategories = _.map Constants.survey_types, (type) -> type
    @structures = structures
    @defaultSelectedStructures = selectedStructures
    @availableStatuses = ['dispatched', 'sent', 'open', 'click', 'bounce', 'blocked', 'spam', 'unsub']
    @filterOptions = { 'statuses[]': angular.copy(@availableStatuses) }
    if @$stateParams.bounced? then @applyPrefilters() else @addEmailsToList(emails.data)
    @addDateRangePickerOptions()
    @initializeTooltips()
    @clear = false

  sortByDesc: (emails) ->
    sortedAsc = _.sortBy emails, (email) -> email.latest_event.event_registered_at
    sortedAsc.reverse()

  applyPrefilters: =>
    @filterOptions['statuses[]'] = if @$stateParams.bounced then @failedEmailStatuses() else @successfulEmailStatuses()
    @filterOptions.survey_id = @$stateParams.survey_id
    @fetchFilteredEmails()

  failedEmailStatuses: ->
    ['bounce', 'blocked', 'spam', 'unsub']

  successfulEmailStatuses: ->
    ['dispatched', 'sent', 'open', 'click']

  # Not the best way, but there is no need to attach colors to api payload
  fetchEventColor: (event) ->
    switch event
      when "dispatched" then "grey"
      when "sent" then "blue"
      when "open" then "limegreen"
      when "click" then "green"
      when "bounce" then "red"
      when "blocked" then "black"
      when "spam" then "orange"
      when "unsub" then "aqua"
      else "black"

  fetchFilteredEmails: (page = null) =>
    @filterOptions.per_page = @$stateParams.pageSize
    @filterOptions.page = 1 unless page
    if (@period != null) && @period
      @filterOptions.date_from = @period.startDate
      @filterOptions.date_to = @period.endDate
    if @filterOptions["structures[]"] && @filterOptions["structures[]"].length == 0
      delete @filterOptions["structures[]"]
    @fetchEmails()

  fetchEmails: =>
    @EmailRepository.getAll(@filterOptions).then (response) =>
      @paging = new @Paging(response)
      @addEmailsToList(response.data)

  addEmailsToList: (response) =>
    emails = _.map response, (email) => new @Email(email)
    @all[@paging.iterator(@paging.page)] = @sortByDesc(emails)

  changePage: (page) =>
    @paging.changePage(page)
    @filterOptions.page = page
    @fetchFilteredEmails(page)

  clearFilters: () ->
    @clear = !@clear
    @filterOptions = {
      'statuses[]': angular.copy(@availableStatuses)
    }
    @clearDateRange()
    @addDateRangePickerOptions()
    @fetchFilteredEmails()

  clearDateRange: () ->
    angular.element(
      document.querySelectorAll('#period')).html(@$translate.instant('general.period')
    )

  addDateRangePickerOptions: =>
    @period = {
      period: {}
    }

    @rangeOpts = {
      locale: {
        format: 'DD.MM.YYYY',
        firstDay: 1
      }
      startDate: moment().utc().startOf('isoWeek'),
      endDate: moment().utc(),
      opens: 'left',
      ranges: {
        'This Week': [
          moment().utc().startOf('isoWeek'),
          moment().utc().endOf('isoWeek')
        ],
        'Last Week': [
          moment().utc().subtract(1, 'week').startOf('isoWeek'),
          moment().utc().subtract(1, 'week').endOf('isoWeek')
        ],
        'This Month': [
          moment().utc().startOf('month'),
          moment().utc().endOf('month')
        ],
        'Last Month': [
          moment().utc().subtract(1, 'month').startOf('month'),
          moment().utc().subtract(1, 'month').endOf('month')
        ],
        'This Year': [
          moment().utc().startOf('year'),
          moment().utc().endOf('year')
        ],
        'Last Year': [
          moment().utc().subtract(1, 'year').startOf('year'),
          moment().utc().subtract(1, 'year').endOf('year')
        ]
      }
      autoApply: true
      eventHandlers: {
        'apply.daterangepicker': (ev, picker) =>
          angular.element(document.querySelectorAll('#period')).html(
            ev.model.startDate.format('DD.MM.YYYY') + ' - ' + ev.model.endDate.format('DD.MM.YYYY')
          )
          @fetchFilteredEmails()
      }
    }

  initializeTooltips: ->
    @$translate.use(@currentPerson.profile_language)
    @$scope.dispatched = { "title": @$translate.instant('emails.dispatched') }
    @$scope.sent = { "title": @$translate.instant('emails.sent') }
    @$scope.open = { "title": @$translate.instant('emails.open') }
    @$scope.click = { "title": @$translate.instant('emails.click') }
    @$scope.bounce = { "title": @$translate.instant('emails.bounce') }
    @$scope.blocked = { "title": @$translate.instant('emails.blocked') }
    @$scope.spam = { "title": @$translate.instant('emails.spam') }
    @$scope.unsub = { "title": @$translate.instant('emails.unsub') }
