angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'structures.people',
        url: '/people'
        params:
          page: 1
        resolve:
          mainStructure: ($stateParams, Structure) ->
            Structure.structureWithDecendants($stateParams.structure_id, { page: 1, per_page: 1 }).then (resp) ->
              resp.data[0]
          response: ($stateParams, mainStructure, Structure, Constants) ->
            Structure.structureWithDescendantsAndRelatedInfo(mainStructure && mainStructure.id, { page: $stateParams.page, per_page: Constants.structures.PAGE_SIZE })
        views:
          '@':
            templateUrl: "structures/people/list/structuresPeopleList.html"
            controller: "StructuresPeopleListRouteCtrl"
            controllerAs: 'ctrl'

class StructuresPeopleListRouteCtrl extends Controller
  constructor: (@$scope, @mainStructure, response, @currentPerson, TableOptions, @Constants, @Structure, @Paging) ->
    @paging         = new @Paging(response)
    @structures     = new Array(@paging.getTotalPages())
    @phrases = {}

    @tableOptions   = new TableOptions(translationScope: 'employment')

    @availablePhrases = [
      {
        translation: "general.structure_unit",
        phrase: "structure_name"
      },
      {
        translation: "general.name",
        phrase: "person_name",
      },
      {
        translation: "vacancy.role_name",
        phrase: "position"
      }
    ]

    @addStructuresToArray(response.data, @paging.iterator(@paging.page))

  getStructuresWithFilteredPeople: ->
    @fetchStructures({ reset: true })

  addStructuresToArray: (structures, position) =>
    @structures[position] = _.map structures, (structure) => new @Structure(structure)

  changePage: (page) =>
    @paging.changePage(page)

    if _.isUndefined(@structures[@paging.iterator(page)])
      @fetchStructures({ page: page })

  fetchStructures: (options = {}) =>
    _.each @phrases, (v, k) =>
      if _.isEmpty(v)
        delete @phrases[k]

    params = @_paramsForFetch(options)
    @Structure.structureWithDescendantsAndRelatedInfo(@mainStructure.id, params).then (response) =>
      @paging = new @Paging(response)
      if options.reset
        @structures = new Array(@paging.getTotalPages())
      @structures[@paging.iterator(@paging.page)] = _.map response.data, (structure) => new @Structure(structure)

  _paramsForFetch: (options = {}) =>
    page = options.page || 1
    params = { page: page, per_page: @Constants.structures.PAGE_SIZE }
    angular.extend(params, @phrases)
    params
