class VacancyEditRouteCtrl extends Controller
  constructor: (@vacancy, @employment, @person, @allJobFamilies, @$translate, @$state, @$modal, @toastr, @PersonSearchService, @EmploymentService, @currentPerson, @Vacancy, @Employment) ->

    @formData = {
      job_families: @vacancy.job_families,
      role_name: @vacancy.role_name,
      send_confirmation_message: true
    }

    if @employment
      @formData.employed_at = @employment.employed_at
      @formData.employed_until = @employment.employed_until

  selectedFamilies: ->
    _.map @formData.job_families, (family) -> family.id

  availableJobFamilies: =>
    ids =  @selectedFamilies()
    _.reject @allJobFamilies, (jobFamily) =>
      _.includes ids, jobFamily.id

  save: () ->
    if @employment?
      @update().then =>
        @redirect()
    else if @PersonSearchService.isPersonPresent()
      @PersonSearchService.getPerson().then (person) =>
        @PersonSearchService.selectedPerson = undefined
        @Employment.create(@vacancy.id, { person_id: person.id, send_confirmation_message: @formData.send_confirmation_message }).then =>
          @PersonSearchService.newPerson = undefined
          @PersonSearchService.newPersonForm = undefined
          @update().then =>
            @redirect()
    else
      @redirect()

  update: () ->
    options = @formData
    options.professional_group_ids = @selectedFamilies()
    delete options['job_families']
    @Vacancy.update(@vacancy.id, options)

  redirect: ->
    type = @currentPerson.currentCompany.type.tableize()
    id   = @currentPerson.currentCompany.id

    @$state.go('structures.people', {structure_type: type, structure_id: id})

  isPersonPresent: ->
    @employment? || @PersonSearchService.isPersonPresent()

  resetEmploymentDates: ->
    @formData.employed_at = undefined
    @formData.employed_until = undefined

  searchAgain: ->
    @resetEmploymentDates()
    @PersonSearchService.searchAgain()

  terminateEmploymentOnMissingRoles: ->
    @EmploymentService.getDeletableRoles(@employment.id).then (roles) =>
      if _.isEmpty(roles)
        @terminateEmployment()
      else
        @openEmploymentTerminationModal(roles)

  terminateEmployment: ->
    @EmploymentService.terminate(@employment.id).then =>
      @_employmentTerminated()

  openEmploymentTerminationModal: (roles) =>
    @$modal.open
      windowClass: "medium"
      templateUrl: "employment/terminate/employmentTerminate.html"
      controller: "EmploymentTerminateModalCtrl"
      controllerAs: "employmentTerminateCtrl"
      resolve:
        employment: => @employment
        person: => @person
        roles: => roles
    .result.then =>
      @_employmentTerminated()

  _employmentTerminated: ->
    @PersonSearchService.searchAgain()
    @employment = undefined
    @person     = undefined
    @resetEmploymentDates()
    @toastr.success @$translate.instant('employment.terminated')
