class RespondersRepository extends Service
  constructor: (@Restangular, @$rootScope) ->

  getSurveyWithToken: (token) ->
    @Restangular.all('responders_by_token').get(token)

  addExternalResponder: (responder) ->
    @Restangular.all('responders_by_token/invite').post(responder)

  deleteExternalResponder: (responder) ->
    @Restangular.all('responders_by_token/delete').post(responder)
