class DefaultGroupRowCtrl extends Controller
  constructor: (@$scope, @SurveyResponderRoleRepository, @LanguageService) ->
    @group = @$scope.group
    @currentLanguage = @LanguageService.selectedLanguage.short
    @rolesVisible = false
    @roles = []

  roleInCurrentLocale: (role) ->
    role.roleForLocale(role.languageOrGivenLocale(@currentLanguage))

  showRoles: () ->
    @rolesVisible = !@rolesVisible
    if @rolesVisible && @roles.length == 0
      @SurveyResponderRoleRepository.getRoles(@group.id).then (roles) =>
        @roles = roles
