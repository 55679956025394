class TodosCtrl extends Controller
  constructor: (@$state, @$scope, response, @Survey, @SurveyRepository, @Paging, TableOptions, @$rootScope, @$translate) ->
    @paging = new @Paging(response)
    @options = {person_id: @$rootScope.currentPerson.id}
    @SurveyRepository.initialize(@paging, @options)

    @tableOptions = new TableOptions(translationScope: 'surveys')
    @categories = @Survey.categories()
    @states = @Survey.statuses(@$rootScope.currentPerson.permissions.one('isManager'))
    @initializeTooltips()

  answeringLink: (survey) =>
    survey.answeringLink(@$rootScope.config.answering_app_host)

  changePage: (page) =>
    @paging.changePage(page)

    surveys = @SurveyRepository.fetchSurveysForPage(@paging, @options)
    if(surveys != undefined)
      surveys.then (paging) =>
        @paging = paging

  getForPage: () ->
    @SurveyRepository.getForPage(@paging)

  fetchFilteredSurveys: () ->
    @options.page = 1
    @changePage()

  emailStatistics: (survey) ->
    @$state.go('email_statistics', {bounced: survey.bounced_emails, survey_id: survey.id})

  describeSurvey: (survey) ->
    text = @translateCategory(survey.category)
    if survey.type
      text += ", " + @$translate.instant("surveys.type." + survey.type).toLowerCase()
    text

  translateCategory: (category) ->
    @$translate.instant(@_getCategoryKey(category))

  # DEV-895 specific hack
  _getCategoryKey: (category) ->
    if @$rootScope.currentPerson.currentCompany.id == 1911
      "surveys." + category + ".sorainen"
    else
      "surveys." + category + ".default"

  canShowSurveyLinkToCurrentApp: (survey) ->
    @isSurveyLinkPermitted(survey) && survey.canRenderSurveyWithinCurrentApp()

  isSurveyLinkPermitted: (survey) ->
    survey.permissions.one("allowingToEditSurvey")

  initializeTooltips: ->
    @$translate.use(@$rootScope.currentPerson.profile_language)
    @$scope.check_emails = { "title": @$translate.instant('emails.check_emails') }
