angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'planning',
        parent: 'protected'
        url: "/planning"
        template: "<ui-view/>"
        params: {
          'saved': false,
          'title': "",
          'sum': 0
        }
        resolve:
          activityFilters: (MyActivityListService) -> MyActivityListService.getFilters()
          planList: (MyActivityListService, currentPerson) -> MyActivityListService.getAllPlans(currentPerson.currentEmployment.id)
        views:
          '@':
            controller: "MyActivityListCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/myActivityList.html"

      .state 'planning_structure',
        parent: 'planning'
        url: "/planning/structure"
        template: "<ui-view/>"
        views:
          '@':
            controller: "MyActivityListCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/planningStructureTable.html"

class MyActivityListCtrl extends Controller
  constructor: (
    @$state,
    activityFilters,
    planList,
    @MyActivityListService
  ) ->

    @activitiesVisibility = false

    @plans = planList
    @planItems = []

    @activityStatusList = activityFilters.statusList
    @activityDepartmentList = activityFilters.departmentsList
    @activityTypeList = activityFilters.activityTypesList
    @activityPlanTypeList = activityFilters.planTypesList
    @activityPeopleList = activityFilters.peopleNamesList
    @activityPlanNameList = activityFilters.planNamesList

    @activitySelectStatus = [{name: "Vali staatus"}]
    @activitySelectDepartment = [{name: "Vali osakond"}]
    @activitySelectType = [{name: "Vali tegevuse tüüp"}]
    @activityBudgetPlan = [{name: "Eelarveplaan"}]
    @activitySelectPlanType = [{name: "Vali plaani tüüp"}]
    @activityFindPeople = [{name: "Otsi vastutaja"}]
    @activityFindPlanName = [{name: "Otsi plaani nime"}]

    @activityPlanLevel = [{name: "Ministeerium"}, {name: "VV Tegevuskava"}, {name: "Riiklik tegevuskava"}, {name: 'Tööplaan'}, {name: 'Strateegia'}]
    @activityPlanDefaultLevel = @activityPlanLevel[0]

    @planExamples = [
      {planId: 1, planName: 'Rahvastiku tervise arengukava 2009-2020; RUP ETKI (MeM eelarveväline)', planType: 'Tööplaan',
      planVoucher: 'Riina Kurm', planLevel: 'Ministeerium', subPlans: [
        {subPlanId: 1, subPlanName: 'Maaelu ministeeriumi hankeplaan 2018', subPlanType: 'Eelarve', subPlanVoucher: 'Ain Soome', subPlanLevel: 'Ministeerium'}
        ]},
      {planId: 2, planName: 'Meede 1.1.3: Toidukäitleja teadmiste ja oskuste täiendamisele', planType: 'Strateegia',
      planVoucher: 'Eduard Koitmaa', planLevel: 'Ministeerium'},
      {planId: 3, planName: 'Seakatku riski maandamise kava', planType: 'Riskid', planVoucher: 'Liina Kaljula', planLevel: 'VV tegevuskava'},
      {planId: 4, planName: 'Seadmete hankimise plaan', planType: 'Projekt', planVoucher: 'Liina Kaljula', planLevel: 'Riiklik tegevuskava'}
    ]

  toggleClass: ($event, tableIndex, $index) ->
    # Init var
    clickedElement

    # Get correct element based on id
    if ($event.target.id == 'activityLock-' + tableIndex + '-' + $index)
      clickedElement = angular.element(document.querySelector('#activityLock-' + tableIndex + '-' + $index))
    else if ($event.target.id == 'flag-' + tableIndex + '-' + $index)
      clickedElement = angular.element(document.querySelector('#flag-' + tableIndex + '-' + $index))
    else if ($event.target.id == 'mainLock-' + $index)
      clickedElement = angular.element(document.querySelector('#mainLock-' + $index))

    # This is for locks
    if (clickedElement.hasClass('lock'))
      if (clickedElement.hasClass('fa-unlock'))
        clickedElement.removeClass('fa-unlock')
        clickedElement.toggleClass('fa-lock')
      else if (clickedElement.hasClass('fa-lock'))
        clickedElement.removeClass('fa-lock')
        clickedElement.toggleClass('fa-unlock')
    # This is for flags
    else if (clickedElement.hasClass('flag'))
      if clickedElement.hasClass('empty-filling')
        clickedElement.removeClass('empty-filling')
        clickedElement.toggleClass('done-filling')
      else if clickedElement.hasClass('done-filling')
        clickedElement.removeClass('done-filling')
        clickedElement.toggleClass('current-filling')
      else if clickedElement.hasClass('current-filling')
        clickedElement.removeClass('current-filling')
        clickedElement.toggleClass('not-filling')
      else if clickedElement.hasClass('not-filling')
        clickedElement.removeClass('not-filling')
        clickedElement.toggleClass('empty-filling')

    return false

  getActivities: (planObject) =>
    if _.isEmpty planObject.planItems
      @MyActivityListService.getPlanActivities(planObject.planId).then (response) =>
        _.forEach(response, (activity) ->
          planObject.planItems.push(activity)
        )

      console.log planObject.planItems

    planObject.isVisible()

  newActivity: (planId) ->
    @$state.go('activity.new', { planId: planId })

  editActivity: (activityObject) ->
    @$state.go('activity.edit', { activityId: activityObject.activityId})

  newPlan: () ->
    @$state.go('plan.new')

  editPlan: (id) ->
    @$state.go('plan.edit', { planId: id})

  fileHeaders: => [
    'responsible_employment_id',
    'created_by_employment_id'
    'title',
    'description',
    'start_date',
    'end_date',
    'planned_budget',
    'actual_budget',
    'locked',
    'status',
    'category_type',
    'level'
  ]

  downloadCSV: =>
    plans = []
    _.each @plans, (plan) ->
      # console.log plan
      plans.push(
        {
          responsible_employment_id: plan.responsibleEmploymentId,
          created_by_employment_id: plan.createdByEmploymentId,
          title: plan.title,
          description: plan.description,
          start_date: plan.startDate,
          end_date: plan.endDate,
          planned_budget: plan.plannedBudget,
          actual_budget: plan.actualBudget,
          locked: plan.locked,
          status: plan.status,
          category_type: plan.typeId,
          level: plan.level
        }
      )
    plans


#  OLD FOR BACKUP
#  getActivities: =>
#    @toggleActivitiesVisibility()
#
#    if _.isEmpty @planItems
#      @MyActivityListService.getPlanActivities().then (response) =>
#        @planItems = response.data
