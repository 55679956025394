angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'plan',
        parent: 'protected'
        url: '/planning'
        abstract: true
        params: {
          planId: undefined,
        }
        resolve:
          currentPlan: () -> {}
          people: (Employment, currentPerson) ->
            Employment.shortEmploymentsForSorainen(currentPerson.currentCompany.id).then (employments) ->
              _.uniq(_.map(_.sortBy(employments, 'name'), (person) -> {id: person.id, name: person.name}))
        views:
          '@':
            controller: "SingleplanCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/plans/single/singlePlanView.html"

      .state 'plan.new',
        parent: 'plan'
        url: '/planning/plan/new'
        template: "<ui-view />"
        views:
          '@':
            controller: "SingleplanCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/plans/single/singlePlanView.html"

      .state 'plan.edit',
        parent: 'plan'
        url: '/plan/edit/{planId: int}'

        template: "<ui-view />"
        resolve:
          currentPlan: ($stateParams, SinglePlanService) ->
            if $stateParams? && $stateParams.planId?
              return SinglePlanService.getPlanData($stateParams.planId)
            else
              return SinglePlanService.createSinglePlanInstance()
        views:
          '@':
            controller: "SingleplanCtrl"
            controllerAs: "ctrl"
            templateUrl: "planning/plans/single/singlePlanView.html"

class SingleplanCtrl extends Controller
  constructor: (@$scope, @$state, @$modal, @$stateParams, @$rootScope, @SinglePlanService, people, currentPlan) ->

    @selectedType = currentPlan.type
    @typeList = @SinglePlanService.getTypeList()

    @selectedOwner = @SinglePlanService.getSelectedOwner(people, currentPlan)

    @ownerList = people

    @selectedLevel = @SinglePlanService.getCurrentLevel(currentPlan)

    @levelList = @SinglePlanService.getLevelList()

    @title = currentPlan.title
    @description = currentPlan.description
    @expectedResult = currentPlan.expectedResult
    @startDate = currentPlan.startDate
    @endDate = currentPlan.endDate

    @plannedBudget = currentPlan.plannedBudget

  save: () ->
    planObject = @SinglePlanService.createSinglePlanInstance()
    planObject.typeId = @selectedType
    #planObject.levelId = @selectedLevel.id
    planObject.responsibleEmploymentId = @selectedOwner.id
    planObject.title = @title
    planObject.description = @description
    planObject.expectedResult = @expectedResult
    planObject.startDate = @startDate
    planObject.endDate = @endDate
    planObject.plannedBudget = @plannedBudget

    if @$state.current.name = 'plan.new'
      @SinglePlanService.APICreatePlan(planObject)
    @goBackToOldState()

  goBackToOldState: () =>
    console.log @$rootScope.previousState
    if @$rootScope.previousState.length
      @$state.go(@$rootScope.previousState)
      return
    @$state.go("planning_structure")
