class OneChoice extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '='
      },
      controller: 'OneChoiceCtrl',
      controllerAs: 'ctrl'
      templateUrl: 'directives/survey_result/one_choice.html'
    }

class OneChoiceCtrl extends Controller
  constructor: (@$scope, @$rootScope) ->
    @index = @$scope.index
    @data = @$scope.data
    @groupedAnswers = @_groupedAnswers(@data.answers)

  _groupedAnswers: (answers) ->
    _.groupBy(answers, (answer) -> answer.content.choice)
