class RemarkRow extends Directive
  constructor: () ->
    return {
      scope: {
        remark: '='
      }
      templateUrl: 'directives/remarkRow.html',
      controller: 'RemarkRowCtrl',
      controllerAs: 'ctrl'
    }

class RemarkRowCtrl extends Controller
  constructor: (@$rootScope, @$scope) ->
    @remark = @$scope.remark

  remarkByCurrentPerson: =>
    if @remark && @$rootScope.currentPerson
      @$rootScope.currentPerson.id == @remark.from.id
    else
      false
