class Document extends Service
  constructor: (Base, Restangular, Permissions, Constants, $state, toastr, $translate, $http, $rootScope) -> return class Document extends Base
    constructor: (json) ->
      angular.extend @,json

    @upload: (file, person) ->
      @allowedFiles = ["pdf"]
      @maxSize = 10000000
      errors = @validate(file)

      if(file != null && errors.length == 0)
        $rootScope.isStateLoading = true

        formData = new FormData()
        formData.append('file', file)
        formData.append('person', person)

        # Bug, restangular with Content-Type undefined returns 400
        $http.post("/api/v2/documents/surveys.json", formData, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        })
      else
        throw(errors)

    @downloadSurvey: (survey, file) ->
      window.location = "/api/v2/documents/"+file

    @validate: (file) ->
      return @_validExtension(file).concat(@_sizeLimitOk(file).concat(@_validContentType(file)))

    @_validContentType: (file) ->
      valid = false

      for type in @allowedFiles
        valid = (file.type == ("application/"+type))
        break if valid
      if valid then [] else [['uploads.file_type', {types: "PDF"}]]



    @_validExtension: (file) ->
      extension = file.name.substr(file.name.lastIndexOf(".")+1).toLowerCase()
      if (@allowedFiles.indexOf(extension) > -1) then [] else [['uploads.file_type', {types: "PDF"}]]


    @_sizeLimitOk: (file) ->
      if (@maxSize > file.size) then [] else [['uploads.file_size', {size: "10MB"}]]
