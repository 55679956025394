class Goal extends Service
  constructor: (Base, Restangular, Permissions, Constants, Employment, $state, toastr, $translate) -> return class Goal extends Base
    constructor: (json) ->
      angular.extend @, json

      @deciderEmployment = new Employment(@decider_employment) if @decider_employment
      @ownerEmployment   = new Employment(@owner_employment)   if @owner_employment
      @permissions       = new Permissions(@permissions)

      @people = []

      if json.affiliations?
        for person in json.affiliations
          @addPerson(person)


    @statuses:         -> _.map Constants.goals_statuses, (status) -> status
    @mapGoals: (goals) -> _.map goals, (goal) -> new Goal(goal)

    addPerson: (person) =>
      return if person.id in @people
      @people.push person.id

    updatePeople: (people) =>
      @people = _.pluck(people, 'id')

    @allForEmployment: (options = {}) ->
      Restangular.all('goals').getList(options).then (response) -> response

    @one: (goal_id) ->
      Restangular.one('goals', goal_id).get().then (response) ->
        new Goal(response.data)

    @create: (goal) ->
      Restangular.all('goals').post(goal).then (response) ->
        response.data

    sendReminder: ->
      Restangular.all('reminders').all('goals').post({goal_id: @id}).then ->
        toastr.success $translate.instant('general.reminder_sent')

    setDone: ->
      @status = Constants.goals_statuses.DONE
      @progress = @target
      @responsible = ""

      @.one('done').customPUT(@).then (response) =>
        @updateFields(response.data)

    update: ->
      @responsible = ""
      @customPUT(@).then (response) =>
        @updateFields(response.data)

    startGoal: ->
      @status = Constants.goals_statuses.IN_PROGRESS
      @update()

    updateFields: (updated_goal) ->
      @description = updated_goal.description
      @start_date  = updated_goal.start_date
      @end_date    = updated_goal.end_date
      @responsible = updated_goal.responsible
      @weight      = updated_goal.weight
      @progress    = updated_goal.progress
      @status      = updated_goal.status
      @target      = updated_goal.target

      @deciderEmployment =
        new Employment(updated_goal.decider_employment) if updated_goal.decider_employment

      return @

    isTargetExceeded: ->
      @progress > @target

    currentProgress:  ->
      progress = 0
      unless @progress == 0 || @target == 0
        progress = ((@progress / @target) * 100).toFixed(1)

      progress

    lessThanTenDone:     -> @currentProgress() <= 10
    lessThanQuarterDone: -> @currentProgress() <= 25
    halfOrLessDone:      -> @currentProgress() <= 50
    done:                -> @currentProgress() == 100

    isInProgress: -> @status == Constants.goals_statuses.IN_PROGRESS
    isDone:       -> @status == Constants.goals_statuses.DONE
    isStarted:    -> @isInProgress() || @isDone()

    canCurrentPersonEditOnlyProgress: ->
      @isInProgress() && @permissions.one('allowingToEditOnlyProgress')

    isEditable: ->
      (!@progressUpdateAllowed() || @ownGoalUpdateAllowed() || @isInProgress()) && @permissions.one('allowingToUpdate')

    partialUpdateAllowed: () ->
      @progressUpdateAllowed() || @ownGoalUpdateAllowed()

    progressUpdateAllowed: () ->
      @permissions.one('allowingToEditOnlyProgress')

    ownGoalUpdateAllowed: () ->
     @permissions.one('allowingToEditOwnGoal')

    affilationIds: ->
      _.map @affiliations, (a) -> a.id

    progressBarFontColor: -> unless @lessThanTenDone() then 'white'
    progressBarClass:     ->
      if @halfOrLessDone() then 'alert' else if @done() then 'success' else 'secondary'
