class TrainingsRowCtrl extends Controller
  constructor: ($scope) ->
    @trainings  = $scope.employmentWithTrainings.trainings
    @employment = $scope.employmentWithTrainings

    @trainingsVisible = false

  trainingEnrollmentForPerson: (training) => training.enrollmentForPerson(@employment.id)
  toggleTrainingsVisibility: => @trainingsVisible = !@trainingsVisible

  removeEnrollmentForPerson: (training) ->
    training.removeEnrollment(@trainingEnrollmentForPerson(training)).then =>
      @trainings = _.without(@trainings, training)
