class Kpi extends Service
  constructor: (Base, Restangular) -> return class Kpi extends Base
    constructor: (json) ->
      angular.extend @, json
      @getDefault()

    @delete:(kpi) ->
      Restangular.one('kpis', kpi.id).remove();

    @create:(kpi) ->
      Restangular.all('kpis').post(kpi).then (response) -> response;

    @update:(kpi) ->
      Restangular.all('kpis').put(kpi).then (response) -> response;

    @getDefault:() ->
      Restangular.all('kpis').getList().then (response) -> response;

    @getCustom:() ->
      Restangular.all('kpis').get('custom').then (response) -> response;

    @getValues:() ->
    	Restangular.all('kpis').get('values').then (response) -> response;
