class SurveyResultUtils extends Service
  constructor: () ->

  extendData: (data) ->
    data.entitiesById = @_mapById data.entities
    @_extendEntities data.entities, data

    data.professionalGroupsById = @_mapById data.professional_groups
    data.ageGroupsById = @_mapById data.age_groups
    data.tenureGroupsById = @_mapById data.tenure_groups

    @_extendSurveys(data.surveys, data)

    data

  _mapById: (items) ->
    mapping = {}
    _.each items, (item) ->
      mapping[item.id] = item
    mapping

  _extendEntities: (entities, data) ->
    _.each entities, (entity) =>
      @_extendEntity entity, data

  _extendEntity: (entity, data) ->
    entity.withAncestors = [entity]
    @_addEntityAncestors entity, entity, data
    entity.path = _.map entity.withAncestors, (e) -> e.id

  _addEntityAncestors: (target, entity, data) ->
    if entity.entity_id
      parent = data.entitiesById[entity.entity_id]
      if parent
        target.withAncestors.unshift parent
        @_addEntityAncestors target, parent, data

  _extendSurveys: (surveys, data) ->
    subjectRespondersBySurveyId = {}
    respondersBySurveyId = {}

    _.each data.responders, (responder) =>
      subjectRespondersBySurveyId[responder.survey_id] = responder if responder.subject
      @_addToKeyedList respondersBySurveyId, responder.survey_id, responder

    _.each surveys, (survey) ->
      survey.subjectResponder = subjectRespondersBySurveyId[survey.id]
      survey.responders = respondersBySurveyId[survey.id] || []

  countRespondersInSubjectGroups: (data, options = {}) ->
    surveysBySubjectGroup = @_mapSurveysBySubjectGroup data.surveys, data
    result = {}
    _.each surveysBySubjectGroup, (groups, type) =>
      ofType = {}
      _.each groups, (surveys, groupId) =>
        ofGroup = @_initializeResponderStatistics()
        _.each surveys, (survey) =>
          @_countSurveyRespondersInto(ofGroup, survey, options)
        ofType[groupId] = ofGroup
      result[type] = ofType
    result

  countSurveyResponders: (survey, options = {}) ->
    result = @_initializeResponderStatistics()
    @_countSurveyRespondersInto(result, survey, options)
    result

  _countSurveyRespondersInto: (statistics, survey, options = {}) ->
    _.each survey.responders, (responder) =>
      @_countResponder responder, statistics.all
      if responder.subject
        @_countResponder responder, statistics.subject
      else
        roleContainer = statistics.byRole[responder.role]
        @_countResponder responder, roleContainer if roleContainer
        @_countResponder responder, statistics.exceptSubjectAndManager unless responder.role == 'manager'

    @_addSecondaryStatistics(statistics, options)

  _countResponder: (responder, target) ->
    target.all += 1
    target.completed += 1 if responder.completed

  _addResponseRate: (responderCounts, precision) ->
    responderCounts.ratioString = responderCounts.completed + '/' + responderCounts.all
    if responderCounts.all == 0
      responderCounts.percentage = 0
    else
      responderCounts.percentage = _.round responderCounts.completed * 100 / responderCounts.all, precision
    responderCounts.percentageString = responderCounts.percentage.toFixed(precision) + '%'

  _mapSurveysBySubjectGroup: (surveys, data) ->
    mapping = {
      entities: {},
      cumulativeEntities: {},
      professionalGroups: {},
      ageGroups: {},
      tenureGroups: {}
    }

    _.each surveys, (survey) =>
      responder = survey.subjectResponder
      if responder
        @_addToKeyedList mapping.entities, responder.entity_id, survey
        entity = responder.entity_id && data.entitiesById[responder.entity_id]
        if entity
          @_addToKeyedLists mapping.cumulativeEntities, entity.path, survey

        if _.isEmpty responder.professional_group_ids
          @_addToKeyedList mapping.professionalGroups, null, survey
        else
          @_addToKeyedLists mapping.professionalGroups, responder.professional_group_ids, survey

        @_addToKeyedList mapping.ageGroups, responder.age_group_id, survey
        @_addToKeyedList mapping.tenureGroups, responder.tenure_group_id, survey
        
    mapping

  _addToKeyedLists: (mapping, keys, item) ->
    _.each keys, (key) =>
      @_addToKeyedList mapping, key, item

  _addToKeyedList: (mapping, key, item) ->
    if !mapping[key]
      mapping[key] = []
    mapping[key].push item

  attachStatisticsToGroups: (statistics, data) ->
    _.each data.entities, (entity) =>
      entity.statistics = statistics.cumulativeEntities[entity.id] || @_initializeResponderStatisticsWithSecondary()

    _.each data.professional_groups, (group) =>
      group.statistics = statistics.professionalGroups[group.id] || @_initializeResponderStatisticsWithSecondary()

    _.each data.age_groups, (group) =>
      group.statistics = statistics.ageGroups[group.id] || @_initializeResponderStatisticsWithSecondary()

    _.each data.tenure_groups, (group) =>
      group.statistics = statistics.tenureGroups[group.id] || @_initializeResponderStatisticsWithSecondary()

  _initializeResponderStatisticsWithSecondary: () ->
    @_addSecondaryStatistics(@_initializeResponderStatistics())

  _initializeResponderStatistics: () ->
    {
      all: { all: 0, completed: 0 },
      subject: { all: 0, completed: 0 },
      exceptSubjectAndManager: { all: 0, completed: 0 },
      byRole: {
        manager: { all: 0, completed: 0 }
      }
    }

  _addSecondaryStatistics: (responderStatistics, options = {}) ->
    precision = options.responderRatePrecision || 0
    _.each ['all', 'subject', 'exceptSubjectAndManager'], (category) =>
      @_addResponseRate responderStatistics[category], precision
    _.each responderStatistics.byRole, (ofRole) =>
      @_addResponseRate ofRole, precision
    responderStatistics

  findParentCompany: (entitiesById, entity) ->
    parentEntity = entity.entity_id && entitiesById[entity.entity_id]
    return null unless parentEntity
    if parentEntity.type == 'Company'
      parentEntity
    else
      findParentCompany(parentEntity, entitiesById)
