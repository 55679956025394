angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'survey_responder_role',
        parent: 'protected'
        url: '/survey_responder_roles/role/{id: int}'
        template: "<ui-view/>"
        resolve:
          role: ($stateParams, SurveyResponderRoleRepository) =>
            SurveyResponderRoleRepository.get($stateParams.id)
        views:
          '@':
            controller: "RoleCtrl"
            controllerAs: "ctrl"
            templateUrl: "survey_responder_roles/roles/role.html"

class RoleCtrl extends Controller
  constructor: (@role, LanguageService, @Constants, @SurveyResponderRoleLocaleRepository, @toastr, @$translate, @$state) ->
    @changeLocale(@role.languageOrGivenLocale(LanguageService.selectedLanguage.short))

  isActiveLocale: (locale) ->
    @currentLanguage == locale

  changeLocale: (locale) ->
    @currentLanguage = locale
    @currentLocale = @role.findOrInitializeRoleLocale(@currentLanguage)
    @currentLocale

  update: () ->
    @role.cleanAwayIncompleteRoleLocales()
    locales = @_collectLocales()
    @SurveyResponderRoleLocaleRepository.updateAll(@role.id, locales).then (response) =>
      @toastr.success(@$translate.instant('survey_responder_roles.role_locales.updated'))

  _collectLocales: () ->
    values = _.values(@role.locales)
    {role_locales: _.map(values, (locale) -> locale[0])}

  cancel: () ->
    @$state.go('survey_responder_role_groups')

  returnToGroupList: () ->
    @groupId = @role.group_id
    @$state.go('survey_responder_role_groups', {group_id: @groupId})
