class SelectEmploymentModalCtrl extends Controller
  constructor: (@$modalInstance, @vacancies) ->
    @selectedEmployment = @vacancies[0].employment || {}

class EmploymentSelectModal extends Directive
  constructor: ->
    return {
      restrict: 'A'
      scope:
        person: '='
        cb: '&'
        cbOptions: '='
      link: (scope, element, attrs) ->
        element.bind 'click', => scope.handle()

      controller: ($scope, $modal) ->
        $scope.handle = ->
          if $scope.person.employments.length > 1
            $modal
              .open
                windowClass: "small"
                templateUrl: 'directives/selectEmploymentModal.html'
                controller: "SelectEmploymentModalCtrl"
                controllerAs: 'ctrl'
                resolve: vacancies: -> $scope.person.selectableVacancies()
              .result.then (employment) ->
                $scope.cb()(employment, $scope.cbOptions)
          else
            $scope.cb()($scope.person.employments[0])
    }
