class SampleHighchart extends Directive
  constructor: () ->
    return {
      restrict: 'E',
      templateUrl: 'directives/sampleHighchart.html'
      controller: 'SampleHighchartCtrl',
      controllerAs: 'highchartCtrl'
    }

class SampleHighchartCtrl extends Controller
  constructor: (@$modal) ->

    @chartConfig = {
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: 'Eelarveplaan 2017'
      },
      xAxis: [{
        categories: ["Jun '17", "Jul '17", "Aug '17", "Sep '17", "Oct '17", "Nov '17", "Dec '17'"],
        crosshair: true
      }],
      yAxis: [{
        title: {
          text: 'Eelarverida 50',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        }
      },
      {
        gridLineWidth: 1,
        title: {
          text: 'Eelarverida 54',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        labels: {
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        }
      },
      {
        gridLineWidth: 1,
        title: {
          text: 'Lõpetatud tegevused',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 80,
        verticalAlign: 'top',
        y: 55,
        floating: true,
        backgroundColor: '#FFFFFF'
      },
      series: [
        {
          name: 'Eelarverida 50',
          type: 'column',
          yAxis: 0,
          data: [2602, 6906, 5804, 5384, 5686, 3675, 0],
          color: Highcharts.getOptions().colors[0]
        },
        {
          name: 'Eelarverida 54',
          type: 'column',
          yAxis: 1,
          data: [1296, 1490, 3526, 10114, 9123, 3225, 0],
          color: Highcharts.getOptions().colors[2]
        },
        {
          name: 'Lõpetatud tegevused',
          type: 'column',
          yAxis: 2,
          data: [169, 190, 224, 195, 212, 106, 0],
          color: Highcharts.getOptions().colors[1]
        },
        {
          name: 'Eelarverida 50 (plaan)',
          type: 'spline',
          yAxis: 0,
          data: [4239, 4804, 5005, 5506, 7355, 8295, 9215],
          dashStyle: 'ShortDash'
          color: Highcharts.getOptions().colors[0]
        },
        {
          name: 'Eelarverida 54 (plaan)',
          type: 'spline',
          yAxis: 1,
          data: [1300, 2200, 3900, 8100, 10300, 12500, 13200],
          dashStyle: 'ShortDash',
          color: Highcharts.getOptions().colors[2]
        },
        {
          name: 'Lõpetatud tegevused (plaan)',
          type: 'spline',
          yAxis: 2,
          data: [146, 223, 168, 256, 190, 205, 210],
          dashStyle: 'ShortDash',
          color: Highcharts.getOptions().colors[1]
        }
      ]
    }

  openChartModal: ->
     @$modal.open
       templateUrl: 'directives/sampleHighchartModal.html'
       controller: "SampleHighchartCtrl"
       controllerAs: 'highchartCtrl'
