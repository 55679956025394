angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'goal',
        parent: 'protected'
        url: "/goals/{goal_id:int}"
        template: "<ui-view/>"
        resolve:
          goal: ($stateParams, Goal) ->
            Goal.one($stateParams.goal_id)
        views:
          '@':
            controller: "GoalCtrl"
            controllerAs: "ctrl"
            templateUrl: "goals/show/goal.html"

class GoalCtrl extends Controller
  constructor: (@$state, @currentPerson, @goal, @$rootScope, @$modal, @GoalRepository, @toastr, @$translate) ->
    @backState = 'goals({entity_id: ' + @currentPerson.currentEmployment.entity.id + '})'

  isSorainen: =>
    @currentPerson.currentCompany.id == 1911        # DEV-718: Sorainen goal view changes

  setGoalDone: =>
    @goal.setDone().then (goal) ->
      @goal = goal

  openGoalDeleteModal: (goal) =>
    @$modal.open
      windowClass: "small"
      templateUrl: 'goals/delete/deleteGoalModal.html'
      controller: "DeleteGoalModalCtrl"
      controllerAs: 'ctrl'
      resolve:
        goal: => goal
    .result.then =>
      @GoalRepository.delete(goal).then =>
        @toastr.success @$translate.instant('goals.deleted')
        previousState = 'my_goals'
        if (@$rootScope.previousState == 'goals')
          previousState = @backState
        @$state.go(previousState)
