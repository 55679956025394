class SurveyTranslatorService extends Service
  constructor: (@$filter, @$rootScope) ->

  translatedRole: (translations, role) ->
    if (role == 'self')
      @translate('surveys.roles.' + role)
    else
      translations.roles[role] || @translate('surveys.roles.' + role)

  translate: (message) ->
    @$filter('translate')(message)
