angular.module 'Upsteem'
  .config ($stateProvider) ->
    $stateProvider
      .state 'create_mass_drafts',
        parent: 'protected'
        url: '/create_mass_drafts'
        params:
          validation: null
          confirmation: null
          showCompletions: false
          surveyTypes: null
          selectedType: null
          templates: null
          selectedTemplate: null
          responderGroups: null
          projects: null
          selectedProject: null
        template: "<ui-view/>"
        resolve:
          showCompletions: ($stateParams) ->
            $stateParams.showCompletions
          validation: ($stateParams, SurveyRepository) ->
            $stateParams.validation || SurveyRepository.getLastMassDraftsValidation()
          confirmation: ($stateParams, validation, SurveyRepository) ->
            if $stateParams.confirmation
              $stateParams.confirmation
            else if validation && validation.state == 'succeeded'
              SurveyRepository.getAssociatedMassDraftsConfirmation(validation.id)
            else
              null
          overallState: (validation, confirmation) ->
            if confirmation
              'confirmation_' + confirmation.state
            else if validation
              'validation_' + validation.state
            else
              'not_started'
          canStartValidation: (overallState, showCompletions) ->
            if _.includes(['not_started', 'validation_failed', 'confirmation_failed'], overallState)
              true
            else
              overallState == 'confirmation_succeeded' && !showCompletions
          surveyTypes: ($stateParams, canStartValidation, Survey) ->
            if canStartValidation
              _.filter Survey.surveyTypes(), (type) ->
                type if type.type != 'staff_satisfaction_survey'
            else
              $stateParams.surveyTypes
          selectedType: ($stateParams, surveyTypes) ->
            $stateParams.selectedType || surveyTypes && surveyTypes[0] && surveyTypes[0].type
          templates: ($stateParams, Constants) ->
            $stateParams.templates || {
              '#{Constants.survey_types.APPRAISAL_INTERVIEW}': [],
              '#{Constants.survey_types.EVALUATION}': []
            }
          selectedTemplate: ($stateParams) ->
            $stateParams.selectedTemplate
          responderGroups: ($stateParams) ->
            $stateParams.responderGroups || {}
          projects: ($stateParams, StructureRepository, currentPerson) ->
            return $stateParams.projects if $stateParams.projects
            StructureRepository.getProjects(
              currentPerson.currentCompany.id,
              { recursive: true, state: "active_or_upcoming" }
            ).then (projects) ->
              return [] if _.isEmpty(projects)
              result = [{ id: null, name: "", title: "" }]
              _.each projects, (project) ->
                project.title = project.structureTreeWithoutParent().join(' » ')
                result.push(project)
              result
          selectedProject: ($stateParams, projects) ->
            $stateParams.selectedProject || projects[0]
        views:
          '@':
            controller: "CreateMassDraftsCtrl"
            controllerAs: "ctrl"
            templateUrl: "surveys/massDraftCreation/createMassDrafts.html"

class CreateMassDraftsCtrl extends Controller
  constructor: (
    @$rootScope,
    @$scope,
    @$translate,
    @$filter,
    @$state,
    @$timeout,
    @$modal,
    @Constants,
    Survey,
    @Validator,
    @toastr,
    @SurveyRepository,
    @TemplatesRepository,
    @EmploymentRepository,
    @showCompletions,
    @validation,
    @confirmation,
    @overallState,
    @canStartValidation,
    @surveyTypes,
    @selectedType,
    @templates,
    @selectedTemplate,
    @responderGroups,
    @projects,
    @selectedProject
  ) ->
    @taskApiPaths = {
      SurveyMassDraftsValidation: 'validations',
      SurveyMassDraftsConfirmation: 'confirmations'
    }

    @csv = {
      content: null,
      header: true,
      headerVisible: true,
      separator: ',',
      separatorVisible: true,
      result: null,
      encoding: 'UTF-8',
      encodingVisible: true
    }

    @getTemplates()

  getTaskApiPath: (task) ->
    path = @taskApiPaths[task.type]
    if !path
      throw new Error('Invalid task type: ' + task.type)
    path

  isAppraisalSelected:  => @selectedType == @Constants.survey_types.APPRAISAL_INTERVIEW
  isEvaluationSelected: => @selectedType == @Constants.survey_types.EVALUATION

  isTemplatesPresent: => !_.isUndefined @templates[@selected_templates]

  getTemplates: =>
    if @selectedType && _.isEmpty @templates[@selectedType]
      @TemplatesRepository.get(@selectedType).then (response) =>
        @templates["#{@selectedType}"] = response.data

        _.each @templates["#{@selectedType}"], (template) =>
          if template.title == "title_not_set"
            template.title = @$translate.instant('general.title_not_set')

  templateChanged: =>
    return unless @selectedTemplate

    if @isEvaluationSelected()
      @TemplatesRepository.getEnLocales(@selectedTemplate.id).then (response) =>
        templateGroups = []
        for i in [0..response.data.length-1]
          templateGroups.push(response.data[i].toLowerCase())
        @responderGroups[@selectedTemplate.id] = templateGroups
    else
      @responderGroups[@selectedTemplate.id] = [@Constants.survey_roles.MANAGER]

  validateSurveys: (file) =>
    # This method sometimes gets called with null argument by the ng-file-upload module for some reason.
    if !file
      return

    @SurveyRepository.extractFileDataWithColumnsAsKeys(file).then (extractionResponse) =>
      responseData = extractionResponse.data
      if responseData.header && responseData.data
        params = @_paramsForMassUpload(responseData)
        # Call API with JSON data:
        @SurveyRepository.validateMassDrafts(params).then (validationResponse) =>
          @$state.go(
            'create_mass_drafts',
            @_overallStateChangeParams({ validation: validationResponse.data, confirmation: null, showCompletions: true }),
            { reload: true }
          )
      else if responseData.error
        @toastr.error(@$translate.instant('errors.' + responseData.error))
      else
        @toastr.error(@$translate.instant('errors.error'))

  _paramsForMassUpload: (data) =>
    {
      'uploaded_data[]': data.data,
      survey_type: @selectedType,
      template_id: @selectedTemplate.id,
      project_id: @selectedProject && @selectedProject.id,
      edit_employments: _.includes(data.header, 'answerer_department'),
      edit_professional_groups: _.includes(data.header, 'answerer_professional_group')
    }

  checkValidationProgress: =>
    @SurveyRepository.getMassDraftsValidation(@validation.id).then (task) =>
      if task.state != @validation.state
        @$state.go(
          'create_mass_drafts',
          @_overallStateChangeParams({ validation: task, confirmation: null, showCompletions: true }),
          { reload: true }
        )

  createSurveys: =>
    @SurveyRepository.confirmMassDraftsCreation(@validation.id).then (response) =>
      @$state.go(
        'create_mass_drafts',
        @_overallStateChangeParams({ validation: @validation, confirmation: response.data, showCompletions: true }),
        { reload: true }
      )

  cancel: =>
    @SurveyRepository.cancelMassDraftsCreation(@validation.id).then (response) =>
      @toastr.success @$translate.instant('general.cancelled')
      @$state.go(
        'create_mass_drafts',
        @_overallStateChangeParams({ validation: response.data, confirmation: null, showCompletions: false }),
        { reload: true }
      )

  checkConfirmationProgress: =>
    @SurveyRepository.getMassDraftsConfirmation(@confirmation.id).then (task) =>
      if task.state != @confirmation.state
        @$state.go(
          'create_mass_drafts',
          @_overallStateChangeParams({ validation: @validation, confirmation: task, showCompletions: true }),
          { reload: true }
        )

  # Params for state.go when overall state changes.
  _overallStateChangeParams: (params) =>
    fullParams = {
      surveyTypes: @surveyTypes
      selectedType: @selectedType
      templates: @templates
      selectedTemplate: @selectedTemplate
      responderGroups: @responderGroups
      projects: @projects
      selectedProject: @selectedProject
    }
    _.each params, (value, key) ->
      fullParams[key] = value
    fullParams

  exmpleFileHeaders: -> ['subject_email', 'answerer_email', 'answerer_group']

  exampleFile: =>
    templateGroups = @responderGroups[@selectedTemplate.id]
    sampleRows = []
    for i in [0..templateGroups.length-1]
      sampleRows.push(
        {
          subject_email: 'subject@email.com',
          answerer_email: 'answerer@email.com',
          answerer_group:	templateGroups[i]
        }
      )
    sampleRows

  # DEV-895 specific hack
  isSorainen: () ->
    @$rootScope.currentPerson.currentCompany.id == 1911

  openProjectCreationModal: () ->
    @$scope.modalInstance = @$modal.open
      scope: @$scope
      windowClass: 'medium'
      templateUrl: 'projects/creationModal.html'
      controller: 'ProjectCreationModalCtrl'
      controllerAs: 'ctrl'
      resolve:
        availableParents: () => @projects
        selectedParent: () => @projects[0]
        returnStateName: () => 'create_mass_drafts'
        returnStateParams: () => @_overallStateChangeParams({ projects: null, selectedProject: null })
    @$scope.modalInstance.result.then(
      (() -> )
    )['finally'] () -> @$scope.modalInstance = undefined
