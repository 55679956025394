angular.module 'Upsteem'
  .config ($stateProvider, $urlRouterProvider) ->
    $stateProvider
      .state 'satisfaction_survey',
        parent: 'protected'
        url: '/satisfaction-surveys/{id:int}'
        abstract: true
        resolve:
          survey: ($stateParams, SatisfactionSurvey) ->
            SatisfactionSurvey.one($stateParams.id)
