class ActivityService extends Service
  constructor: (@ActivityRepository, @Activity) ->

  newActivityInstance: () -> new @Activity

  getActivityById: (activityId) ->
    @ActivityRepository.getActivityById(activityId).then (response) =>
      @newActivityInstance().responseToModel(response)

  saveActivity: (activityModel) ->
    @ActivityRepository.saveActivity(activityModel)

  APICreateActivity: (activityModel) ->
    @ActivityRepository.createActivity(activityModel.getAPIObject())

  getProperties: () ->
    {
      ownersList: @getOwners()
      coOwnersList: @getCoOwners()
      typesList: @getActivityTypes()
      costRowTypesList: @getCostRowTypesList()
      activityStatusList: @getActivityStatus()
    }

  getResponsiblePeople: (people, activityModel) ->
    people.filter( (p) => _.indexOf(activityModel.ownerList, p.id) != -1)

  getCoResponsiblePeople: (people, activityModel) ->
    people.filter( (p) => _.indexOf(activityModel.coOwnerList, p.id) != -1)

  getPlanningCategories: (categories, activityModel) ->
    categories.filter( (p) => _.indexOf(activityModel.planningCategory, p.id) != -1)

  getActivityStatus: () -> [{name: "Alustamata", id:1}, {name: "Tegemisel", id:2}, {name: "Lõpetatud", id:3}]
  getActivityTypes: () -> [{name: "Hanked", id:1}, {name: "Rutiin", id:2}, {name: "Õigusloome", id:3}, {name: "Aasta tegevuskava", id:4}]
  getCostRowTypesList: () -> [{name: "Palgakulu 54", id:1}, {name: "Investeering 50", id:2}, {name: "Välisvahendid SF", id:3}]

  getSelectedType: (typeId) ->
    @getActivityTypes().filter( (p) => p.id == parseInt(typeId))

  getSelectedStatus: (statusId) ->
    @getActivityStatus().filter( (p) => p.id == parseInt(statusId))

  getEmployees: (activityId) -> @employees
  getCoOwners: (activityId, planId) -> @getEmployees()
  getOwners: (activityId, planId) -> @getEmployees()
  getFinancialData: (activityId, planId) ->

  hasEditingPermissions: (activityId) ->
    true
