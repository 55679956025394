class ServiceModal extends Directive
  constructor: () ->
    return {
      restrict: 'E'
      templateUrl:  'directives/serviceModal.html'
      controller:   'ServiceModalCtrl'
      controllerAs: 'ctrl'
      replace: true
      scope:
        url: '='
        title: '?='
        token: '='
    }

class ServiceModalCtrl extends Controller
  constructor: (@$scope, @$modalInstance, @$timeout, @$rootScope, @$translate, @toastr, $sce, title, url, @token, @$window, @goalStatus, @actionAvailabilities) ->
    @url = @$scope.$parent.ctrl.modalUrl
    @title = title
    @modalId = 'modal' + @$scope.$id

    @subscribe()

  submit: () ->
    @_performAction('save')

  close: () ->
    if @$modalInstance && @$modalInstance.opened
      @$modalInstance.close()

  archive: () ->
    @_performAction('archive')

  activate: () ->
    @_performAction('activate')

  delete: () ->
    @_performAction('delete')

  _performAction: (action) ->
    iframe = document.getElementById(@modalId)
    if iframe
      iframe.contentWindow.postMessage({ action: action, token: @token }, "*")

  subscribe: () ->
    x = @
    @$window.addEventListener 'message', ((event)->
      data = event.data
      if x && data.action == 'close'
        x.close()
      return
    ), true
