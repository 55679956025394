class Freeform extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        index: '='
      },
      controller: 'FreeformCtrl',
      controllerAs: 'ctrl',
      templateUrl: 'directives/survey_result/freeform.html'
    }

class FreeformCtrl extends Controller
  constructor: (@$scope) ->
    @data = @$scope.data

    @sortedAnswers = @_sortedAnswers(@data.answers)

  _sortedAnswers: (answers) ->
    answers.reverse()
