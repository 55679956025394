class KpiPeriodChart extends Directive
  constructor: () ->
    return {
      restrict: 'E'
      templateUrl:  'directives/kpiPeriodChart.html'
      controller:   'KpiPeriodChartCtrl'
      controllerAs: 'ctrl'
      replace: true
      scope:
        chartData: '='
    }

class KpiPeriodChartCtrl extends Controller
  constructor: (@$scope, @$rootScope) ->
    @kpis   = []
    @periodKpis = []
    @chartConfig = {}
    @colors = Highcharts.getOptions().colors
    @tableData = {}

    @chartOptions = {
      actual: { order: 2, name: 'objectives.actual', enabled: true }
      target: { order: 1, name: 'objectives.target', enabled: true }
    }

    @dataSeries = {}

    @updateChart(@$scope.chartData)
    @$scope.$watch 'chartData', (data) => @updateChart(data)

  updateChart: (data) =>
    @table = data
    @months = data.months
    @goals  = data.goals
    @title  = data.title
    @periodKpis = _.map _.filter(data.kpis, ((kpi) -> KpiPeriodChartCtrl.kpiHasPeriods(kpi))), (kpi, index) ->
      kpi.visible = true
      kpi.index = index
      kpi
    @updateChartConfig()

  updateChartConfig: () =>
    @kpis = _.filter @periodKpis, {'visible': true}

    @fillDataSeries(@kpis)

    yAxis = _.map @kpis, (kpi, index) ->
      style = { color: @colors[kpi.index] }
      {
        labels: { format: @axisLabel(kpi), style: style }
        title: { text: kpi.name, style: style }
        opposite: Math.abs(kpi.index % 2) == 1
        gridLineWidth: 1
      }
    , @

    series = _.flatten _.map @kpis, (kpi, index) ->
      s = []
      if @chartOptions.target.enabled
        s.push {
          name: "#{kpi.name} (target)"
          type: 'spline'
          yAxis: index
          data: @dataSeries[kpi.id].targets
          dashStyle: 'ShortDash'
          tooltip: { valueSuffix: @kpiUnit(kpi) }
          color: @colors[kpi.index],
          events:
            legendItemClick: () ->
              return false
        }

      if @chartOptions.actual.enabled
        s.push {
          name: "#{kpi.name}"
          type: 'column'
          yAxis: index
          data: @dataSeries[kpi.id].results
          tooltip: { valueSuffix: @kpiUnit(kpi) }
          color: @colors[kpi.index],
          events:
            legendItemClick: () ->
              return false
        }

      s
    , @

    @chartConfig = {
      chart: { zoomType: 'xy' },
      title: { text: @title },
      xAxis: [{
        categories: _.map @months, (month) -> month.format('MMMM \'YY'),
        crosshair: true
      }],
      yAxis: yAxis,
      tooltip: { shared: true },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 80,
        verticalAlign: 'top',
        y: 55,
        floating: true,
        backgroundColor: '#FFFFFF'
      },
      size: {},
      series: series
    }

  fillDataSeries: (kpis) =>
    @dataSeries = {}
    _.each kpis, ((kpi) -> @dataSeries[kpi.id] = @dataSeriesForKpi(kpi, @months, @goals)), @

  dataSeriesForKpi: (kpi, months, goals) =>
    r = {
      targets: [], results: [], fulfillments: [], summary: {}, name: kpi.name, unit: @kpiUnit(kpi)
    }

    _.map _.filter(goals, {'kpi_id': kpi.id}), (goal) ->
      _.each months, (month, index) ->
        r.targets[index] ?= []
        r.results[index] ?= []
        r.fulfillments[index] ?= []

        goal_results = _.findWhere(goal.results, { 'date': month.format('YYYY-MM-DD') }) || {}
        r.targets[index].push goal_results.target
        r.results[index].push goal_results.result

    r.targets = _.map r.targets, ((targets) -> @reduce(targets, kpi)) , @
    r.results = _.map r.results, ((results) -> @reduce(results, kpi)) , @
    r.fulfillments = @calculateFullFillments(r.targets, r.results)

    targets_t = @reduce(r.targets, kpi)
    results_t = @reduce(r.results, kpi)
    r.totals = {
      targets: targets_t
      results: results_t
      fulfillments: _.round ((parseFloat(results_t) / parseFloat(targets_t)) * 100), 2
    }
    r

  reduce: (values, kpi) =>
    sum = _.sum values
    if kpi.algorithm == 'AVERAGE' && sum > 0
      return _.round (sum / _.size _.compact values), 2
    _.round sum, 2

  calculateFullFillments: (targets, results) =>
    _.map targets, (target, index) ->
      _.round ((parseFloat(results[index]) / parseFloat(target)) * 100), 2

  updateKpis: (kpi) =>
    @updateChartConfig()

  updateOptions: () =>
    @updateChartConfig()

  axisLabel: (kpi) =>
    if kpi.unit == 'NUMERIC'
      return "{value}"
    "{value} #{kpi.unit}"

  kpiUnit: (kpi) =>
    if kpi.unit == 'NUMERIC'
      return ''
    kpi.unit

  @kpiHasPeriods: (kpi) =>
    _.last(kpi.grading_type.split('.')).toUpperCase() == 'PERIOD'
