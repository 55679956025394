class SurveyResultOverview extends Directive
  constructor: ($rootScope) ->
    return {
      restrict: 'E',
      scope: {
        data: '=',
        translations: '=',
      },
      controller: 'SurveyResultOverviewCtrl',
      controllerAs: 'ctrl'
      templateUrl: 'directives/survey_result/survey_result_overview.html'
    }

class SurveyResultOverviewCtrl extends Controller
  constructor: (@$scope, @SurveyTranslatorService) ->
    @data = @$scope.data
    @translations = @$scope.translations
    @translator = @SurveyTranslatorService
    @$scope.$watch 'data', (data) => @_updateData(data, @$scope.translations)
    @surveyType = @$scope.data.info.category

  _updateData: (data, translations) ->
    @data = data
    @overview = data.overview
    @role_ratings = @_roleRatings(_.omit(@data.role_ratings, "self"), translations)
    @responders = data.responders

  _roleRatings: (ratings, translations) ->
    _.reduce(ratings, (result, rating, role) ->
      result[@translator.translatedRole(translations, role)] = rating
      result
    , {}, @)

  isNotAppraisal: () ->
    return @surveyType != 'appraisalinterview'