class GoalsAppAccessManager extends Service
  constructor: (toastr, $state) -> return class GoalsAppAccessManager
    constructor: (@config, @currentPerson) ->

    manageOwnGoalsAccess: () ->
      if @config.isGoalsAppAvailable()
        if @currentPerson.permissions.areOwnGoalsAccessible()
          true
        else
          @_noPermissions()
      else
        @_notAvailable()

    manageEmployeeGoalsAccess: () ->
      if @config.isGoalsAppAvailable()
        if @currentPerson.permissions.areEmployeeGoalsAccessible()
          true
        else
          @_noPermissions()
      else
        @_notAvailable()

    manageSettingsAccess: () ->
      if @config.isGoalsAppAvailable()
        if @currentPerson.permissions.areGoalSettingsAccessible()
          true
        else
          @_noPermissions()
      else
        @_notAvailable()

    _noPermissions: () ->
      @currentPerson.permissions.showMessageAndRedirect()

    _notAvailable: () ->
      toastr.error 'Not Found'
      $state.go 'root'
