class RemarkModalCtrl extends Controller
  constructor: (@$scope, @$modalInstance, @$timeout, @$rootScope, @toastr, @availablePeople, @$translate ,@RemarkRepository) ->

    @remark = {
      subject: @_preSelectPerson(),
    }

  submit: (form) ->
    if(form.$valid)
      @_formatRemark()
      if(@remark.to.indexOf(@$rootScope.currentPerson.id) != -1)
        @toastr.error @$translate.instant('remarks.self_error')
      else
        @RemarkRepository.create(@remark).then((response) =>
          if(response.status > 199 && response.status < 300)
            @$modalInstance.close()
            @$rootScope.$broadcast("refresh")
            @toastr.success @$translate.instant('remarks.remark_created');
          else
            @toastr.error @$translate.instant('remarks.error')
        ).then((response) =>

        ).then null, (err) =>
          @toastr.error @$translate.instant('remarks.error')

  _formatRemark: () ->
    @remark.to = _.map(@remark.subject, (subject) ->
      subject.id
    @remark.subject = undefined
    )

  close: () ->
    @$modalInstance.close()

  _preSelectPerson: () ->
    if(@$scope.person != undefined)
      selectedPerson =  _.find(@availablePeople, (person) =>
        person.id == @$scope.$parent.person
      )
      return if (selectedPerson == undefined) then undefined else [selectedPerson]
